import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Loading from '../../components/Loading';

const columns = [
    { id: 'S. No.', label: 'S. No.', minWidth: 50 },
    { id: 'employee', label: 'Employee', minWidth: 185 },
    { id: 'designation', label: 'Designation', minWidth: 185 },
    {
        id: 'Award',
        label: 'Award',
        minWidth: 150,
    },
    {
        id: 'Gift',
        label: 'Gift',
        minWidth: 100,
    },
    {
        id: 'Award_Information',
        label: 'Award Information',
        minWidth: 180,
    },

    {
        id: 'Date',
        label: 'Date',
        minWidth: 105,
    },
    {
        id: 'Action',
        label: 'Action',
        minWidth: 50,
    },
];

const Awardtable = props => {
    const {
        awards,
        editAward,
        page,
        handleChangePage,
        rowsPerPage,
        handleChangeRowsPerPage,
        count,
    } = props;

    return (
        <>
            <TableContainer sx={{ '::-webkit-scrollbar': { display: 'none' } }}>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                    }}>
                                    <Typography variant='caption'>{column.label}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {awards ? (
                            awards.map((award, i) => (
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}>
                                    <TableCell component='th' scope='row'>
                                        {i + 1}
                                    </TableCell>
                                    <TableCell component='th' scope='row'>
                                        <Box display='flex'>
                                            <Box mr={2}>
                                                <Avatar
                                                    alt='Remy Sharp'
                                                    src='https://shorturl.at/fjqz9'
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant='body2'>
                                                    {award.employee?.firstName +
                                                        ' ' +
                                                        award.employee?.lastName}
                                                </Typography>
                                                <Typography
                                                    color='text.secondary'
                                                    variant='caption'>
                                                    {award.employee?.email}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </TableCell>

                                    <TableCell component='th' scope='row'>
                                        <Typography variant='caption'>
                                            {award.employee?.designation}
                                        </Typography>
                                    </TableCell>

                                    <TableCell component='th' scope='row'>
                                        <Typography variant='caption'>{award.name}</Typography>
                                    </TableCell>

                                    <TableCell component='th' scope='row'>
                                        <Typography variant='caption'>{award.gift}</Typography>
                                    </TableCell>
                                    <TableCell component='th' scope='row'>
                                        <Typography variant='caption'>
                                            {award.description}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component='th' scope='row'>
                                        <Typography variant='caption'>
                                            {new Date(award.createdAt).toLocaleDateString()}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component='th' scope='row'>
                                        <IconButton
                                            onClick={() => editAward(award._id)}
                                            sx={{
                                                borderRadius: '12px',
                                                backgroundColor: 'custom.buttonColor.light.blue',
                                                color: 'custom.buttonColor.dark.blue',
                                                '&:hover': {
                                                    backgroundColor:
                                                        'custom.buttonColor.light.blue',
                                                },
                                            }}>
                                            <EditIcon fontSize='small' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <>
                                <TableCell component='th' scope='row' colSpan={8}>
                                    <Loading />
                                </TableCell>
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component='div'
                count={count ? count : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default Awardtable;
