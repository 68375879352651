import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Modal,
    Tooltip,
    Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useCallback, useEffect, useState } from 'react';
import Search from '../components/Search';
import DeparmentTable from '../components/DeparmentTable';

import axios from 'axios';
import DeparmentModal from '../components/DeparmentModal';

const Department = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [departments, setDepartments] = useState();

    const fetchDepartment = useCallback(
        async (search = '') => {
            try {
                const response = await axios.get(
                    `/hr/department?searchBy=name&search=${search}&sortBy=order`
                );
                setDepartments(response.data.departments);
            } catch (e) {
                console.log(e);
            }
        },
        [setDepartments]
    );

    useEffect(() => {
        fetchDepartment();
    }, [fetchDepartment]);

    return (
        <>
            <Box mt={3}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Department</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box>
                            <Button onClick={handleOpen} variant='contained'>
                                Add Department
                            </Button>
                        </Box>
                        <Box sx={{ ml: 2 }}>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0 }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {/*table  */}
            <Card elevation={0} sx={{ position: 'relative', mt: 4, mb: 2 }}>
                <CardContent>
                    <Box
                        sx={{
                            borderLeftWidth: '5px',
                            borderLeftColor: 'primary.main',
                            borderLeftStyle: 'solid',
                            height: '40px',
                            width: '5px',
                            position: 'absolute',

                            left: 1,
                        }}></Box>
                    <Typography variant='h6' sx={{ mb: 4 }}>
                        Department Summary
                    </Typography>
                    <Box sx={{ maxWidth: '300px', ml: 'auto', my: 2 }}>
                        <Search
                            placeholder='Search by Department'
                            onChange={e => {
                                const { value } = e.target;
                                !(value.trim() === ' ') && fetchDepartment(value);
                            }}
                        />
                    </Box>
                    <DeparmentTable departments={departments} fetchDepartment={fetchDepartment} />
                </CardContent>
            </Card>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'>
                <DeparmentModal fetchDepartment={fetchDepartment} handleClose={handleClose} />
            </Modal>
        </>
    );
};

export default Department;
