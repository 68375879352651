import {
    Box,
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import React from 'react';
import UserCard from './UserCard';
import TicketBarChart from './TicketBarChart';
import TicketTable from './TicketTable';

const index = () => {
    return (
        <>
            <Box mt={2}>
                <Typography variant='h6'>User Dashboard</Typography>
            </Box>
            <Box mt={4}>
                <Grid container spacing={2}>
                    <UserCard />
                </Grid>
            </Box>
            <Box my={5}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Card elevation={0}>
                            <CardContent>
                                <Box
                                    display='flex'
                                    alignItems='cemter'
                                    justifyContent='space-between'
                                >
                                    <Box>
                                        <Typography variant='h6' sx={{ mb: 2 }}>
                                            Ticket Overview
                                        </Typography>
                                    </Box>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id='demo-simple-select-label'>
                                                Year
                                            </InputLabel>
                                            <Select
                                                size='medium'
                                                labelId='demo-simple-select-label'
                                                id='demo-simple-select'
                                            >
                                                <MenuItem value={10}>
                                                    Monthly
                                                </MenuItem>
                                                <MenuItem value={20}>
                                                    Weekly
                                                </MenuItem>
                                                <MenuItem value={30}>
                                                    Yearly
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <TicketBarChart />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Box mb={2}>
                <Card elevation={0}>
                    <CardContent>
                        <Typography sx={{ mb: 2 }}>Ticket Summary</Typography>
                        <TicketTable />
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default index;
