import { Box, Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EmployeesCard from '../components/EmployeesCard';
import EmployeeListTable from '../components/EmployeeListTable';

const EmployList = () => {
    return (
        <>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Employees</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box sx={{ ml: 2 }}>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0 }}>
                                    <InfoOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={6}>
                <Grid container spacing={4}>
                    <EmployeesCard />
                </Grid>
            </Box>
            <Box mt={5} mb={2}>
                <Card elevation={0} sx={{ position: 'relative', pb: 2 }}>
                    <CardContent>
                        <Box
                            sx={{
                                borderLeftWidth: '5px',
                                borderLeftColor: 'primary.main',
                                borderLeftStyle: 'solid',
                                height: '40px',
                                width: '5px',
                                position: 'absolute',

                                left: 1,
                            }}></Box>

                        <Typography variant='h6' sx={{ mb: 3 }}>
                            Employees List
                        </Typography>

                        <EmployeeListTable />
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default EmployList;
