import HomeIcon from '@mui/icons-material/HomeOutlined';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import GroupIcon from '@mui/icons-material/Group';
import ViewListIcon from '@mui/icons-material/ViewList';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import BusinessIcon from '@mui/icons-material/Business';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
// import FactCheckIcon from '@mui/icons-material/FactCheck';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AddCardIcon from '@mui/icons-material/AddCard';
import EventNoteIcon from '@mui/icons-material/EventNote';
import DescriptionIcon from '@mui/icons-material/Description';

// landing
import FlightLandIcon from '@mui/icons-material/FlightLand';
// import Person2Icon from '@mui/icons-material/Person2';
// import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
// import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SchoolIcon from '@mui/icons-material/School';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SettingsIcon from '@mui/icons-material/Settings';
import PaidIcon from '@mui/icons-material/Paid';

const AttendanceCollapse = [
    {
        name: 'Attendance',
        icon: <CoPresentIcon fontSize='small' />,
        to: '/attendance',
    },

    {
        name: 'Leave Setting',
        icon: <WorkHistoryIcon fontSize='small' />,
        to: '/leave-setting',
    },
    {
        name: 'Leave Application',
        icon: <AllInboxIcon fontSize='small' />,
        to: '/leave-application',
    },

    {
        name: `User's attendance`,
        icon: <PersonOutlineIcon fontSize='small' />,
        to: '/user-attendance',
    },
];

const Employee = [
    {
        name: 'Employees List',
        icon: <GroupIcon fontSize='small' />,
        to: '/employlist',
    },
    {
        name: 'View Employees',
        icon: <GroupIcon fontSize='small' />,
        to: '/view-employee',
    },
];

const Payroll = [
    {
        name: 'Add  Payroll',
        icon: <AddCardIcon fontSize='small' />,
        to: '/add-payroll',
    },
    {
        name: 'Empoyee Salary',
        icon: <MonetizationOnIcon fontSize='small' />,
        to: '/employee-salary',
    },
];

const footerLink = [
    {
        name: 'Expenses',
        icon: <PaidIcon fontSize='small' />,
        to: '/expenses',
    },
    {
        name: 'Projects',
        icon: <LibraryBooksIcon fontSize='small' />,
        to: '/projects',
    },
    {
        name: 'Award',
        icon: <EmojiEventsIcon fontSize='small' />,
        to: '/award',
    },

    {
        name: 'Holidays',
        icon: <RecentActorsIcon fontSize='small' />,
        to: '/holidays',
    },
    {
        name: 'Notice Board',
        icon: <EventNoteIcon fontSize='small' />,
        to: '/notice',
    },

    {
        name: 'Rules & Regulations',
        icon: <DescriptionIcon fontSize='small' />,
        to: '/rules-&-regulations',
    },
];

const NavLinks = {
    dashboard: [
        {
            name: 'Dashboards',
            icon: <HomeIcon fontSize='small' />,
            to: '/',
        },
        {
            name: 'Job Listing',
            icon: <ViewListIcon fontSize='small' />,
            to: '/JobListing',
        },
        {
            name: 'Docs',
            icon: <DescriptionIcon fontSize='small' />,
            to: '/docs',
        },
        {
            name: 'Interview Question',
            icon: <ViewListIcon fontSize='small' />,
            to: '/interview-question',
        },

        {
            name: 'Department',
            icon: <BusinessIcon fontSize='small' />,
            to: '/department',
        },
        {
            name: 'Received Application',
            icon: <MarkunreadMailboxIcon fontSize='small' />,
            to: '/received-application',
        },
    ],
};

// const supportSystem = [
//     {
//         name: 'Landing pages',
//         icon: <FlightLandIcon fontSize='small' />,
//         to: '/award',
//     },

//     {
//         name: 'User Pages',
//         icon: <Person2Icon fontSize='small' />,
//         to: '/holidays',
//     },
//     {
//         name: 'Admin',
//         icon: <AdminPanelSettingsIcon fontSize='small' />,
//         to: '/award',
//     },

//     {
//         name: 'Agent',
//         icon: <SupportAgentIcon fontSize='small' />,
//         to: '/holidays',
//     },
// ];

const landingPages = [
    {
        name: 'Land Page',
        icon: <FlightLandIcon fontSize='small' />,
        to: '/support/landing-pages/land-page',
    },
    {
        name: 'Knowledge Page',
        icon: <SchoolIcon fontSize='small' />,
        to: '/knowledge-page',
    },
    {
        name: 'Knowledge View',
        icon: <SchoolIcon fontSize='small' />,
        to: '/knowledge-view',
    },
];

const userPages = [
    {
        name: 'Dashboard',
        icon: <HomeIcon fontSize='small' />,
        to: '/userpage-dashboard',
    },

    {
        name: 'Profile',
        icon: <PersonOutlineIcon fontSize='small' />,
        to: '/userpage-profile',
    },
];

const userPageTickets = [
    {
        name: 'Ticket List',
        icon: <HomeIcon fontSize='small' />,
        to: '/ticket-list',
    },

    {
        name: 'Active Tickets',
        icon: <PersonOutlineIcon fontSize='small' />,
        to: '/active-tickets',
    },
    {
        name: 'Closed Tickets',
        icon: <HomeIcon fontSize='small' />,
        to: '/closed-tickets',
    },

    {
        name: 'Create Ticket',
        icon: <PersonOutlineIcon fontSize='small' />,
        to: '/create-tickets',
    },
    {
        name: 'View Ticket',
        icon: <PersonOutlineIcon fontSize='small' />,
        to: '/view-tickets',
    },
];

const AdminHeader = [
    {
        name: 'Dashboard',
        icon: <HomeIcon fontSize='small' />,
        to: '/admin-dashboard',
    },

    {
        name: 'Edit Profile',
        icon: <PersonOutlineIcon fontSize='small' />,
        to: '/edit-profile',
    },
];

const AdminFooter = [
    {
        name: 'Customers',
        icon: <HomeIcon fontSize='small' />,
        to: '/admin-dashboard',
    },

    {
        name: 'Categories',
        icon: <PersonOutlineIcon fontSize='small' />,
        to: '/admin-Categories',
    },
    {
        name: 'Articles',
        icon: <PersonOutlineIcon fontSize='small' />,
        to: '/admin-articles',
    },
];

const Agent = [
    {
        name: 'Dashboard',
        icon: <HomeIcon fontSize='small' />,
        to: '/agent-dashboard',
    },

    {
        name: 'Assigned Categories',
        icon: <PersonOutlineIcon fontSize='small' />,
        to: '/agent-Categories',
    },
];

const Chat = [
    {
        name: 'Chat',
        icon: <ChatBubbleOutlineOutlinedIcon fontSize='small' />,
        to: '/chat',
    },
];

const mainAdmin = [
    {
        name: 'General setting',
        icon: <SettingsIcon fontSize='small' />,
        to: '/admin/general-setting',
    },
    {
        name: 'API setting',
        icon: <SettingsIcon fontSize='small' />,
        to: '/admin/api-setting',
    },
    {
        name: 'Role Access',
        icon: <SettingsIcon fontSize='small' />,
        to: '/admin/role-access',
    },
];

export {
    NavLinks,
    AttendanceCollapse,
    Payroll,
    footerLink,
    Employee,
    userPageTickets,
    userPages,
    landingPages,
    AdminFooter,
    AdminHeader,
    Agent,
    Chat,
    mainAdmin,
};
