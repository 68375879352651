import React, { useState } from 'react';
import { Box, FormControl, Grid, IconButton, MenuItem, Divider, Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelIcon from '@mui/icons-material/Cancel';
import StarIcon from '@mui/icons-material/Star';
import TonalityIcon from '@mui/icons-material/Tonality';
import LogoutIcon from '@mui/icons-material/Logout';
import SnoozeIcon from '@mui/icons-material/Snooze';
// import Search from '../../components/Search';
import { Select } from '../../components/Select';
import AttendanceTable from '../../components/AttendanceTable';

const Months = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
};

const Years = Array(41)
    .fill(1)
    .map((el, i) => i + 2009);

const Attendance = () => {
    const [date, setDate] = useState({
        employeeId: '',
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
    });

    const handleChangeQuery = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDate({ ...date, [name]: value });
    };

    return (
        <Box mt={3}>
            <Grid container spacing={2} display='flex' alignItems='center'>
                <Grid item xs>
                    <Typography variant='h5'>Attendance</Typography>
                </Grid>
                <Grid item>
                    <FormControl size='small' sx={{ width: 233 }}>
                        <Select
                            fullWidth
                            value={date.month}
                            name='month'
                            displayEmpty
                            onChange={handleChangeQuery}
                            filter={date.month && Months[date.month]}
                            clear={() => setDate({ ...date, month: '' })}
                            renderValue={v => {
                                if (!date.month) return 'Month';
                                return Months[v];
                            }}>
                            {Object.keys(Months).map(month => (
                                <MenuItem key={month} value={month}>
                                    {Months[month]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl size='small' sx={{ width: 233 }}>
                        <Select
                            name='year'
                            fullWidth
                            displayEmpty
                            value={date.year}
                            filter={date.year}
                            onChange={handleChangeQuery}
                            clear={() => setDate({ ...date, year: '' })}
                            renderValue={v => {
                                if (!date.year) return 'Year';
                                return v;
                            }}>
                            {Years.map(year => (
                                <MenuItem value={year}>{year}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider sx={{ my: 3 }} />

            <Grid container mb={2} spacing={2}>
                <Grid item xs>
                    <IconButton
                        disableRipple
                        disableTouchRipple
                        dis
                        sx={{
                            color: '#0DCD9E',
                            '&:hover': {
                                background: 'none',
                            },
                        }}>
                        <VerifiedIcon fontSize='small' />
                        <Typography sx={{ mx: 1 }} variant='subtitle2'>
                            {'  -----> Present'}
                        </Typography>
                    </IconButton>
                    <IconButton sx={{ color: '#F11564' }} disableRipple disableTouchRipple>
                        <CancelIcon fontSize='small' />
                        <Typography sx={{ mx: 1 }} variant='subtitle2'>
                            {'  -----> Absent'}
                        </Typography>
                    </IconButton>
                    <IconButton sx={{ color: '#FFAD00' }} disableRipple disableTouchRipple>
                        <StarIcon fontSize='small' />
                        <Typography sx={{ mx: 1 }} variant='subtitle2'>
                            {'  -----> Holiday'}
                        </Typography>
                    </IconButton>
                    <IconButton sx={{ color: '#F34932' }} disableRipple disableTouchRipple>
                        <TonalityIcon fontSize='small' />
                        <Typography sx={{ mx: 1 }} variant='subtitle2'>
                            {'  -----> Half Day'}
                        </Typography>
                    </IconButton>
                    <IconButton sx={{ color: '#ffe8af' }} disableRipple disableTouchRipple>
                        <LogoutIcon fontSize='small' />
                        <Typography sx={{ mx: 1 }} variant='subtitle2'>
                            {'  -----> Leave'}
                        </Typography>
                    </IconButton>
                    <IconButton sx={{ color: '#32daf3' }} disableRipple disableTouchRipple>
                        <SnoozeIcon fontSize='small' />
                        <Typography sx={{ mx: 1 }} variant='subtitle2'>
                            {'  -----> Late'}
                        </Typography>
                    </IconButton>
                </Grid>
                {/* <Grid item>
                    <Search
                        placeholder='Search by Employee'
                        // onChange={(e) => {
                        //     const { value } = e.target;
                        // !(value.trim() === ' ') && fetchEmploees(value);
                        // }}
                    />
                </Grid> */}
            </Grid>

            <AttendanceTable month={date.month} year={date.year} />
        </Box>
    );
};

export default Attendance;
