import { Box, Container, Typography, Stack } from '@mui/material';
import React from 'react';
import { Image } from './Image';

const Error = props => {
    const { error, errorCode } = props;
    return (
        <Container maxWidth='lg'>
            <Box
                display='flex'
                justifyContent='center'
                alignItems={{ xs: 'flex-start', md: 'center' }}
                flexDirection={{ xs: 'column', md: 'row' }}
                minHeight='calc(100dvh - 90px)'>
                <Stack direction='horizontal' mb={2}>
                    <Image name='catch.png' sx={{ height: '50px' }} />{' '}
                    {/* <Typography variant='h3' fontWeight={500} mx={1} letterSpacing={'1.8px'}>
                        {process.env.REACT_APP_COMPANY}
                    </Typography> */}
                </Stack>
                <Box ml={2}>
                    <Typography variant='h6' color='text.secondary' fontWeight={400} mb={3}>
                        <Typography
                            variant='h5'
                            fontWeight={500}
                            color='text.primary'
                            component='span'>
                            {errorCode}. {'   '}
                        </Typography>
                        That's an error.
                    </Typography>
                    <Typography variant='h6' mb={1}>
                        {error}
                    </Typography>
                    <Typography variant='subtitle1' color='text.secondary'>
                        That's all we know
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
};

export default Error;
