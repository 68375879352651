import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { IconButton } from '@mui/material';

const columns = [
    { id: 'ID', label: 'ID', minWidth: 30 },
    {
        id: 'Title',
        label: 'Title',
        minWidth: 100,
    },
    {
        id: 'Priority',
        label: 'Priority',
        minWidth: 80,
    },
    {
        id: 'Category',
        label: 'Category',
        minWidth: 80,
    },
    {
        id: 'Date',
        label: 'Date',
        minWidth: 80,
    },
    {
        id: 'Status',
        label: 'Status',
        minWidth: 80,
    },
    {
        id: 'Action',
        label: 'Action',
        minWidth: 80,
    },
];
const TicketTable = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value, 10);
        setPage(0);
    };

    return (
        <>
            <TableContainer>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            // key={id}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component='th' scope='row'>
                                111
                            </TableCell>
                            <TableCell component='th' scope='row'>
                                Maiores alias aut
                            </TableCell>
                            <TableCell component='th' scope='row'>
                                High
                            </TableCell>
                            <TableCell component='th' scope='row'>
                                Services
                            </TableCell>
                            <TableCell component='th' scope='row'>
                                17-03-2021
                            </TableCell>
                            <TableCell component='th' scope='row'>
                                Open
                            </TableCell>
                            <TableCell component='th' scope='row'>
                                <IconButton
                                    sx={{
                                        borderRadius: '4px',
                                        background: '#3366FF',
                                        '&:hover': {
                                            background: '#3366FF',
                                        },
                                    }}
                                >
                                    <VisibilityIcon fontSize='small' />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component='div'
                // count={departments ? departments?.length : '0'}
                count={10}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default TicketTable;
