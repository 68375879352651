import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React from 'react';

const UploadDocument = () => {
    return (
        <>
            <Grid container spacing={1} display='flex' alignItems='center'>
                <Grid item lg={3} xs={12}>
                    <Typography variant='caption'>Resume</Typography>
                </Grid>
                <Grid item lg={9} xs={12}>
                    <TextField
                        name='Bank Name'
                        size='small'
                        type='file'
                        required
                        readOnly
                        fullWidth
                    />
                </Grid>
                <Grid item lg={3} xs={12}>
                    <Typography variant='caption'>ID Proof</Typography>
                </Grid>
                <Grid item lg={9} xs={12}>
                    <TextField
                        name='ID Proof'
                        size='small'
                        type='file'
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item lg={3} xs={12}>
                    <Typography variant='caption'>Offer Letter</Typography>
                </Grid>
                <Grid item lg={9} xs={12}>
                    <TextField
                        name='Offer Letter'
                        size='small'
                        type='file'
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item lg={3} xs={12}>
                    <Typography variant='caption'>Joining Letter</Typography>
                </Grid>
                <Grid item lg={9} xs={12}>
                    <TextField
                        name='Joining Letter'
                        size='small'
                        type='file'
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item lg={3} xs={12}>
                    <Typography variant='caption'>Agreement Letter</Typography>
                </Grid>
                <Grid item lg={9} xs={12}>
                    <TextField
                        name='Agreement Letter'
                        size='small'
                        type='file'
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item lg={3} xs={12}>
                    <Typography variant='caption'>Experience Letter</Typography>
                </Grid>
                <Grid item lg={9} xs={12}>
                    <TextField
                        name='Experience Letter'
                        size='small'
                        type='file'
                        required
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Box mt={6} textAlign='right'>
                <Button
                    type='submit'
                    variant='contained'
                    sx={{
                        mx: 2,

                        background: '#4674FF',
                        '$:hover': {
                            background: '#4674FF',
                        },
                    }}
                >
                    Update
                </Button>

                <Button
                    variant='contained'
                    sx={{
                        background: '#F91A3F',
                        '$:hover': {
                            background: '#F91A3F',
                        },
                    }}
                >
                    Cancel
                </Button>
            </Box>
        </>
    );
};

export default UploadDocument;
