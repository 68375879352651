import React, { useEffect, useState } from 'react';
import Error from '../components/Error';
import { useUser } from './Authenticate';
import { env } from '../utils/function';
import Loading from '../components/Loading';

const AuthorizationProvider = ({ children }) => {
    const [content, setContent] = useState();
    const user = useUser();

    useEffect(() => {
        if (user.role === 'hr') {
            setContent(children);
            return;
        }

        if (user.role === 'employee') {
            const redirectTo = env('EMPLOYEE_DOMAIN');

            setContent(
                <Loading
                    message='Please wait, redirecting you to Employee Dashboard'
                    redirectTo={redirectTo}
                />
            );
            return;
        }

        setContent(
            <Error
                error='Access to this resource is denied. You may not have the necessary permissions.'
                errorCode={403}
            />
        );
    }, [user.role, children]);

    return content;
};

export default AuthorizationProvider;
