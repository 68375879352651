import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import TableRow from '@mui/material/TableRow';
import {
    Box,
    IconButton,
    Typography,
    Stack,
    FormControl,
    Select,
    MenuItem,
    Pagination,
    Modal,
    Tooltip,
    Chip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import Loading from './Loading';
import moment from 'moment';
import Search from './Search';
import { Link } from 'react-router-dom';

import useModal from '../hooks/useModal';
import TerminationModal from './TerminationModal';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import { useEmployees } from '../hooks/Authenticate';

const columns = [
    { id: 'Sr. no', label: 'No', minWidth: 20 },
    { id: 'Emp_Name', label: 'Emp Name', minWidth: 185, align: 'center' },
    { id: 'Shift_Start', label: 'Shift Start', minWidth: 40, align: 'center' },
    { id: 'Shift_End', label: 'Shift End', minWidth: 40, align: 'center' },
    { id: 'Department', label: 'Department', minWidth: 60, align: 'center' },
    { id: 'Designation', label: 'Designation', minWidth: 153, align: 'center' },
    {
        id: 'Phone_Number',
        label: 'Phone Number',
        minWidth: 110,
        align: 'center',
    },
    {
        id: 'Join_Date',
        label: 'Join Date',
        minWidth: 100,
        align: 'center',
    },

    {
        id: 'Status',
        label: 'Status',
        minWidth: 45,
        align: 'center',
    },
    {
        id: 'Action',
        label: 'Action',
        minWidth: 100,
        align: 'center',
    },
];

const EmployeeListTable = () => {
    const [employLists, setEmployLists] = React.useState(null);
    const [department, setDepartment] = React.useState(null);
    const [pageLimit, setPageLimit] = React.useState(0);
    const [totalEmployee, setTotalEmployee] = React.useState(0);
    const [suspenId, setSuspendId] = React.useState();

    const [page, setPage] = React.useState(1);
    const [status, setStatus] = React.useState('Active');
    const employees = useEmployees();

    console.log(employees);

    const fetchEmploees = React.useCallback(
        async (search = '') => {
            try {
                const response = await axios.get(
                    `/hr/employee?searchBy=firstName&search=${search}&sortBy=order&status=${status}&page=${page}`
                );
                setEmployLists(response.data.employees);
                setPageLimit(response.data.pageData.totalPages);
                setTotalEmployee(response.data.pageData.totalData);
            } catch (e) {
                console.log(e);
            }
        },
        [setEmployLists, page, status]
    );

    const fetchDepartment = React.useCallback(
        async function () {
            try {
                const response = await axios.get(`/hr/department`);
                setDepartment(response.data.departments);
            } catch (e) {
                console.log(e);
            }
        },
        [setDepartment]
    );
    const { modalState, closeModal, openModal } = useModal();

    const handleChangeTermination = id => {
        setSuspendId(id);
        openModal();
    };
    const convertToTwoDigits = number => number.toString().padStart(2, '0');

    React.useEffect(() => {
        fetchEmploees();
        fetchDepartment();
    }, [fetchEmploees, fetchDepartment]);

    return (
        <>
            <Stack direction='row' justifyContent='space-between' my={2}>
                <Box
                    mt={2}
                    sx={{
                        display: 'flex',
                    }}>
                    <Typography sx={{ mr: 2, mt: 1 }}>Status</Typography>
                    <FormControl size='small'>
                        <Select value={status} onChange={e => setStatus(e.target.value)}>
                            <MenuItem value='Active'>Active</MenuItem>
                            <MenuItem value='Terminated'>Terminated</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Search
                    placeholder='Search by Employee First Name'
                    onChange={e => {
                        const { value } = e.target;
                        !(value.trim() === ' ') && fetchEmploees(value);
                    }}
                />
            </Stack>
            <TableContainer>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                    }}>
                                    <Typography variant='caption'>{column.label}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {employLists ? (
                            employLists.map((employee, id) => {
                                const depName = department?.find(
                                    dep => dep._id === employee.department
                                );

                                const d = new Date();

                                return (
                                    <TableRow
                                        key={id}
                                        sx={{
                                            '&:last-child td, &:last-child th': {
                                                border: 0,
                                            },
                                        }}>
                                        <TableCell component='th' scope='row' key={id}>
                                            {id + 1}
                                        </TableCell>
                                        <TableCell component='th' scope='row'>
                                            <Box display='flex' alignItems='center'>
                                                <Box mx={2}>
                                                    {/* <Avatar
                                                        src={`https://api.files.catch.com/open/file/preview/${
                                                            employees[employee._id]?.photo
                                                        }`}
                                                    /> */}
                                                </Box>
                                                <Box>
                                                    <Typography variant='body1'>
                                                        {employee.firstName} {employee.lastName}{' '}
                                                        {new Date(
                                                            d.setDate(d.getDate() + 1)
                                                        ).getTime() >
                                                            new Date(
                                                                employee.dateOfJoining
                                                            ).getTime() &&
                                                        new Date(employee.dateOfJoining).getTime() >
                                                            new Date(
                                                                d.setDate(d.getDate() - 1)
                                                            ).getTime() ? (
                                                            <Chip
                                                                size='small'
                                                                label='New'
                                                                sx={{
                                                                    p: 0.5,
                                                                    mx: 0.4,
                                                                    mb: 0.4,
                                                                    fontSize: 11,
                                                                    height: '18px',
                                                                    bgcolor: 'secondary.main',
                                                                }}
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Typography>
                                                    <Typography
                                                        color='text.secondary'
                                                        variant='caption'>
                                                        {employee.email}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell component='th' scope='row' align='center'>
                                            <Typography variant='caption'>
                                                {convertToTwoDigits(employee.shiftStart.hour)}:
                                                {convertToTwoDigits(employee.shiftStart.minute)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell component='th' scope='row' align='center'>
                                            <Typography variant='caption'>
                                                {convertToTwoDigits(employee.shiftEnd.hour)}:
                                                {convertToTwoDigits(employee.shiftEnd.minute)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell component='th' scope='row' align='center'>
                                            <Typography variant='caption'>
                                                {depName?.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell component='th' scope='row' align='center'>
                                            <Typography variant='caption'>
                                                {employee.designation}
                                            </Typography>
                                        </TableCell>
                                        <TableCell component='th' scope='row' align='center'>
                                            <Typography variant='caption'>
                                                {employee.phone.countryCode} {employee.phone.phone}
                                            </Typography>
                                        </TableCell>
                                        <TableCell component='th' scope='row' align='center'>
                                            <Typography variant='caption'>
                                                {moment(employee.dateOfJoining)
                                                    .utc()
                                                    .format('MM-DD-YYYY')}
                                            </Typography>
                                        </TableCell>

                                        <TableCell component='th' scope='row' align='center'>
                                            <Typography variant='caption'>
                                                {employee.status}
                                            </Typography>
                                        </TableCell>
                                        <TableCell component='th' scope='row' align='center'>
                                            <Link to={`/view-employee/${employee._id}`}>
                                                <Tooltip title='View Employee'>
                                                    <IconButton
                                                        sx={{
                                                            borderRadius: '4px',
                                                            backgroundColor:
                                                                'custom.buttonColor.light.blue',
                                                            color: 'custom.buttonColor.dark.blue',
                                                            '&:hover': {
                                                                backgroundColor:
                                                                    'custom.buttonColor.light.blue',
                                                            },
                                                        }}>
                                                        <EditIcon fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                            </Link>
                                            <Tooltip title='Suspend Employee'>
                                                <IconButton
                                                    onClick={() =>
                                                        handleChangeTermination(employee._id)
                                                    }
                                                    sx={{
                                                        borderRadius: '4px',
                                                        ml: 1,
                                                        backgroundColor:
                                                            'custom.buttonColor.light.red',
                                                        color: 'custom.buttonColor.dark.red',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                'custom.buttonColor.light.red',
                                                        },
                                                    }}>
                                                    <PersonOffIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                            {/* <Tooltip title='Application'>
                                                <IconButton
                                                    sx={{
                                                        borderRadius: '4px',
                                                        ml: 1,
                                                        backgroundColor:
                                                            'custom.buttonColor.light.yellow',
                                                        color: 'custom.buttonColor.dark.yellow',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                'custom.buttonColor.light.yellow',
                                                        },
                                                    }}>
                                                    <ReceiptIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip> */}
                                            <Tooltip title='Attendance'>
                                                <IconButton
                                                    LinkComponent={Link}
                                                    to={`/user-attendance/${employee._id}`}
                                                    sx={{
                                                        borderRadius: '4px',
                                                        ml: 1,
                                                        background: '#F7284A',
                                                        backgroundColor:
                                                            'custom.buttonColor.light.pink',
                                                        color: 'custom.buttonColor.dark.pink',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                'custom.buttonColor.light.pink',
                                                        },
                                                    }}>
                                                    <CoPresentIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <>
                                <TableCell component='th' scope='row' align='center'></TableCell>
                                <TableCell component='th' scope='row' align='center'>
                                    <Loading />
                                </TableCell>
                                <TableCell component='th' scope='row' align='center'></TableCell>
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box
                sx={{
                    float: 'right',
                    display: 'flex',
                    alignItems: 'center',
                    mt: 2,
                }}>
                <Typography sx={{ mr: 2 }} variant='caption'>
                    Total Employee : {totalEmployee}
                </Typography>
                <Pagination
                    page={page}
                    onChange={(_, newPage) => setPage(newPage)}
                    color='primary'
                    count={pageLimit}
                />
            </Box>
            <Modal open={modalState} onClose={closeModal}>
                <TerminationModal
                    handleClose={closeModal}
                    fetchEmploees={fetchEmploees}
                    suspenId={suspenId}
                />
            </Modal>
        </>
    );
};

export default EmployeeListTable;
