import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

const PersonalDetails = ({
    firstName,
    lastName,
    dob,
    email,
    phone,
    countryCode,
    presentAddress,
    maritalStatus,
    bloodGroup,
    gender,
}) => {
    return (
        <>
            <Grid container rowSpacing={3} display='flex' alignItems='center'>
                <Grid item xs={6}>
                    <Typography variant='body2'>Name</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body1' fontWeight={500}>
                        {' '}
                        {firstName} {lastName}
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='body2'>Country Code</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box display='flex'>
                        <Typography variant='body1' fontWeight={500}>
                            {' '}
                            {countryCode}{' '}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>Contact Number</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box display='flex'>
                        <Typography variant='body1' fontWeight={500}>
                            {' '}
                            {phone}{' '}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='body2'>Date Of Birth</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body1' fontWeight={500}>
                        {' '}
                        {dob}{' '}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>Gender</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body1' fontWeight={500}>
                        {gender}
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='body2'>Email</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body1' fontWeight={500}>
                        {' '}
                        {email}{' '}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>Marital Status</Typography>
                </Grid>
                <Grid item xs={6} sx={{ mb: { lg: 2, xs: 1 } }}>
                    <Typography variant='body1' fontWeight={500}>
                        {' '}
                        {maritalStatus}{' '}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>Blood Group</Typography>
                </Grid>
                <Grid item xs={6} sx={{ mb: { lg: 2, xs: 1 } }}>
                    <Typography variant='body1' fontWeight={500}>
                        {' '}
                        {bloodGroup}{' '}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>Permanent Address</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body1' fontWeight={500}>
                        {' '}
                        {presentAddress}{' '}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default PersonalDetails;
