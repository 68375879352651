import React, { useState } from 'react';
// import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Divider,
    IconButton,
    Modal,
    Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Loading from './Loading';
import LeaveModal from './LeaveModal';
import useLoader from '../hooks/useLoader';
import useModal from '../hooks/useModal';
import { useMessage } from '../layouts/Header';
import useErrorHandler from '../hooks/useErrorHandler';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import axios from 'axios';
const columns = [
    { id: 'Leaves_Type', label: 'Leaves Type', minWidth: 150 },
    {
        id: 'No_Of_Leaves',
        label: 'No.Of Leaves',
        minWidth: 100,
        align: 'center',
    },

    {
        id: 'Action',
        label: 'Action',
        minWidth: 107,
        align: 'center',
    },
];

const LeaveTable = ({ leavesTypes, fetchLeaveType }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selectLeaveType, setSelectLeaveType] = useState({});
    const errorHandler = useErrorHandler();
    const { showSuccess } = useMessage();
    const { modalState: deleteState, openModal: openDelete, closeModal: closeDelete } = useModal();
    const { loaderState, start, end } = useLoader();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    async function deleteLeaveTypes(id) {
        start();
        try {
            const res = await axios.delete(`/hr/attendance/leaves-types/${id}`);

            if (res.data.success) {
                showSuccess('Leave type deleted');
            }
        } catch (e) {
            errorHandler(e);
        } finally {
            closeDelete();
            end();
            fetchLeaveType();
        }
    }

    return (
        <>
            <TableContainer>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                    }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {leavesTypes ? (
                            leavesTypes
                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((leavetype, id) => (
                                    <TableRow
                                        key={id}
                                        sx={{
                                            '&:last-child td, &:last-child th': {
                                                border: 0,
                                            },
                                        }}>
                                        <TableCell component='th' scope='row'>
                                            {leavetype.name}
                                        </TableCell>
                                        <TableCell component='th' scope='row' align='center'>
                                            {leavetype.noOfLeaves}
                                        </TableCell>
                                        <TableCell component='th' scope='row' align='center'>
                                            <IconButton
                                                onClick={() => {
                                                    setSelectLeaveType(leavetype);
                                                    handleOpen();
                                                }}
                                                sx={{
                                                    my: { xl: 0, xs: 1 },
                                                    mx: 1,
                                                    borderRadius: '4px',
                                                    backgroundColor:
                                                        'custom.buttonColor.light.blue',
                                                    color: 'custom.buttonColor.dark.blue',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            'custom.buttonColor.light.blue',
                                                    },
                                                }}>
                                                <EditIcon fontSize='small' />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    setSelectLeaveType(leavetype);
                                                    openDelete();
                                                }}
                                                sx={{
                                                    my: { xl: 0, xs: 1 },
                                                    mx: 1,
                                                    borderRadius: '12px',
                                                    backgroundColor: 'custom.buttonColor.light.red',
                                                    color: 'custom.buttonColor.dark.red',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            'custom.buttonColor.light.red',
                                                    },
                                                }}>
                                                <DeleteOutlineOutlinedIcon fontSize='small' />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                        ) : (
                            <>
                                <TableCell component='th' scope='row' align='center'></TableCell>
                                <TableCell component='th' scope='row' align='center'>
                                    <Loading />
                                </TableCell>
                                <TableCell component='th' scope='row' align='center'></TableCell>
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component='div'
                count={leavesTypes ? leavesTypes?.length : '0'}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Modal
                open={deleteState}
                onClose={closeDelete}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card sx={{ maxWidth: '548px', width: '100%' }}>
                    <CardContent>
                        <Typography variant='h5' fontWeight={500}>
                            Delete {selectLeaveType.name}
                        </Typography>
                        <Divider sx={{ my: 1.5 }} />
                        <Typography variant='subtitle01'>
                            Do you really want to delete the {selectLeaveType.name} ?
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ mt: 3, justifyContent: 'flex-end', p: 2 }}>
                        <Button variant='outlined' onClick={closeDelete}>
                            Cancel
                        </Button>
                        <Button
                            variant='contained'
                            color='warning'
                            onClick={() => deleteLeaveTypes(selectLeaveType._id)}
                            disabled={loaderState}
                            endIcon={
                                loaderState && (
                                    <CircularProgress size='20px' sx={{ color: 'inherit' }} />
                                )
                            }>
                            Delete
                        </Button>
                    </CardActions>
                </Card>
            </Modal>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'>
                <LeaveModal
                    handleClose={handleClose}
                    leavesTypes={leavesTypes}
                    leaveTypeId={selectLeaveType._id}
                    fetchLeaveType={fetchLeaveType}
                />
            </Modal>
        </>
    );
};

export default LeaveTable;
