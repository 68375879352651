import React, { useEffect, useState } from 'react';
import { LineChart, axisClasses } from '@mui/x-charts';

const chartSetting = {
    // width: 700,
    height: 500,

    sx: {
        [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'rotate(-90deg) translate(0px, -20px)',
        },
    },
};

const dataPrototype = {
    1: {
        Expenses: 0,
        Salary_Paid: 0,
        month: 'Jan',
    },
    2: {
        Expenses: 0,
        Salary_Paid: 0,
        month: 'Feb',
    },
    3: {
        Expenses: 0,
        Salary_Paid: 0,
        month: 'Mar',
    },
    4: {
        Expenses: 0,
        Salary_Paid: 0,
        month: 'Apr',
    },
    5: {
        Expenses: 0,
        Salary_Paid: 0,
        month: 'May',
    },
    6: {
        Expenses: 0,
        Salary_Paid: 0,
        month: 'Jun',
    },
    7: {
        Expenses: 0,
        Salary_Paid: 0,
        month: 'Jul',
    },
    8: {
        Expenses: 0,
        Salary_Paid: 0,
        month: 'Aug',
    },
    9: {
        Expenses: 0,
        Salary_Paid: 0,
        month: 'Sept',
    },
    10: {
        Expenses: 0,
        Salary_Paid: 0,
        month: 'Oct',
    },
    11: {
        Expenses: 0,
        Salary_Paid: 0,
        month: 'Nov',
    },
    12: {
        Expenses: 0,
        Salary_Paid: 0,
        month: 'Dec',
    },
};

const valueFormatter = value => `${value}`;
const DashboardChart = ({ salaries, expenses }) => {
    const [dataSet, setDataSet] = useState(Object.values(dataPrototype));

    console.log({ salaries, expenses, dataSet });
    useEffect(() => {
        if (Array.isArray(salaries)) {
            salaries.forEach(salary => {
                dataPrototype[salary._id] = {
                    ...dataPrototype[salary._id],
                    Salary_Paid: salary.salary,
                };
            });
        }
    }, [salaries]);

    useEffect(() => {
        if (Array.isArray(expenses)) {
            expenses.forEach(exp => {
                dataPrototype[exp._id] = { ...dataPrototype[exp._id], Expenses: exp.price };
            });
        }
    }, [expenses]);

    useEffect(() => {
        setDataSet(Object.values(dataPrototype));
    }, [salaries, expenses]);

    return (
        <>
            <LineChart
                dataset={dataSet}
                xAxis={[
                    {
                        scaleType: 'band',
                        dataKey: 'month',
                        categoryGapRatio: 0.4,
                    },
                ]}
                series={[
                    { dataKey: 'Salary_Paid', label: 'Salary', valueFormatter },
                    {
                        dataKey: 'Expenses',
                        label: 'Expenses',
                        valueFormatter,
                    },
                ]}
                {...chartSetting}
            />
        </>
    );
};

export default DashboardChart;
