import React, { useCallback, useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    Rating,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    circularProgressClasses,
    CircularProgressProps,
} from '@mui/material/CircularProgress';

import LinearProgress, {
    LinearProgressProps,
} from '@mui/material/LinearProgress';
import PersonalDetails from './ViewEmployees/PersonalDetails';

import { useParams } from 'react-router-dom';
import axios from 'axios';
import Loading from '../components/Loading';

function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant='determinate' {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography
                    variant='body2'
                    color='text.secondary'
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

function FacebookCircularProgress(props: CircularProgressProps) {
    return (
        <Box sx={{ position: 'relative' }}>
            <CircularProgress
                variant='determinate'
                sx={{
                    color: (theme) =>
                        theme.palette.grey[
                            theme.palette.mode === 'light' ? 200 : 800
                        ],
                }}
                size={90}
                thickness={4}
                {...props}
                value={100}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant='caption'
                    component='div'
                    color='text.secondary'
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
            <CircularProgress
                variant='determinate'
                disableShrink
                sx={{
                    color: (theme) =>
                        theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
                    animationDuration: '550ms',
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                }}
                size={90}
                thickness={4}
                {...props}
            />
        </Box>
    );
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AddEmploy = ({ mode }) => {
    const id = useParams().id;

    const [employee, setEmployee] = useState(null);

    const fetchJobsListing = useCallback(
        async function () {
            try {
                const response = await axios.get(`/hr/job-application/${id}`);
                const jobDetail = response.data.application;
                setEmployee(jobDetail);
            } catch (e) {
                console.log(e);
            }
        },
        [setEmployee, id]
    );
    console.log(employee);

    // const fetchEmployeeData = useCallback(
    //     async function () {
    //         try {
    //             const response = await axios.get(`/hr/employee/${id}`);
    //             const employee = response.data.employee;
    //             setEmployee(employee);
    //         } catch (e) {
    //             console.log(e);
    //         }
    //     },
    //     [setEmployee, id]
    // );

    useEffect(() => {
        fetchJobsListing();
    }, [fetchJobsListing]);

    // useEffect(() => {
    //     if (mode === 'edit') fetchEmployeeData();
    // }, [fetchEmployeeData]);

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ mt: '8rem' }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Holidays</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box sx={{ ml: 2 }}>
                            <Tooltip title='info' placement='top'>
                                <IconButton
                                    disableRipple
                                    variant='navIcon'
                                    sx={{ mr: 0 }}
                                >
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box my={5}>
                <Grid container spacing={4}>
                    <Grid item lg={4} xs={12} mt={6}>
                        <Card>
                            <CardContent
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Avatar
                                    alt='Remy Sharp'
                                    src='https://shorturl.at/fjqz9'
                                    sx={{
                                        width: 80,
                                        height: 80,
                                        my: 2,
                                    }}
                                />
                                <Typography variant='h6'>
                                    Faith Harris
                                </Typography>
                                <Typography color='text.secondary'>
                                    Web Designer
                                </Typography>
                                <Rating
                                    name='size-medium'
                                    defaultValue={2}
                                    sx={{ my: 1 }}
                                />
                                <Divider />
                                <Grid container spacing={2} my={1}>
                                    <Grid item lg={4} textAlign='center'>
                                        <Typography>January</Typography>
                                        <Typography>0 / 30</Typography>
                                        <Typography>Attendance</Typography>
                                    </Grid>
                                    <Grid item lg={4} textAlign='center'>
                                        <Typography>Year-2021</Typography>
                                        <Typography>0 / 41</Typography>
                                        <Typography>Leaves</Typography>
                                    </Grid>
                                    <Grid item lg={4} textAlign='center'>
                                        <Typography>Year-2021</Typography>
                                        <Typography>0 </Typography>
                                        <Typography>Awards</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card sx={{ my: 4 }}>
                            <CardContent>
                                <Typography variant='h6' sx={{ mb: 4 }}>
                                    Statistics-2021
                                </Typography>
                                <Box
                                    display='flex'
                                    alignItems='center'
                                    justifyContent='space-between'
                                    mb={6}
                                >
                                    <Box>
                                        <FacebookCircularProgress value={70} />
                                        <Typography>Attendance</Typography>
                                    </Box>
                                    <Box>
                                        <FacebookCircularProgress value={90} />
                                        <Typography textAlign='center'>
                                            Projects
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <FacebookCircularProgress value={70} />
                                        <Typography>Performance</Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography variant='caption'>
                                        This Week
                                    </Typography>
                                    <LinearProgressWithLabel value={90} />
                                </Box>
                                <Box my={2}>
                                    <Typography variant='caption'>
                                        This Month
                                    </Typography>
                                    <LinearProgressWithLabel value={90} />
                                </Box>
                                <Box>
                                    <Typography variant='caption'>
                                        This Year
                                    </Typography>
                                    <LinearProgressWithLabel value={90} />
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* form */}
                    <Grid item lg={8} xs={12}>
                        <Box sx={{ width: '100%' }}>
                            <Box
                                sx={{ borderBottom: 1, borderColor: 'divider' }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label='basic tabs example'
                                >
                                    <Tab
                                        label='Presonal Details'
                                        {...a11yProps(0)}
                                    />
                                </Tabs>
                            </Box>
                            <Card>
                                <CardContent>
                                    <Typography variant='h6' sx={{ mb: 1 }}>
                                        Basic
                                    </Typography>
                                </CardContent>
                                {employee ? (
                                    <CustomTabPanel value={value} index={0}>
                                        {employee && (
                                            <PersonalDetails
                                                employee={employee}
                                                mode={mode}
                                            />
                                        )}
                                    </CustomTabPanel>
                                ) : (
                                    <Box>
                                        <Loading />
                                    </Box>
                                )}
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default AddEmploy;
