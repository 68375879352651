import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DataGrid from '../components/DataGrid';
import axios from 'axios';
import useErrorHandler from '../hooks/useErrorHandler';
import { useMessage } from '../layouts/Header';

const Expenses = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const errorHandler = useErrorHandler();
    const { showSuccess } = useMessage();

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 5,
        page: 0,
    });
    const [rowCount, setRowCount] = useState(0);

    const fetchExpenses = useCallback(async () => {
        setLoading(true);

        console.log('fetching....');
        // setRows([]);

        try {
            const response = await axios.get(
                `/hr/expenses/?page=${paginationModel.page + 1 || 1}&pageSize=${
                    paginationModel.pageSize
                }`
            );
            const { expenses, pageData } = response.data;

            const rows = expenses.map((expense, index) => ({
                ...expense,
                id: index,
                amount: expense.price.amount,
                currency: expense.price.currency,
            }));

            setRows(rows);
            setRowCount(pageData.totalData || 0);
        } catch (e) {
            console.warn(e);
        } finally {
            setLoading(false);
        }
    }, [setRows, paginationModel]);

    const changeStatus = useCallback(
        async (id, status) => {
            try {
                const response = await axios.patch(`/hr/expenses/${id}`, { status });

                const { success } = response.data;

                if (success) {
                    showSuccess('Status changed');
                    fetchExpenses();
                }
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler, fetchExpenses, showSuccess]
    );

    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: 'S.No.',
                sortable: false,
                width: 100,
                renderCell: params => params.row.id + 1,
            },
            {
                field: 'title',
                headerName: 'Title',
                sortable: false,
                width: 200,
            },
            {
                field: 'purchasePlace',
                headerName: 'Purchase from',
                sortable: false,
                width: 200,
            },
            {
                field: 'date',
                headerName: 'Date',
                type: 'date',
                align: 'left',
                width: 150,
                renderCell: params => {
                    const { year, month, day } = params.row.dateOfPurchase;
                    return new Date(year, month, day).toLocaleDateString();
                },
            },
            {
                field: 'amount',
                headerName: 'Amount',
                width: 120,
            },
            {
                field: 'currency',
                headerName: 'Currency',
                width: 120,
            },
            {
                field: 'status',
                headerName: 'Approved Status',
                width: 200,
                renderCell: params => (
                    <Select
                        value={params.row.status}
                        size='small'
                        fullWidth
                        onChange={e => changeStatus(params.row._id, e.target.value)}>
                        <MenuItem value='Approved'>Approved</MenuItem>
                        <MenuItem value='Rejected'>Rejected</MenuItem>
                        <MenuItem value='Pending'>Pending</MenuItem>
                    </Select>
                ),
            },
        ],
        [changeStatus]
    );

    useEffect(() => {
        fetchExpenses();
    }, [fetchExpenses]);
    return (
        <>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Expenses</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0 }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box my={4}>
                <Card elevation={0}>
                    <CardContent>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            paginationMode='server'
                            pageSizeOptions={[5, 10, 50, 100]}
                            autoHeight
                            disableSelectionOnClick={true}
                            checkboxSelections={false}
                            loading={loading}
                            rowCount={rowCount}
                        />
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default Expenses;
