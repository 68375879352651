import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './utils/axios';

//pages
import Header from './layouts/Header';
import Dashboard from './pages/Dashboard';
import AddEmploy from './pages/AddEmploy';
import EmployList from './pages/EmployList';
import Attendance from './pages/Attendance/Attendance';
import UserAttendance from './pages/Attendance/UserAttendance';
import Department from './pages/Department';
import ReceivedApplication from './pages/jobApplications/ReceivedApplication';
import LeaveSetting from './pages/Attendance/LeaveSetting';
import LeaveApplication from './pages/Attendance/LeaveApplication';
import JobApplicationDetail from './pages/jobApplications/JobApplicationDetail';
import OfferLetter from './pages/jobApplications/OfferLetter';
import JobListing from './pages/Joblisting';
import InterviewQuestion from './pages/InterviewQuestion';
import Payroll from './pages/Payroll';
import Award from './pages/Award';
import Holidays from './pages/Holidays';
import EmployeeSalary from './pages/Payroll/EmployeeSalary';
import Notice from './pages/Notice/Notice';
import Docs from './pages/Docs';
import ViewEmployees from './pages/ViewEmployees/Index';
import Project from './pages/Project';
import Chat from './pages/Chat';
import UserPages from './pages/Support/UserPages';
import SelectViewEmployee from './pages/SelectViewEmployee';
import Rules from './pages/Rules&Regulations';
import Agreements from './pages/jobApplications/Agreements/Agreements';
import ApiSetting from '../src/pages/Support/Admin/ApiSetting';
import GeneralSetting from './pages/Support/Admin/GeneralSetting';
import RoleAccess from './pages/Support/Admin/RoleAccess';
import Expenses from './pages/Expenses';

const App = () => {
    return (
        <>
            <Header>
                <Routes>
                    <Route path='/' element={<Dashboard />} />
                    <Route path='/add-employee/:id' element={<AddEmploy mode='add' />} />
                    <Route path='/edit-employee/:id' element={<AddEmploy mode='edit' />} />
                    <Route path='/attendance' element={<Attendance />} />
                    <Route path='/employlist' element={<EmployList />} />
                    <Route path='/JobListing' element={<JobListing />} />
                    <Route path='/interview-question' element={<InterviewQuestion />} />
                    <Route path='/department' element={<Department />} />
                    <Route path='/add-payroll' element={<Payroll />} />
                    <Route path='/award' element={<Award />} />
                    <Route path='/employee-salary' element={<EmployeeSalary />} />
                    <Route path='/leave-setting' element={<LeaveSetting />} />
                    <Route path='/leave-application' element={<LeaveApplication />} />
                    <Route path='/received-application' element={<ReceivedApplication />} />
                    <Route path='/expenses' element={<Expenses />} />
                    <Route path='/jobApplicationDetail'>
                        <Route path=':id' element={<JobApplicationDetail />} />
                        <Route path='offer-letter/:id' element={<OfferLetter />} />
                        <Route path='agreements/:id' element={<Agreements />} />
                    </Route>
                    <Route path='/user-attendance' element={<UserAttendance />} />
                    <Route path='/user-attendance/:id' element={<UserAttendance />} />
                    <Route path='/holidays' element={<Holidays />} />
                    <Route path='/notice' element={<Notice />} />
                    <Route path='/docs' element={<Docs />} />
                    <Route path='/projects' element={<Project />} />
                    <Route path='/rules-&-regulations' element={<Rules />} />
                    <Route path='/view-employee/:id' element={<ViewEmployees />} />
                    <Route path='/view-employee' element={<SelectViewEmployee />} />
                    {/* support */}
                    <Route path='/support'>
                        <Route path='landing-pages/land-page' index element={<UserPages />} />
                        <Route path='userpage-dashboard' element={<UserPages />} />
                    </Route>
                    {/* chat */}
                    <Route path='/chat' element={<Chat />} />
                    <Route path='/admin/api-setting' element={<ApiSetting />} />
                    <Route path='/admin/general-setting' element={<GeneralSetting />} />
                    <Route path='/admin/role-access' element={<RoleAccess />} />
                </Routes>
            </Header>
        </>
    );
};

export default App;
