import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LeaveApplicationCard from '../../components/LeaveApplicationCard';
import axios from 'axios';
import useErrorHandler from '../../hooks/useErrorHandler';

const LeaveApplication = () => {
    const [LeaveApplications, seLeaveApplications] = useState([]);
    const errorHandler = useErrorHandler();

    const fetchLeaveApplication = useCallback(
        async function () {
            try {
                const response = await axios.get(
                    '/hr/attendance/leaves?searchBy=status&search=Pending'
                );
                seLeaveApplications(response.data.leaves);
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler]
    );

    useEffect(() => {
        fetchLeaveApplication();
    }, [fetchLeaveApplication]);

    return (
        <>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Leave Applications</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box sx={{ ml: 2 }}>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0 }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {/* {LeaveApplications ? ( */}
            <Box>
                <Box mt={5}>
                    <Grid container spacing={2}>
                        {LeaveApplications.length ? (
                            LeaveApplications.map(application => {
                                const date = new Date(application.updatedAt);
                                let Applied = date.toLocaleTimeString();

                                const from = new Date(
                                    `${application.dates[0]?.month}-${application.dates[0]?.day}-${application.dates[0]?.year}`
                                );

                                const to = application.dates[1]
                                    ? new Date(
                                          `${application.dates[1]?.month}-${application.dates[1]?.day}-${application.dates[1]?.year}`
                                      )
                                    : new Date(
                                          `${application.dates[0]?.month}-${application.dates[0]?.day}-${application.dates[0]?.year}`
                                      );
                                const timeDifference = Math.abs(from - to);
                                const daysRemaining = Math.ceil(
                                    timeDifference / (1000 * 60 * 60 * 24)
                                );

                                return (
                                    <Grid item xs={12} sm={6} lg={4} xl={3} key={application._id}>
                                        <LeaveApplicationCard
                                            fetchLeaveApplication={fetchLeaveApplication}
                                            id={application._id}
                                            name={application.fullName}
                                            department={application.department}
                                            Applied={Applied}
                                            from={from}
                                            to={to}
                                            reason={application.reason}
                                            daysRemaining={daysRemaining}
                                            date={date}
                                        />
                                    </Grid>
                                );
                            })
                        ) : (
                            <Box
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                height='50vh'
                                mx='auto'>
                                <Typography variant='h5' color='text.secondary' mb={2}>
                                    It seems there's no record of a leave application as the
                                    employee hasn't submitted one yet.
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Box>
                {/* <Box my={5}>
                    <Card elevation={0} sx={{ position: 'relative' }}>
                        <CardContent>
                            <Box
                                sx={{
                                    borderLeftWidth: '5px',
                                    borderLeftColor: 'primary.main',
                                    borderLeftStyle: 'solid',
                                    height: '40px',
                                    width: '5px',
                                    position: 'absolute',

                                    left: 1,
                                }}></Box>
                            <Typography variant='h6' sx={{ mb: 3 }}>
                                {' '}
                                Leaves Summary
                            </Typography>
                            <LeaveApplicationTable LeaveApplications={LeaveApplications} />
                        </CardContent>
                    </Card>
                </Box> */}
            </Box>
        </>
    );
};

export default LeaveApplication;
