import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList } from '@mui/base/TabsList';
import { TabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab, tabClasses } from '@mui/base/Tab';
import Chatpage from './Chatpage';
import ChatDetail from './ChatDetail';

const index = () => {
    return (
        <>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Chat</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box sx={{ ml: 2 }}>
                            <Tooltip title='info' placement='top'>
                                <IconButton
                                    disableRipple
                                    variant='navIcon'
                                    sx={{ mr: 0 }}
                                >
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box my={3}>
                <Grid container>
                    <Grid itam lg={4} xs={12}>
                        <Card
                            sx={{
                                height: '84vh',
                                boxShadow: 'none',
                                borderRight: '2px solid grey',
                            }}
                            elevation={0}
                        >
                            <Tabs defaultValue={0}>
                                <Box>
                                    <StyledTabsList>
                                        <StyledTab value={0}>Chat</StyledTab>
                                        <StyledTab value={1}>Contact</StyledTab>
                                    </StyledTabsList>
                                </Box>
                                <Box
                                    sx={{
                                        overflowY: 'auto',
                                        maxHeight: '70vh',
                                        '::-webkit-scrollbar': {
                                            width: '3px',
                                            //     backgroundColor: 'transparent',
                                        },
                                    }}
                                >
                                    <StyledTabPanel value={0}>
                                        <Divider sx={{ mb: 2 }} />
                                        <Chatpage />
                                        <Chatpage />
                                        <Chatpage />
                                        <Chatpage />
                                        <Chatpage />
                                        <Chatpage />
                                    </StyledTabPanel>
                                    <StyledTabPanel value={1}>
                                        <Divider sx={{ mb: 2 }} />
                                        <Chatpage />
                                        <Chatpage />
                                        <Chatpage />
                                        <Chatpage />
                                    </StyledTabPanel>
                                </Box>
                            </Tabs>
                        </Card>
                    </Grid>
                    <Grid item lg={8} xs={12}>
                        <Card
                            sx={{ height: '84vh', boxShadow: 'none' }}
                            elevation={0}
                        >
                            <CardContent>
                                <ChatDetail />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default index;

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
};

const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
};

const StyledTab = styled(Tab)`
    font-family: 'IBM Plex Sans', sans-serif;
    color: #fff;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 600;
    background-color: transparent;
    width: 50%;
    padding: 10px 12px;
    margin: 6px;
    border: none;
    border-radius: 7px;
    display: flex;
    justify-content: center;

    &:hover {
        background-color: ${blue[400]};
    }

    &:focus {
        color: #fff;
        outline: 3px solid ${blue[200]};
    }

    &.${tabClasses.selected} {
        background-color: #fff;
        color: ${blue[600]};
    }

    &.${buttonClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

const StyledTabPanel = styled(TabPanel)(
    ({ theme }) => `
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
//   padding: 10px 12px;
//   background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
//   border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
//   border-radius: 12px;
  opacity: 0.6;
  `
);

const StyledTabsList = styled(TabsList)(
    ({ theme }) => `
  min-width: 200px;
  background-color: ${theme.palette.primary.main};
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  margin:20px 15px;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 30px ${
      theme.palette.mode === 'dark' ? grey[900] : grey[200]
  };
  `
);
