import React, { useCallback, useEffect, useState } from 'react';

import {
    Box,
    Card,
    CardContent,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import ManIcon from '@mui/icons-material/Man';
import Woman2Icon from '@mui/icons-material/Woman2';
import axios from 'axios';

const EmployeesCard = () => {
    const [employeeMetrics, setEmployeeMetrics] = useState(null);

    const fetchMetrics = useCallback(
        async function () {
            try {
                const response = await axios.get(`/hr/employee/metrics`);
                setEmployeeMetrics(response.data.metrics);
            } catch (e) {
                console.log(e);
            }
        },
        [setEmployeeMetrics]
    );

    useEffect(() => {
        fetchMetrics();
    }, [fetchMetrics]);
    return (
        <>
            {employeeMetrics?.map((metric) => (
                <>
                    <Grid item lg={6} xs={12}>
                        <Card elevation={0}>
                            <CardContent
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box>
                                    <Typography mb={2}>
                                        Total Employees
                                    </Typography>
                                    <Typography
                                        variant='h5'
                                        sx={{ color: '#3366FF' }}
                                    >
                                        {metric.total}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        sx={{
                                            background: '#3366FF',
                                            borderRadius: '8px',
                                            '&:hover': {
                                                background: '#3366FF',
                                            },
                                        }}
                                    >
                                        {/*  */}
                                        <GroupIcon sx={{ fontSize: '20px' }} />
                                    </IconButton>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/*  */}
                    <Grid item lg={6} xs={12}>
                        <Card elevation={0}>
                            <CardContent
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box>
                                    <Typography mb={2}>
                                        Female Employees
                                    </Typography>
                                    <Typography
                                        variant='h5'
                                        sx={{ color: '#FE7F00' }}
                                    >
                                        {metric.female}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        sx={{
                                            background: '#FE7F00',
                                            borderRadius: '8px',
                                            '&:hover': {
                                                background: '#FE7F00',
                                            },
                                        }}
                                    >
                                        {/*  */}
                                        <Woman2Icon sx={{ fontSize: '20px' }} />
                                    </IconButton>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Card elevation={0}>
                            <CardContent
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box>
                                    <Typography mb={2}>
                                        Male Employees
                                    </Typography>
                                    <Typography
                                        variant='h5'
                                        sx={{ color: '#3366FF' }}
                                    >
                                        {metric.male}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        sx={{
                                            background: '#3366FF',
                                            borderRadius: '8px',
                                            '&:hover': {
                                                background: '#3366FF',
                                            },
                                        }}
                                    >
                                        {/*  */}
                                        <ManIcon sx={{ fontSize: '20px' }} />
                                    </IconButton>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/*  */}
                    <Grid item lg={6} xs={12}>
                        <Card elevation={0}>
                            <CardContent
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box>
                                    <Typography mb={2}>
                                        Terminated Employees
                                    </Typography>
                                    <Typography
                                        variant='h5'
                                        sx={{ color: '#F7284A' }}
                                    >
                                        {metric.terminated}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        sx={{
                                            background: '#F7284A',
                                            borderRadius: '8px',
                                            '&:hover': {
                                                background: '#F7284A',
                                            },
                                        }}
                                    >
                                        {/*  */}
                                        <GroupIcon sx={{ fontSize: '20px' }} />
                                    </IconButton>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </>
                //
            ))}
        </>
    );
};

export default EmployeesCard;
