import React, { useState } from 'react';
import {
    Radio,
    RadioGroup,
    FormControl,
    Typography,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    FormControlLabel,
    TextField,
    Switch,
    Grid,
    Tabs,
    Tab,
    Paper,
    Button,
    Menu,Card, CardContent
} from '@mui/material';

import SettingsIcon from '@mui/icons-material/Settings';
import SecurityIcon from '@mui/icons-material/Security';
import PaletteIcon from '@mui/icons-material/Palette';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EmailIcon from '@mui/icons-material/Email';
import ChatIcon from '@mui/icons-material/Chat';
import CodeIcon from '@mui/icons-material/Code';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ChromePicker } from 'react-color';


const GeneralSettingTab = () => {
 
    const languages = [
        { code: 'en', name: 'English' },
        { code: 'es', name: 'Spanish' },
        { code: 'fr', name: 'French' },
        // Add more languages as needed
      ];

      const Date = [
        { code: '1', name: 'MM/DD/YY' },
        { code: '2', name: 'DD/MM/YY' },
        { code: '3', name: 'YY/MM/DD' },
        
        // Add more date format as needed
      ];

      const time = [
        { code: '1', name: '24H (HH:MM:SS)' },
        { code: '2', name: '24H (HH:MM)' },
        { code: '3', name: '24H (HH:MM:SS:XM)' },
        { code: '4', name: '12H (HH:MM:SS)' },
        { code: '5', name: '12H (HH:MM)' },
        { code: '6', name: '12H (HH:MM:SS:XM)' },
        
        // Add more time format as needed
      ];

    return <div>
    <Typography variant="h5" marginbottom={3} spacing={2}>Basic Settings</Typography>

    <Grid container spacing={0.5} marginTop={3}>
        <Grid item xs={12} sm={3} marginTop="6.5px">
            <Typography style={{ fontSize: '14px' }}>App Title</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
            <TextField
                variant="outlined"
                placeholder="Name"
                fullWidth
                size='small'
            />
        </Grid>
    </Grid>
    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3} marginTop="6.5px">
            <Typography style={{ fontSize: '14px' }} >App Email</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
            <TextField
                variant="outlined"
               placeholder="Name"
                fullWidth
                size='small'
                // You can add more TextField props as needed
            />
        </Grid>
    </Grid>
    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3} marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Admin Panel Language</Typography>
        </Grid>
        <Grid item xs={11} sm={8.5}>
        <FormControl fullWidth style={{ height: 50 }}>
          <InputLabel size='small' id="language-select-label" >Select Language</InputLabel>
          <Select
            labelId="language-select-label"
            placeholder="Select Language"
            defaultValue=""
            style={{ height: '80%' }}
          >
            {languages.map((language) => (
              <MenuItem key={language.code} value={language.code}>
                {language.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }} >Site Panel Language</Typography>
        </Grid>
        <Grid item xs={11} sm={8.5}>
        <FormControl fullWidth  style={{ height: 50 }}>
        <InputLabel size='small' id="language-select-label">Select Language</InputLabel>
        <Select
          labelId="language-select-label"
          placeholder="Select Language"
          defaultValue="" // Set a default language if needed
          // Other Select props
          style={{ height: '75%' }}
        >
          {languages.map((language) => (
            <MenuItem key={language.code} value={language.code}>
              {language.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
                </Grid>
    </Grid>
    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }} >Select Date-Format</Typography>
        </Grid>
        <Grid item xs={11} sm={8.5}>
        <FormControl fullWidth style={{ height: 50 }}>
        <InputLabel size='small' id="date-select-label">Select Date-Format</InputLabel>
        <Select
          labelId="date-select-label"
        
          defaultValue="" // Set a default language if needed
          // Other Select props
          style={{ height: '80%' }}
        >
          {Date.map((Date) => (
            <MenuItem key={Date.code} value={Date.code}>
              {Date.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
                </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }} >Select Time-Format</Typography>
        </Grid>
        <Grid item xs={11} sm={8.5}>
        <FormControl fullWidth style={{ height: 50 }}>
        <InputLabel id="time-select-label" size='small'>Select Time-Format</InputLabel>
        <Select
          labelId="time-select-label"
          placeholder="Select Time-Format"
          defaultValue="" // Set a default language if needed
          // Other Select props
          style={{ height: '80%' }}
        >
          {time.map((time) => (
            <MenuItem key={time.code} value={time.code}>
              {time.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
                </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }} >Default Login</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3} marginTop="10px">
            <Typography style={{ fontSize: '14px' }} >Default Registration</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }} >Google Font  </Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5} >
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }} >RTL Direction</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>

    
    <Grid item>
                        <Grid container spacing={0} marginTop={3} marginbottom={2}>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    style={{
                                    
                                        marginRight: '5px',
                                    }}
                                >
                                    Save Changes
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' color='error'>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                        </Grid>
                        
</div>; 
};

const CaptchSettings = () => {

   const selectvalue = [
        { code: '1', name: '1' },
        { code: '2', name: '2' },
        { code: '3', name: '3' },
        { code: '4', name: '4' },
        { code: '5', name: '5' },
        { code: '6', name: '6' },
        
        
      ];

    return <div>
        <Typography variant="h5"marginbottom={5}>Captcha Settings </Typography>

<Grid container spacing={0.5} marginTop={3}>
    <Grid item xs={12} sm={3} marginTop="10px">
        <Typography style={{ fontSize: '14px' }}>Captch Type</Typography>
    </Grid>
    
    <Grid item xs={11} sm={8.5}>
    <RadioGroup row size='small'>
                                <FormControlLabel
                                    value='Default'
                                    control={<Radio color='primary' />}
                                    label='Default'
                                    size="small"
                                />
                                <FormControlLabel
                                    value='Google Re-Captch'
                                    control={<Radio color='primary' />}
                                    label='Google Re-Captch'
                                    size="small"
                                />
                            </RadioGroup>
    </Grid>
</Grid>
<Grid container spacing={0.5} marginTop={1}>
    <Grid item xs={12} sm={3} marginTop="10px">
        <Typography style={{ fontSize: '14px' }} >Captch String</Typography>
    </Grid>
    
    <Grid item xs={11} sm={8.5}>
    <RadioGroup row size='small'>
                                <FormControlLabel
                                    value='Alpha Numeric'
                                    control={<Radio color='primary' />}
                                    label='Default'
                                    size="small"
                                />
                                <FormControlLabel
                                    value='Numeric'
                                    control={<Radio color='primary' />}
                                    label='Alpha Numeric'
                                    size="small"
                                />
                            </RadioGroup>
    </Grid>
</Grid>
<Grid container spacing={0.5} marginTop={1}>
    <Grid item xs={12} sm={3} marginTop="10px">
        <Typography style={{ fontSize: '14px' }}>Captch Length </Typography>
    </Grid>
    <Grid item xs={11} sm={8.5}>
                <FormControl fullWidth style={{ height: 50 }}>
                    <InputLabel id="dropdown-id" size='small'>Choose a Length...</InputLabel>
                    <Select
                        labelId="dropdown-id"
                        placeholder="Dropdown"
                        variant="outlined"
                        size='small'
                        defaultValue=""
                        style={{ height: '80%' }}
                    >{selectvalue.map((selectvalue) => (
                        <MenuItem key={selectvalue.code} value={selectvalue.code}>
                          {selectvalue.name}
                        </MenuItem>
                      ))}
                    </Select>
                </FormControl>
            </Grid>
</Grid>
<Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }} >Client Login</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>
    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }} >Guest Ticket</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>
    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3} marginTop="10px">
            <Typography style={{ fontSize: '14px' }} >Client Registartion</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>
    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3} marginTop="10px">
            <Typography style={{ fontSize: '14px' }} >Admin Login</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>
    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3} marginTop="10px">
            <Typography style={{ fontSize: '14px' }} >Admin Registration</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>
    
    <Grid item>
                        <Grid container spacing={0} marginTop={3} marginbottom={2}>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    style={{
                                      
                                        marginRight: '5px',
                                    }}
                                >
                                    Save Changes
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' color='error'>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                        </Grid>
                        
                        
    </div>; 
};

const ThemeSettings = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedColor, setSelectedColor] = useState('#1FC000');
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [selectedColor1, setSelectedColor1] = useState('#592000');

    const handleColorChange = (color) => {
      setSelectedColor(color.hex);
      setAnchorEl(null); // Close the menu after selecting a color
    };
  
    const handleColorChange1 = (color) => {
      setSelectedColor1(color.hex);
      setAnchorEl1(null); // Close the menu after selecting a color
    };
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
      };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
      };
  
     
       
    return (
      
         <div>
            
              <Typography variant="h5" marginbottom={2}>  Layout & Color Settings </Typography>
    
              <Grid container spacing={0.5} marginTop={3}  alignitems="center">
                <Grid item xs={12} sm={3}marginTop="10px">
                  <Typography>Application Layout</Typography>
                </Grid>
                <Grid item xs={11} sm={8.5}>
                  <RadioGroup row size="small">
                 
                       <FormControlLabel
                                    value='Default'
                                    control={<Radio color='primary' />}
                                    label='Full Width'
                                    size="small"
                                />
                                <FormControlLabel
                                    value='Box'
                                    control={<Radio color='primary' />}
                                    label='Box'
                                />
                  </RadioGroup>
                </Grid>
              </Grid>
        
              {/* Theme Color */}
              <Grid container spacing={1} style={{ marginTop: 1 }} alignitems="center">
                <Grid item xs={12} sm={3} marginTop="6px">
                  <Typography style={{ fontSize: '14px' }}variant="subtitle1">Theme Color</Typography>
                </Grid>
                <Grid item xs={11} sm={8.5}>
                  {/* ... (your Theme Color components) */}
                      <Button
                                variant="contained"
                                onClick={handleClick}
                                 size='small'
                                style={{ backgroundColor: selectedColor, width: '155px', height: '32px' }}
                            >
                                Select Color
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem>
                                    <ChromePicker color={selectedColor} onChange={handleColorChange} />
                                </MenuItem>
                            </Menu>
                </Grid>
              </Grid>
        
              {/* Text Color */}
              <Grid container spacing={1} style={{ marginTop:1 }} alignitems="center">
                <Grid item xs={12} sm={3} marginTop="10px">
                  <Typography style={{ fontSize: '14px' }}variant="subtitle1">Text Color</Typography>
                </Grid>
                <Grid item xs={11} sm={8.5}>
                  {/* ... (your Text Color components) */}
                     <Button
                                variant="contained"
                                onClick={handleClick1}
                                style={{ backgroundColor: selectedColor1, width: '155px', height: '32px' }}
                            >
                                Select Color
                            </Button>
                            <Menu
                                anchorEl={anchorEl1}
                                open={Boolean(anchorEl1)}
                                onClose={handleClose1}
                            >
                                <MenuItem>
                                    <ChromePicker color={selectedColor1} onChange={handleColorChange1} />
                                </MenuItem>
                            </Menu>
                </Grid>
              </Grid>
        
              {/* Save and Cancel Buttons */}
              <Grid item>
                        <Grid container spacing={0} marginTop={3} marginbottom={2}>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    style={{
                                      
                                        marginRight: '5px',
                                    }}
                                >
                                    Save Changes
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' color='error'>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                        </Grid>
            </div>
    );
};

const FileuploadSettings = () => {
    return <div><Typography variant="h5"marginbottom={5}>File Upload Settings</Typography>

    <Grid container spacing={0.5} marginTop={3}>
        <Grid item xs={12} sm={3} marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Max Upload Size</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
            <TextField
                variant="outlined"
                value="5 GB"
                fullWidth
                size='small'
                
            />
        </Grid>
    </Grid>
    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3} marginTop="10px">
            <Typography style={{ fontSize: '14px' }} >Allowed File Type </Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
            <TextField
                variant="outlined"
                value={"jpg/png/zip/doc/"}
                fullWidth
                size='small'
               
            />
        </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }} >Client Photo Upload</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }} >Ticket File Upload</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>
    
    <Grid item>
                        <Grid container spacing={0} marginTop={3} marginbottom={2}>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    style={{
                                        marginRight: '5px',
                                    }}
                                >
                                    Save Changes
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' color='error'>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                        </Grid>
                        
    </div>; 
};

const TicketSettings = () => {
    return <div> <Typography variant="h5"marginbottom={5}>Ticket Settings</Typography>

    <Grid container spacing={0.5} marginTop={3}>
        <Grid item xs={12} sm={3.5} marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Guest Ticket</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>


    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3.5}marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Show all Priority Guest Ticket</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3.5}marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Any User Can Reply?</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>
    
    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3.5}marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Auto Close</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3.5} marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Max Open Ticket</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8}>
            <TextField
                variant="outlined"
                placeholder="text"
                fullWidth
                size='small'
                
            />
        </Grid>
    </Grid>

    <Grid container spacing={0.2} >
        <Grid item xs={12} sm={3.5}marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Re-Open Ticket</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3.5}marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Re-Open Time</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8}>
            <TextField
                variant="outlined"
                placeholder="text"
                width="5px"
                size='small'
                
            /><TextField
            variant="outlined"
            width="2px"
            size='small'
            value={'hours'}
            InputProps={{ style: { width: '70px' } }}
            
        />
        </Grid>
    </Grid>

    <Grid container spacing={0.2} >
        <Grid item xs={12} sm={3.5}marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Ticket Feedback</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey'}}
                        />
        </Grid>
    </Grid>
    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3.5} marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Positive Message</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8}>
            <TextField
                variant="outlined"
                placeholder='Something text here..'
              fullWidth
              InputProps={{ style: { height: '80px' } }}
                size='small'
                
            />
        </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3.5} marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Nagative Message</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8}>
            <TextField
                variant="outlined"
                placeholder='Something text here..'
              fullWidth
              InputProps={{ style: { height: '80px' } }}
                size='small'
                
            />
        </Grid>
    </Grid>

    
    <Grid item>
                        <Grid container spacing={0} marginTop={3} marginbottom={2}>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    style={{
                                    
                                        marginRight: '5px',
                                    }}
                                >
                                    Save Changes
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' color='error'>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                        </Grid>
                        
    </div>; 
};

const NotificationSettings = () => {
    return <div><Typography variant="h5"marginbottom={5}>Ticket Settings</Typography>

    <Grid container spacing={0.5} marginTop={3}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Ticket Open</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Ticket Assign</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>
    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Ticket User Reply</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>
    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Ticket Admin Reply</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>

    <Typography variant="h5" marginTop={3} marginbottom={3}>Window Screen Notification Settings</Typography>

    <Grid container spacing={0.5} marginTop={3}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Ticket Open</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Ticket Assign</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>
    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3} marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Ticket User Reply</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>
    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px" >
            <Typography style={{ fontSize: '14px' }}>Ticket Admin Reply</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>
    </Grid>
        
    <Grid item>
                        <Grid container spacing={0} marginTop={3} marginbottom={2}>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    style={{
                                      
                                        marginRight: '5px',
                                    }}
                                >
                                    Save Changes
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' color='error'>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                        </Grid>

</div>; 
};

const EmailSettings = () => {
    return <div>
        <Typography variant="h5"marginbottom={5}>Email Settings</Typography>
        <Grid container spacing={0.5} marginTop={3}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Name</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
            <TextField
                variant="outlined"
                placeholder="name"
                fullWidth
                size='small'
                
            />
        </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Email</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
            <TextField
                variant="outlined"
                placeholder="name"
                fullWidth
                size='small'
                
            />
        </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Reply to Email</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
            <TextField
                variant="outlined"
                placeholder="name"
                fullWidth
                size='small'
                
            />
        </Grid>
    </Grid>

    
<Grid container spacing={0.2} >
    <Grid item xs={12} sm={3}marginTop="10px">
        <Typography style={{ fontSize: '14px' }}>Captch Type</Typography>
    </Grid>
    
    <Grid item xs={11} sm={8.5}>
    <RadioGroup row size='small'>
                                <FormControlLabel
                                    value='Default'
                                    control={<Radio color='primary' />}
                                    label='Send Mail'
                                    size="small"
                                />
                                <FormControlLabel
                                    value='Google Re-Captch'
                                    control={<Radio color='primary' />}
                                    label='SMTP'
                                    size='small'
                                />
                            </RadioGroup>
    </Grid>
</Grid>
    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Sendmail Path</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
            <TextField
                variant="outlined"
                name="name"
                fullWidth
                size='small'
                
            />
        </Grid>
    </Grid>
    <Grid item>
                        <Grid container spacing={0} marginTop={3} marginbottom={2}>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    style={{
                                      
                                        marginRight: '5px',
                                    }}
                                >
                                    Save Changes
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' color='error'>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                        </Grid>
    </div>
};

const ChatSettings = () => {
    return <div>
        <Typography variant="h5"marginbottom={5}>Chat Settings</Typography>

<Grid container spacing={0.5} marginTop={3}>
    <Grid item xs={12} sm={3}marginTop="10px">
        <Typography style={{ fontSize: '14px' }}>Chat</Typography>
    </Grid>
    
    <Grid item xs={11} sm={8.5}>
    <FormControlLabel
                        control={<Switch color='primary' />}
                        label='Enable/Disable'
                        labelPlacement='end'
                        style={{ color: 'grey' }}
                    />
    </Grid>
</Grid>
  
<Grid container spacing={0.5} marginTop={1}>
    <Grid item xs={12} sm={3}marginTop="10px">
        <Typography style={{ fontSize: '14px' }}>Chat Type</Typography>
    </Grid>
    
    <Grid item xs={11} sm={8.5}>
    <RadioGroup row size='small'>
                                <FormControlLabel
                                    value='Default Chat'
                                    control={<Radio color='primary' />}
                                    label='Default Chat'
                                    size="small"
                                />
                                <FormControlLabel
                                    value='Live Chat'
                                    control={<Radio color='primary' />}
                                    label='Live Chat'
                                    size="small"
                                />
                            </RadioGroup>
    </Grid>
</Grid>

<Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>Title</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
            <TextField
                variant="outlined"
                placeholder="name"
                fullWidth
                size='small'
                
            />
        </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="10px">
            <Typography style={{ fontSize: '14px' }}>TagLine</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
            <TextField
                variant="outlined"
                placeholder="name"
                fullWidth
                size='small'
                
            />
        </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="25px">
            <Typography style={{ fontSize: '14px' }}>Offline Text</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
            <TextField
                variant="outlined"
                placeholder='Something text here..'
              fullWidth
              InputProps={{ style: { height: '80px' } }}
                size='small'
                
            />
        </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="25px">
            <Typography style={{ fontSize: '14px' }}>Agent Welcome Text</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
            <TextField
                variant="outlined"
                placeholder='Something text here..'
              fullWidth
              InputProps={{ style: { height: '80px' } }}
                size='small'
                
            />
        </Grid>
    </Grid>


<Grid item>
                    <Grid container spacing={0} marginTop={3} marginbottom={2}>
                        <Grid item>
                            <Button
                                variant='contained'
                                style={{
                                   
                                    marginRight: '5px',
                                }}
                            >
                                Save Changes
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='error'>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                    </Grid>

    </div>; 
};

const CustomSettings = () => {
    return <div>

<Typography variant="h5"marginbottom={5}>Custom (CSS/Javascript)</Typography>
<Grid container spacing={0.5} marginTop={3}>
        <Grid item xs={3} marginbottom={1} > 
            <Typography style={{ fontSize: '14px' }}>CSS Styles</Typography>
        </Grid>
        
        <Grid item xs={11.5}>
            <TextField
                variant="outlined"
                placeholder='Something text here CSS Style..'
              fullWidth
              InputProps={{ style: { height: '120px' } }}
                size='small'
                
            />
        </Grid>
    </Grid>

    <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={3} marginbottom={1}>
            <Typography style={{ fontSize: '14px' }}>JavaScript Styles</Typography>
        </Grid>
        
        <Grid item xs={11.5}>
            <TextField
                variant="outlined"
                placeholder='Something text here to Scripting Section ..'
              fullWidth
              InputProps={{ style: { height: '120px' } }}
                size='small'
                
            />
        </Grid>
    </Grid>

    
<Grid item>
                    <Grid container spacing={0} marginTop={3} marginbottom={2}>
                        <Grid item>
                            <Button
                                variant='contained'
                                style={{
                                 
                                    marginRight: '5px',
                                }}
                            >
                                Save Changes
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='error'>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                    </Grid>

    </div>; 
};


const GeneralSetting = () => {
    const [selectedTab, setSelectedTab] = useState(0); // State to keep track of selected tab

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const renderForm = () => {
        switch (selectedTab) {
            case 0:
                return <GeneralSettingTab />;
            case 1:
                return <CaptchSettings/>;
            case 2:
                return <ThemeSettings/>;
            case 3:
                return <FileuploadSettings/>
            case 4:
                return <TicketSettings/>
            case 5:
                return <NotificationSettings/>
            case 6:
                return <EmailSettings/>
            case 7:
                return <ChatSettings/>
            case 8:
                return <CustomSettings/>

            default:
                return null;
        }
    };

    return (    <div>
        <div style={{ marginbottom: 10 }}>
          <Grid container spacing={2} marginTop={1} alignitems="center" paddingLeft={2}>
            <Grid item xs>
              <Typography variant="h5">General Settings</Typography>
            </Grid>
            <Grid item sx={{ display: { xs: 'flex' }, mx: 'auto' }} alignitems="center">
              <div>
                <IconButton variant="navIcon" disableRipple style={{ marginRight: 0, paddingRight: 2 }}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </div>
  
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Paper style={{ width: '100%', marginbottom: '20px', height: '500px', marginTop: '15px',borderRadius:'12px' }}>
              <Tabs
                orientation="vertical"
                value={selectedTab}

                onChange={handleTabChange}
                textColor="primary"
                alignitems="right"
                
              >
                <Tab icon={<SettingsIcon  style={{height:'20px',marginTop:'5px'}}/>} sx={{ flexDirection: 'row',  alignItems: 'center',justifyContent: 'flex-start', gap: '8px',minHeight: '55px' }} label="General Settings" />
              
                <Tab icon={<SecurityIcon  style={{height:'20px',marginTop:'5px'}} />}  sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start',minHeight: '55px', gap: '8px' }} label="Captch Settings" />
                <Tab icon={<PaletteIcon   style={{height:'20px',marginTop:'5px'}}/>}  sx={{ flexDirection: 'row',  alignItems: 'center',justifyContent: 'flex-start', minHeight: '55px',gap: '8px' }} label="Theme Settings" />
                <Tab icon={<CloudUploadIcon  style={{height:'20px',marginTop:'5px'}}/>}  sx={{ flexDirection: 'row',  alignItems: 'center',justifyContent: 'flex-start',minHeight: '55px', gap: '8px' }} label="File upload Settings" />
                <Tab icon={<ConfirmationNumberIcon  style={{height:'20px',marginTop:'5px'}} />}  sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start',minHeight: '55px', gap: '8px' }} label="Ticket Settings" />
                <Tab icon={<NotificationsIcon   style={{height:'20px',marginTop:'5px'}}/>}  sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start',minHeight: '55px', gap: '8px' }} label="Notification Settings" />
                <Tab icon={<EmailIcon  style={{height:'20px',marginTop:'5px'}} />}  sx={{ flexDirection: 'row',  alignItems: 'center',justifyContent: 'flex-start',minHeight: '55px', gap: '8px' }} label="Email Settings" />
                <Tab icon={<ChatIcon   style={{height:'20px',marginTop:'5px'}}/>}  sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', minHeight: '55px',gap: '8px' }} label="Chat Settings" />
                <Tab icon={<CodeIcon  style={{height:'20px',marginTop:'5px'}}/>}  sx={{ flexDirection: 'row',  alignItems: 'center',justifyContent: 'flex-start',minHeight: '55px', gap: '8px' }} label="Custom(CSS/JavaScript)" />
              </Tabs>
            </Paper>
            
          </Grid>
  
          <Grid item xs={12} md={9}>
            <div style={{ marginTop:'20px', position: 'relative', width: '100%', border: '1px solid #000' }}>
              <Card sx={{ borderRadius: '12px', width: '100%', marginbottom: '20px' }}>
                <CardContent style={{ width: '100%' }}>
                  <div style={{ width: '100%', height: '70%', marginLeft: '20px' }}>
                    {renderForm()}
                  </div>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
    );
};


export default GeneralSetting;
