import { Avatar, Box, Divider, Typography } from '@mui/material';
import React from 'react';

const Chatpage = () => {
    return (
        <>
            <Box display='flex' justifyContent='space-around' mb={2}>
                <Box display='flex' alignItems='center'>
                    <Box mr={2}>
                        <Avatar
                            alt='Remy Sharp'
                            src='https://shorturl.at/fjqz9'
                        />
                    </Box>
                    <Box>
                        <Typography variant='body1'>Gaurav Gupta</Typography>

                        <Typography color='text.secondary' variant='caption'>
                            gauravgupta@gmail.com
                        </Typography>
                    </Box>
                </Box>
                <Typography variant='caption' sx={{ textAlign: 'right' }}>
                    12 mins
                </Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
        </>
    );
};

export default Chatpage;
