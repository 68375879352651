import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    IconButton,
    Modal,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import Calender from '../components/Calender';
import HolidayList from '../components/HolidayList';
import Search from '../components/Search';
import { Form, Submit, useForm } from '../hooks/useForm/useForm';
import { Input } from '../hooks/useForm/inputs';
import { useMessage } from '../layouts/Header';
import useErrorHandler from '../hooks/useErrorHandler';
import useLoader from '../hooks/useLoader';
import axios from 'axios';
import Loading from '../components/Loading';

const Holidays = () => {
    const [holidays, setHolidays] = useState(null);
    const [holidayDate, setHolidayDate] = useState();
    const handlers = useForm(
        useMemo(
            () => ({
                title: '',
            }),
            []
        ),
        { Input: TextField }
    );
    const [holiday, setHoliday] = useState(null);
    const setValues = handlers.setValues;
    const { start, end, loaderState } = useLoader();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const errorHandler = useErrorHandler();

    console.log(holidayDate);

    const fetchHolidays = useCallback(
        async (search = '') => {
            try {
                const response = await axios.get(
                    `/hr/holidays?searchBy=title&search=${search}&sortBy=order`
                );

                setHolidays(response.data.holidays);
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler]
    );

    const editHoliday = id => {
        setHoliday(id);
        handleOpen();
    };

    const fetchHoliday = useCallback(
        async id => {
            start();
            try {
                const response = await axios.get(`/hr/holidays/${id}`);
                const { title, date } = response.data.holiday;

                const d = date.split('T')[0];
                setValues({ title });
                setHolidayDate(d);
            } catch (e) {
                errorHandler(e);
            } finally {
                end();
            }
        },
        [errorHandler, setValues, start, end]
    );

    useEffect(() => {
        if (holiday) {
            fetchHoliday(holiday);
        }
    }, [holiday, fetchHoliday]);

    useEffect(() => {
        fetchHolidays();
    }, [fetchHolidays]);

    const { showSuccess, showError } = useMessage();

    const submit = res => {
        const { success, message } = res.data;

        if (!success) return showError(message);

        showSuccess('Holiday saved successfully');
        fetchHolidays();
        handleClose();
    };
    return (
        <>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Holidays</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box>
                            <Button
                                onClick={() => {
                                    setHoliday(null);
                                    handleOpen();
                                }}
                                variant='contained'>
                                Add Holiday
                            </Button>
                        </Box>
                        <Box sx={{ ml: 2 }}>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0 }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box my={4}>
                <Grid container spacing={2}>
                    <Grid item lg={8} xs={12}>
                        <Card elevation={0} sx={{ position: 'relative' }}>
                            <CardContent>
                                <Box
                                    sx={{
                                        borderLeftWidth: '5px',
                                        borderLeftColor: 'primary.main',
                                        borderLeftStyle: 'solid',
                                        height: '40px',
                                        width: '5px',
                                        position: 'absolute',

                                        left: 1,
                                    }}></Box>
                                <Typography sx={{ mb: 2 }} variant='h6'>
                                    Holidays Lists
                                </Typography>
                                <Box
                                    sx={{
                                        maxWidth: '300px',
                                        ml: 'auto',
                                        my: 2,
                                    }}>
                                    <Search
                                        placeholder='Search by Holiday'
                                        onChange={e => {
                                            const { value } = e.target;
                                            !(value.trim() === ' ') && fetchHolidays(value);
                                        }}
                                    />
                                </Box>
                                {holidays && (
                                    <HolidayList
                                        fetchHolidays={fetchHolidays}
                                        holidays={holidays}
                                        editHoliday={editHoliday}
                                    />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <Calender />
                    </Grid>
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card
                    sx={{
                        boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
                        borderRadius: '8px',
                        maxWidth: '600px',
                        width: '100%',
                        p: 4,
                        mx: 2,
                        overflowX: 'hidden',
                        maxHeight: '85vh',
                        overflowY: 'auto',
                    }}>
                    <Typography id='modal-modal-title' variant='h5' component='h2'>
                        {holiday ? 'Edit ' : 'Add '} Holiday
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    {loaderState ? (
                        <Loading
                            message='Please wait, while your Holiday are loading...'
                            minHeight='200px'
                        />
                    ) : (
                        <Form
                            handlers={handlers}
                            onSubmit={submit}
                            action={holiday ? `/hr/holidays/${holiday}` : '/hr/holidays'}
                            method={holiday ? 'patch' : 'post'}
                            final={values => ({
                                ...values,
                                date: {
                                    year: parseInt(holidayDate.split('-')[0]),
                                    month: parseInt(holidayDate.split('-')[1]),
                                    day: parseInt(holidayDate.split('-')[2]),
                                },
                            })}
                            onError={console.log}>
                            <Typography id='modal-modal-description' sx={{ my: 2 }}>
                                Select Date
                            </Typography>
                            <TextField
                                size='small'
                                name='date'
                                placeholder='Date'
                                type='date'
                                value={holidayDate}
                                onChange={e => setHolidayDate(e.target.value)}
                                fullWidth
                            />
                            <Typography id='modal-modal-description' sx={{ my: 1 }}>
                                Enter Occasion
                            </Typography>
                            <Input name='title' size='small' placeholder='Occasion' fullWidth />
                            <Box mt={3} textAlign='right'>
                                <Button
                                    onClick={handleClose}
                                    variant='outlined'
                                    sx={{
                                        mx: 2,
                                    }}>
                                    Close
                                </Button>
                                <Submit>
                                    {loader => (
                                        <Button
                                            type='submit'
                                            variant='contained'
                                            disabled={Boolean(loader)}
                                            endIcon={loader}>
                                            Add
                                        </Button>
                                    )}
                                </Submit>
                            </Box>
                        </Form>
                    )}
                </Card>
            </Modal>
        </>
    );
};

export default Holidays;
