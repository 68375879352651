import { Grid, Typography } from '@mui/material';
import React from 'react';

const BankDetails = ({
    pan,
    ifsc,
    bankName,
    branch,
    accountNumber,
    accountHolder,
    city,
    state,
    country,
}) => {
    return (
        <>
            <Grid container rowSpacing={3} display='flex' alignItems='center'>
                <Grid item xs={6}>
                    <Typography variant='body2'>Account Holder</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body1' fontWeight={500}>
                        {' '}
                        {accountHolder}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>Account Number</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body1' fontWeight={500}>
                        {accountNumber}{' '}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>Bank Name</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body1' fontWeight={500}>
                        {bankName}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>Branch Location</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body1' fontWeight={500}>
                        {branch}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>Bank Code (IFSC) </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body1' fontWeight={500}>
                        {ifsc}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>Tax Payer ID (PAN) </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body1' fontWeight={500}>
                        {pan}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>City</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body1' fontWeight={500}>
                        {city}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>State</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body1' fontWeight={500}>
                        {state}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>Country</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body1' fontWeight={500}>
                        {country}{' '}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default BankDetails;
