
import React from 'react';
import {
    Avatar,
    Badge,
    Box,
    Divider,
    IconButton,
    Typography,
    TextField,
} from '@mui/material';
import styled from '@emotion/styled';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import ArchiveIcon from '@mui/icons-material/Archive';
import TrashIcon from '@mui/icons-material/Delete';
import MoreIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


const ChatDetail = () => {
    const getFormattedDateTime = () => {
        const currentDateTime = new Date();
        return `${currentDateTime.toLocaleDateString()} at ${currentDateTime.getHours()}:${currentDateTime.getMinutes()}`;
    };

    return (
        <>
            <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                mb={3}
                px={2} 
            >
                <Box display='flex' alignItems='center' sx={{ '@media (max-Width: 480px)': { display: 'none' } }}>
                   
                    <Box mr={2} >
                        <StyledBadge
                            overlap='circular'
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            variant='dot'
                        >
                            <Avatar
                                alt='Remy Sharp'
                                src='https://shorturl.at/fjqz9'
                                sx={{ width: 48, height: 48 }} // Adjust avatar size
                            />
                        </StyledBadge>
                    </Box>
                    <Box>
                        <Typography variant='body1'>Gaurav Gupta</Typography>
                        <Typography
                            color='text.secondary'
                            variant='caption'
                            sx={{ fontSize: '0.8rem' }} // Adjust font size
                        >
                            Active
                        </Typography>
                    </Box>
                </Box>

                <div
     style={{
        display: 'flex',
        height:'32px',
        justifyContent: 'flex-end',
      }}

    >
      
      <IconButton
        sx={{ border: '1px solid lightblue', borderRadius: '4px', marginRight: '7px', p: 1 }}
        size='small'
      >
        <PhoneIcon />
      </IconButton>
      <IconButton
        sx={{ border: '1px solid lightblue', borderRadius: '4px', marginRight: '7px', p: 1 }}
        size='small'
      >
        <ArchiveIcon />
      </IconButton>
      <IconButton
        sx={{ border: '1px solid lightblue', borderRadius: '4px', marginRight: '7px', p: 1 }}
        size='small'
      >
        <TrashIcon />
      </IconButton>
      <IconButton
        sx={{ border: '1px solid lightblue', borderRadius: '4px', marginRight: '7px', p: 1 }}
        size='small'
      >
        <InfoOutlinedIcon />
      </IconButton>
      <IconButton
        sx={{ border: '1px solid lightblue', borderRadius: '4px', marginRight: '7px', p: 1 }}
        size='small'
      >
        <MoreIcon />
      </IconButton>
    </div>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Box
                sx={{
                    overflowY: 'auto',
                    maxHeight: '70vh',
                    '::-webkit-scrollbar': {
                        width: '3px',
                        backgroundColor: 'transparent',
                    },
                    '::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                    },
                    '::-webkit-scrollbar-thumb': {
                        backgroundColor: 'transparent',
                    },
                    px: 2, 
                }}
            >
                <Box display='flex' alignItems='center'  >
                    <Avatar alt='Remy Sharp' src='https://shorturl.at/fjqz9' />
                    <Box
                        sx={{
                            borderRadius: '20px',
                           
                            px: '10px',
                            py: '5px',
                            mt:'10px',
                           
                           
                            borderTopLeftRadius: 0,
                        }}
                    >
                        <Typography fontSize={15}sx={{
                            borderRadius: '10px',
                            background: 'grey',
                            ml:'7px',
                            px: '10px',
                            py: '5px',
                             
                            borderTopLeftRadius: 0,    
        
                        }}  variant='caption'>
                            Hi I am Gaurav
                        </Typography>
                      
                        <Box display="flex" alignItems="center" mt={1} >
  <AccessTimeIcon style={{ height: '15px' }} />
  <Typography fontSize={10} variant='caption' style={{ margin: 0 }}>
    {getFormattedDateTime()}
  </Typography>
</Box> </Box>
                   
                </Box>
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='end'
                    my={2}
                    mb={8}
                >
                    <Box
                        sx={{
                            borderRadius: '20px',
                          
                            px: '10px',
                            py: '5px',
                            mt:'10px',
                          
                           
                            borderTopRightRadius: 0,
                        }}
                    >
                        
                     

                        <Typography fontSize={15}
                        
                        sx={{
                            borderRadius: '10px',
                            background: 'grey',
                            px: '10px',
                            py: '5px',
                            mr:'10px',
                           
                            borderTopRightRadius: 0,
                        }}
                        variant='caption'>Hi I am Areeb</Typography>

          
    <Box display="flex" alignItems="center" mt={1}>
  <AccessTimeIcon style={{ height: '15px' }} />
  <Typography fontSize={10} variant='caption' style={{ margin: 0 }}>
    {getFormattedDateTime()}
  </Typography>
</Box>
                        
                    </Box>
                    <Avatar alt='Remy Sharp' src='https://shorturl.at/fjqz9' />
                </Box>
                <Box
      sx={{
        
        display: 'flex',
        alignItems: 'center',
        position:'sticky',
        mb: 1,
        marginTop: '280px',
      }}
    >
        
      <TextField
        placeholder="What's on your mind?"
        fullWidth

        variant="outlined"
        InputProps={{
          endAdornment: (
            <IconButton color="primary" size="small">
              <SendIcon />
            </IconButton>
          ),
        }}
      />
    </Box>
            </Box>
           
        </>
    );
};

export default ChatDetail;
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#0dcd94',
        color: '#0dcd94',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));
