import React from 'react';
import GroupIcon from '@mui/icons-material/Group';

import {
    Box,
    Card,
    CardContent,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';

const UserCard = () => {
    return (
        <>
            <Grid item lg={4} xs={12}>
                <Card elevation={0}>
                    <CardContent>
                        <Typography mb={2}>Total Tickets</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant='body'>1254</Typography>

                            <IconButton
                                sx={{
                                    background: '#0DCD94',
                                    borderRadius: '8px',
                                    '&:hover': {
                                        background: '#0DCD94',
                                    },
                                }}
                            >
                                <GroupIcon />
                            </IconButton>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Card elevation={0}>
                    <CardContent>
                        <Typography mb={2}>Active Tickets</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant='body'>14</Typography>

                            <IconButton
                                sx={{
                                    background: '#FE6339',
                                    borderRadius: '8px',
                                    '&:hover': {
                                        background: '#FE6339',
                                    },
                                }}
                            >
                                <GroupIcon />
                            </IconButton>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Card elevation={0}>
                    <CardContent>
                        <Typography mb={2}>Closed Tickets</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant='body'>10</Typography>

                            <IconButton
                                sx={{
                                    background: '#295C97',
                                    borderRadius: '8px',
                                    '&:hover': {
                                        background: '#295C97',
                                    },
                                }}
                            >
                                <GroupIcon />
                            </IconButton>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
};

export default UserCard;
