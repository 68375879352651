import React from 'react';
import {
    Typography,
    Grid,
    TextField,
    Select,
    MenuItem,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Card,
    CardContent,Pagination
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from 'react';


const RoleAccess = () => {

   // const [entriesPerPage, setEntriesPerPage] = useState('10');
    const [entriesOption, setEntriesOption] = useState('1');

  //const handleChange = (event) => {
    //setEntriesPerPage(event.target.value);
  //};
  const handleChangeOption = (event) => {
    setEntriesOption(event.target.value);
  };

  const [entries1, setEntries1] = useState(10); // Initial value for the dropdown

  const handleChange1 = (event) => {
    setEntries1(event.target.value);
  };
    return (
       
        <div >
            <div >
        <Grid container spacing={2} marginTop={1} alignItems='center' paddingLeft={2}>
            <Grid item xs>
                <Typography variant='h5'>Role Access</Typography>
            </Grid>
            <Grid item sx={{ display: { xs: 'flex' }, mx: 'auto' }} alignItems='center'>
                
                    <div>
                        <IconButton variant='navIcon' disableRipple style={{ marginRight: 0,paddingRight:2 }}>
                            <InfoOutlinedIcon fontSize='small' />
                        </IconButton>
                    </div>
               
            </Grid>
        </Grid>
        </div>
           
        <Card style={{ margin: '20px', marginTop:'15px'}}>
            <CardContent>
                  
        <Grid container spacing={2}>
            <Grid item xs={10} sm={3}>
                <Typography variant="subtitle1">Select Property:</Typography>
                <TextField size='small' variant="outlined" style={{ width: '100%' }} />
            </Grid>

            {/* Your second section */}
            <Grid item xs={10} sm={3}>
                <Typography variant="subtitle1">Select Value:</Typography>
                <Select
        variant="outlined"
        size="small"
        fullWidth
        value={entriesOption}
        onChange={handleChangeOption}
      >
        <MenuItem value="1">Option 1</MenuItem>
        <MenuItem value="2">Option 2</MenuItem>
        <MenuItem value="3">Option 3</MenuItem>
        {/* Add more options */}
      </Select>
       
        </Grid>

        <Grid container spacing={1} marginTop={2} marginbottom={2} item xs={10} sm={4}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                variant='contained'
                                fullWidth
                                style={{ marginRight: '10px' }}
                                
                            >
                                Search
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button variant='contained' fullWidth color='error'>
                               Reset
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

            <Card style={{ margin: '20px' }}>
                <CardContent>
                <Grid container spacing={2}>
    
      <Grid item xs={7} style={{ display: 'flex', justifyContent: 'flex-start',marginTop:10  }}>
        <span>Show </span>
        <Select
          label="Entries"
          value={entries1}
          
          onChange={handleChange1}
          style={{ marginLeft: 8, marginRight: 8, height: 30}}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          {/* Add more options as needed */}
        </Select>
        <span> entries</span>
      </Grid>
   


                        {/* Your fifth section */}
                        <Grid item xs={5} justifyContent= 'flex-end'>
                            <TextField
                                variant="outlined"
                                size='small'
                                label="Search"
                                style={{ width: '100%' }}
                            />
                        </Grid>

                        {/* Your sixth section */}
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>No.</TableCell>
                                            <TableCell>Role Name</TableCell>
                                            <TableCell>Super admin</TableCell>
                                            <TableCell>Admin</TableCell>
                                            <TableCell>HR</TableCell>
                                            <TableCell>Client</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {[...Array(11)].map((_, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>Role {index + 1}</TableCell>
                                                <TableCell>-</TableCell>
                                                <TableCell>-</TableCell>
                                                <TableCell>-</TableCell>
                                                <TableCell>-</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            </Grid>
                            <Grid container justify="space-between" alignItems="center" marginTop={2}>
            {/* Label on the right side */}
            <Typography variant="subtitle2" style={{ marginRight: 'auto', marginLeft:'15px' }}>1 to 10 of 24 entries</Typography>

            {/* Buttons on the left side */}
            <div style={{ display: 'flex', alignitems: 'center', justifyContent: 'flex-start' }}>
      <Pagination count={3} color="primary" size="small" />
      
    </div>
        </Grid>
                      
                    </Grid>
                </CardContent>
            </Card>
       
        </div>
    );
};

export default RoleAccess;

