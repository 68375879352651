import { Box, Button, Grid, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useCallback, useState } from 'react';
import { handleAxiosError } from '../../utils/function';
import { useMessage } from '../../layouts/Header';
import axios from 'axios';
import { useEffect } from 'react';
import NoticeCard from './NoticeCard';
import CreateNotice from './CreateNotice';
import useModal from '../../hooks/useModal';

const Notice = () => {
    const [notices, setNotices] = useState(null);
    const { modalState: noticeState, openModal: openNotice, closeModal: closeNotice } = useModal();
    const { showError } = useMessage();
    const [notice, setNotice] = useState(null);

    const fetchNotice = useCallback(async () => {
        try {
            const response = await axios.get('/hr/notice');

            setNotices(response.data.notices);
        } catch (e) {
            handleAxiosError(e, showError);
        }
    }, [showError]);

    const editNotice = id => {
        setNotice(id);
        openNotice();
    };

    useEffect(() => {
        fetchNotice();
    }, [fetchNotice]);

    return (
        <Box>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Notice Board</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    setNotice(null);
                                    openNotice();
                                }}>
                                New notice
                            </Button>
                        </Box>

                        <Box>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0, ml: 2 }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {notices?.map(notice => (
                <NoticeCard notice={notice} editNotice={editNotice} refresh={fetchNotice} />
            ))}
            <Modal
                sx={{
                    overflowY: 'scroll',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                open={noticeState}
                onClose={closeNotice}>
                <>
                    <CreateNotice closeModal={closeNotice} refresh={fetchNotice} notice={notice} />
                </>
            </Modal>
        </Box>
    );
};

export default Notice;
