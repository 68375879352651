import React, { useCallback, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Avatar, Box, Card, Stack, IconButton, Popover, Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelIcon from '@mui/icons-material/Cancel';
import StarIcon from '@mui/icons-material/Star';
import TonalityIcon from '@mui/icons-material/Tonality';
import LogoutIcon from '@mui/icons-material/Logout';
import SnoozeIcon from '@mui/icons-material/Snooze';
import axios from 'axios';
import { useMenu } from './../hooks/useMenu';

const AttendanceTable = ({ month, year }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [attendance, setAttendance] = useState(null);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [details, setDetails] = useState('');

    const { anchorEl: detailAnchorEl, openMenu: openDetail, closeMenu: closeDetail } = useMenu();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const fetchAttendance = useCallback(
        async function () {
            try {
                if (year && month) {
                    const response = await axios.get(
                        '/hr/attendance?year=' + year + '&month=' + month
                    );
                    setAttendance(response.data.attendance);
                }
            } catch (e) {
                console.log(e);
            }
        },
        [year, month]
    );

    useEffect(() => {
        fetchAttendance();
    }, [fetchAttendance]);

    useEffect(() => {
        if (attendance) {
            // Transform the API data into rows
            const uniqueDays = [...new Set(attendance.map(dayData => dayData.day))];
            uniqueDays.sort((a, b) => a - b);
            const dayColumns = uniqueDays.map(day => ({
                id: `day_${day}`,
                label: `${day}`,
                minWidth: 15,
                align: 'center',
            }));

            // Create rows for each employee
            const newRows = attendance.reduce((acc, dayData) => {
                dayData.attendance.forEach(attendance => {
                    const employeeRow = acc.find(row => row.employee.id === attendance.employee.id);

                    if (employeeRow) {
                        // Employee row already exists, add the attendance icon to the appropriate day column
                        employeeRow[`day_${dayData.day}`] = (
                            <IconButton
                                onClick={e => {
                                    setDetails(attendance);
                                    openDetail(e);
                                }}
                                key={attendance.employee.id}
                                sx={{
                                    color: getColorForStatus(attendance.status),
                                }}
                                style={{ 'margin-top': '10px' }}>
                                {attendance.status === 'Present' && (
                                    <VerifiedIcon fontSize='small' />
                                )}
                                {attendance.status === 'Late' && <SnoozeIcon fontSize='small' />}
                                {attendance.status === 'Half-Day' && (
                                    <TonalityIcon fontSize='small' />
                                )}
                                {attendance.status === 'Leave' && <LogoutIcon fontSize='small' />}
                                {attendance.status === 'Absent' && <CancelIcon fontSize='small' />}
                                {attendance.status === 'Holiday' && <StarIcon fontSize='small' />}
                            </IconButton>
                        );
                    } else {
                        // Create a new employee row with attendance icons for each day column
                        const newRow = {
                            employee: attendance.employee,
                            Employee_Name: (
                                <Box
                                    key={attendance.employee.id}
                                    display='flex'
                                    alignItems='center'
                                    style={{ 'margin-top': '10px' }}>
                                    <Box>
                                        <Avatar
                                            alt={attendance.employee.name}
                                            // src={`https://api.files.catch.com/open/file/preview/${
                                            //     employees[attendance.employee.id]?.photo
                                            // }`}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography sx={{ mx: 1 }}>
                                            {attendance.employee.name}
                                        </Typography>
                                    </Box>
                                </Box>
                            ),
                            ...Object.fromEntries(
                                dayColumns.map(dayColumn => [
                                    dayColumn.id,
                                    dayColumn.id === `day_${dayData.day}` ? (
                                        <IconButton
                                            key={attendance.employee.id}
                                            onClick={e => {
                                                setDetails(attendance);
                                                openDetail(e);
                                            }}
                                            sx={{
                                                color: getColorForStatus(attendance.status),
                                            }}
                                            style={{ 'margin-top': '10px' }}>
                                            {attendance.status === 'Present' && (
                                                <VerifiedIcon fontSize='small' />
                                            )}
                                            {attendance.status === 'Late' && (
                                                <SnoozeIcon fontSize='small' />
                                            )}
                                            {attendance.status === 'Half-Day' && (
                                                <TonalityIcon fontSize='small' />
                                            )}
                                            {attendance.status === 'Leave' && (
                                                <LogoutIcon fontSize='small' />
                                            )}
                                            {attendance.status === 'Absent' && (
                                                <CancelIcon fontSize='small' />
                                            )}
                                            {attendance.status === 'Holiday' && (
                                                <StarIcon fontSize='small' />
                                            )}
                                        </IconButton>
                                    ) : null,
                                ])
                            ),
                        };

                        acc.push(newRow);
                    }
                });

                return acc;
            }, []);

            const columns = [
                { id: 'Employee_Name', label: 'Employee Name', minWidth: 170 },
                ...dayColumns,
            ];

            setColumns(columns);
            setRows(newRows);
        }
    }, [attendance, closeDetail, detailAnchorEl, openDetail]);

    const getColorForStatus = status => {
        if (status === 'Present') {
            return '#0DCD9E';
        } else if (status === 'Absent') {
            return '#0DCD9E';
        } else if (status === 'Half-Day') {
            return '#F34932';
        } else if (status === 'Holiday') {
            return '#FFAD00';
        } else if (status === 'Leave') {
            return '#ffe8af';
        } else if (status === 'Late') {
            return '#32daf3';
        }
        return '';
    };

    return (
        <>
            <TableContainer sx={{ bgcolor: 'background.paper' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                    }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, i) => {
                                return (
                                    <TableRow role='checkbox' tabIndex={-1} key={i}>
                                        {columns.map(column => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}

                        {rows?.length === 0 && (
                            <>
                                <TableRow role='checkbox' tabIndex={-1}>
                                    <TableCell>
                                        <p style={{ alignItems: 'center' }}>
                                            No records were found
                                        </p>
                                    </TableCell>
                                </TableRow>
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <DetailPanel
                detailAnchorEl={detailAnchorEl}
                closeDetail={closeDetail}
                details={details}
            />
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component='div'
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default AttendanceTable;

const DetailPanel = ({ details, closeDetail, detailAnchorEl }) => {
    const { clockInTime, clockOutTime, note } = details;

    return (
        <Popover
            open={Boolean(detailAnchorEl)}
            anchorEl={detailAnchorEl}
            onClose={closeDetail}
            sx={{
                '.MuiPaper-root.MuiPopover-paper': {
                    boxShadow: 'none',
                },
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}>
            <Card
                sx={{
                    p: 1.5,
                    borderStyle: 'solid',
                    borderColor: 'divider',
                    borderWidth: '1px',
                    width: '300px',
                    bgcolor: 'background.default',
                }}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' mb={1}>
                    <Typography variant='caption' fontWeight={600}>
                        Clock In
                    </Typography>

                    <Typography variant='caption'>
                        {clockInTime ? new Date(clockInTime).toLocaleString() : 'N/A'}
                    </Typography>
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center' mb={1}>
                    <Typography variant='caption' fontWeight={600}>
                        Clock Out
                    </Typography>

                    <Typography variant='caption'>
                        {clockOutTime ? new Date(clockOutTime).toLocaleString() : 'N/A'}
                    </Typography>
                </Stack>
                <Typography variant='caption' fontWeight={600}>
                    Note:{' '}
                </Typography>
                <Typography variant='caption' textOverflow='clip'>
                    {note}
                </Typography>
            </Card>
        </Popover>
    );
};
