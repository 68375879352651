import React, { createContext, useCallback, useContext } from 'react';
import Navbar from './Navbar';
import useSnack from '../hooks/useSnack';
import ThemeContextProvider from '../style/theme';
import AuthenticationProvider from '../hooks/Authenticate';
import AuthorizationProvider from '../hooks/Authorize';

const SnackContext = createContext();

export default function Header({ children }) {
    const { SnackBar, showMessage } = useSnack();

    return (
        <ThemeContextProvider>
            <SnackContext.Provider value={showMessage}>
                <AuthenticationProvider>
                    <Navbar>
                        <AuthorizationProvider>{children}</AuthorizationProvider>
                    </Navbar>
                </AuthenticationProvider>
                {SnackBar}
            </SnackContext.Provider>
        </ThemeContextProvider>
    );
}

const useMessage = () => {
    const showMessage = useContext(SnackContext);

    const showSuccess = useCallback(
        function (msg) {
            showMessage({ success: msg });
        },
        [showMessage]
    );

    const showError = useCallback(
        function (msg) {
            showMessage({ error: msg });
        },
        [showMessage]
    );

    return { showError, showSuccess };
};

export { useMessage };
