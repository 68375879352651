import React, { useCallback, useState } from 'react';
import {
    Box,
    Button,
    Card,
    Avatar,
    Typography,
    CircularProgress,
    Stack,
    Divider,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import axios from 'axios';
import { useMessage } from '../layouts/Header';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const LeaveApplicationCard = ({
    name,
    department,
    Applied,
    from,
    to,
    reason,
    daysRemaining,
    id,
    fetchLeaveApplication,
    date,
}) => {
    const { showError, showSuccess } = useMessage();
    const [acceptLoading, setAcceptLoading] = useState(false);
    const acceptLeave = useCallback(
        async function (id) {
            setAcceptLoading(true);
            try {
                const res = await axios.post(`/hr/attendance/leaves/approve/${id}`);
                fetchLeaveApplication();
                const { success, message } = res.data;
                if (success) return showSuccess(message);
                showError(message);
            } catch (e) {
                console.log(e);
            } finally {
                setAcceptLoading(false);
            }
        },
        [fetchLeaveApplication, showSuccess, showError]
    );

    const rejectLeave = useCallback(
        async function (id) {
            try {
                const res = await axios.post(`/hr/attendance/leaves/deny/${id}`);
                fetchLeaveApplication();
                const { success, message } = res.data;
                if (success) return showSuccess(message);
                showError(message);
            } catch (e) {
                showError(e);
            }
        },
        [fetchLeaveApplication, showSuccess, showError]
    );

    return (
        <>
            <Card
                sx={{
                    backgroundColor: 'custom.paper',
                    height: '100%',
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}>
                <Box>
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={{
                            borderLeftWidth: '5px',
                            borderLeftColor: 'primary.main',
                            borderLeftStyle: 'solid',
                        }}
                        px={2}
                        py={1.2}>
                        <Box display='flex' alignItems='center'>
                            <Avatar
                                alt='Remy Sharp'
                                src='https://shorturl.at/fjqz9'
                                sx={{ width: 45, height: 45 }}
                            />
                            <Box>
                                <Typography
                                    variant='body2'
                                    lineHeight={0.7}
                                    pt={1}
                                    sx={{
                                        mx: 1.5,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}>
                                    {name}
                                </Typography>
                                <Typography
                                    sx={{ mx: 1.5, color: 'text.secondary', fontStyle: 'italic' }}
                                    variant='caption'
                                    fontSize={12}
                                    lineHeight={0.5}>
                                    {department}
                                </Typography>
                            </Box>
                        </Box>
                        <Box textAlign='right'>
                            <Typography
                                variant='caption'
                                sx={{ display: 'block', lineHeight: 1, fontSize: 12 }}
                                color='text.secondary'
                                fontWeight={500}>
                                {date.getDate()} {months[date.getMonth()]}{' '}
                                {date.getFullYear().toString().slice(2)}
                            </Typography>
                            <Typography sx={{ color: '#FFAD51', fontSize: 11 }} variant='caption'>
                                {daysRemaining} days left
                            </Typography>
                        </Box>
                    </Stack>
                    <Divider variant='fullWidth' />
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        p={1}
                        py={3.5}
                        mx={2.2}>
                        <Box textAlign='center'>
                            <Typography variant='subtitle1' color='text.secondary'>
                                {months[from.getMonth()]}
                            </Typography>

                            <Typography variant='h4' fontWeight='Bold'>
                                {from.getDate()}
                            </Typography>

                            <Typography variant='subtitle1' color='text.secondary'>
                                {days[from.getDay()]}
                            </Typography>
                        </Box>
                        <Stack direction='row' justifyContent='space-around' alignItems='center'>
                            <HorizontalRuleIcon sx={{ fontSize: '30px', color: 'primary.main' }} />
                            <HorizontalRuleIcon sx={{ fontSize: '30px', color: 'primary.main' }} />
                            <HorizontalRuleIcon sx={{ fontSize: '30px', color: 'primary.main' }} />
                            <ArrowForwardIcon sx={{ fontSize: '30px', color: 'primary.main' }} />
                        </Stack>
                        <Box textAlign='center'>
                            <Typography variant='subtitle1' color='text.secondary'>
                                {months[to.getMonth()]}
                            </Typography>

                            <Typography variant='h4' fontWeight='Bold'>
                                {to.getDate()}
                            </Typography>

                            <Typography variant='subtitle1' color='text.secondary'>
                                {days[to.getDay()]}
                            </Typography>
                        </Box>
                    </Stack>
                </Box>

                <Box
                    py={2}
                    px={2.2}
                    sx={{
                        backgroundColor: 'custom.selectedHover',
                        color: 'text.secondary',
                    }}>
                    <Typography variant='body2' mb={2} mx={1.5} fontSize={13}>
                        {reason}
                    </Typography>
                    <Box display='flex'>
                        <Button
                            onClick={() => rejectLeave(id)}
                            variant='outlined'
                            fullWidth
                            color='primary'
                            sx={{
                                mr: 1,
                            }}>
                            Reject
                        </Button>
                        <Button
                            onClick={() => acceptLeave(id)}
                            variant='contained'
                            size='small'
                            fullWidth
                            color='primary'
                            sx={{ ml: 1 }}
                            disabled={acceptLoading}
                            endIcon={
                                acceptLoading && (
                                    <CircularProgress size='20px' sx={{ color: 'inherit' }} />
                                )
                            }>
                            Accept
                        </Button>{' '}
                    </Box>
                </Box>
            </Card>
        </>
    );
};

export default LeaveApplicationCard;
