import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import EmploySalaryTable from './EmploySalaryTable';

const EmployeeSalary = () => {
    return (
        <>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Employee Salary</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box>
                            <Link to='/add-payroll'>
                                <Button variant='contained'>
                                    Add New Payroll
                                </Button>
                            </Link>
                        </Box>
                        <Box sx={{ ml: 2 }}>
                            <Tooltip title='info' placement='top'>
                                <IconButton
                                    disableRipple
                                    variant='navIcon'
                                    sx={{ mr: 0 }}
                                >
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box my={5}>
                <Card elevation={0} sx={{ position: 'relative' }}>
                    <CardContent>
                        <Box
                            sx={{
                                borderLeftWidth: '5px',
                                borderLeftColor: 'primary.main',
                                borderLeftStyle: 'solid',
                                height: '40px',
                                width: '5px',
                                position: 'absolute',

                                left: 1,
                            }}
                        ></Box>
                        <EmploySalaryTable />
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default EmployeeSalary;
