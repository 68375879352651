import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Modal,
    Tooltip,
    Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useCallback } from 'react';
import LeaveTable from '../../components/LeaveTable';
import axios from 'axios';
import LeaveModal from '../../components/LeaveModal';

const LeaveSetting = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [leavesTypes, setLeaveType] = useState(null);

    const fetchLeaveType = useCallback(
        async function () {
            try {
                const response = await axios.get('/hr/attendance/leaves-types');
                setLeaveType(response.data.leaveTypes);
            } catch (e) {
                console.log(e);
            }
        },
        [setLeaveType]
    );

    useEffect(() => {
        fetchLeaveType();
    }, [fetchLeaveType]);

    return (
        <>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Leave Settings</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box>
                            <Button onClick={handleOpen} variant='contained'>
                                Add Leave Type
                            </Button>
                        </Box>
                        <Box sx={{ ml: 2 }}>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0 }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {/*table  */}
            <Card sx={{ my: 5, position: 'relative' }} elevation={0}>
                <CardContent>
                    <Box
                        sx={{
                            borderLeftWidth: '5px',
                            borderLeftColor: 'primary.main',
                            borderLeftStyle: 'solid',
                            height: '40px',
                            width: '5px',
                            position: 'absolute',

                            left: 1,
                        }}></Box>
                    <Typography variant='h6' sx={{ mb: 4 }}>
                        Leaves Types
                    </Typography>
                    <LeaveTable leavesTypes={leavesTypes} fetchLeaveType={fetchLeaveType} />
                </CardContent>
            </Card>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'>
                <LeaveModal
                    handleClose={handleClose}
                    leavesTypes={leavesTypes}
                    fetchLeaveType={fetchLeaveType}
                />
            </Modal>
        </>
    );
};

export default LeaveSetting;
