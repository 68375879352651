import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import EditIcon from '@mui/icons-material/Edit';
import Loading from './Loading';
import axios from 'axios';
import { useMessage } from '../layouts/Header';
import getDate from '../hooks/function';
const columns = [
    { id: 'No', label: 'No', minWidth: 50 },
    {
        id: 'Day',
        label: 'Day',
        minWidth: 70,
        align: 'center',
    },

    {
        id: 'Date',
        label: 'Date',
        minWidth: 90,
        align: 'center',
    },
    {
        id: 'Holidays',
        label: 'Holidays',
        minWidth: 150,
        align: 'center',
    },
    {
        id: 'Actions',
        label: 'Actions',
        minWidth: 100,
        align: 'center',
    },
];

const HolidayList = ({ holidays, fetchHolidays, editHoliday }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const { showSuccess, showError } = useMessage();
    const deleteHoliday = React.useCallback(
        async function (id) {
            try {
                const res = await axios.delete(`/hr/holidays/${id}`);

                const { success, message } = res.data;

                if (!success) return showError(message);

                showSuccess('Holiday Delete successfully');
                fetchHolidays();
            } catch (e) {
                console.log(e);
            }
        },
        [showSuccess, showError, fetchHolidays]
    );
    return (
        <>
            <TableContainer>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                    }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {holidays ? (
                            holidays
                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((holiday, id) => {
                                    // const date = holiday.date.split('T')[0];
                                    const weekday = [
                                        'Sunday',
                                        'Monday',
                                        'Tuesday',
                                        'Wednesday',
                                        'Thursday',
                                        'Friday',
                                        'Saturday',
                                    ];

                                    const d = new Date(holiday.date);
                                    let day = weekday[d.getDay()];
                                    return (
                                        <TableRow
                                            key={id}
                                            sx={{
                                                '&:last-child td, &:last-child th': {
                                                    border: 0,
                                                },
                                            }}>
                                            <TableCell component='th' scope='row' key={id}>
                                                {id + 1}
                                            </TableCell>
                                            <TableCell component='th' scope='row' align='center'>
                                                {day}
                                            </TableCell>
                                            <TableCell component='th' scope='row' align='center'>
                                                {/* {date.split('-')[2]}-
                                                {date.split('-')[1]}-
                                                {date.split('-')[0]} */}
                                                {getDate(holiday.date)}
                                            </TableCell>
                                            <TableCell component='th' scope='row' align='center'>
                                                {holiday.title}
                                            </TableCell>
                                            <TableCell component='th' scope='row' align='center'>
                                                <>
                                                    <IconButton
                                                        onClick={() => editHoliday(holiday._id)}
                                                        sx={{
                                                            borderRadius: '12px',
                                                            backgroundColor:
                                                                'custom.buttonColor.light.blue',
                                                            color: 'custom.buttonColor.dark.blue',
                                                            '&:hover': {
                                                                backgroundColor:
                                                                    'custom.buttonColor.light.blue',
                                                            },
                                                        }}>
                                                        <EditIcon fontSize='small' />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => deleteHoliday(holiday._id)}
                                                        sx={{
                                                            my: {
                                                                xl: 0,
                                                                xs: 1,
                                                            },
                                                            mx: 1,
                                                            borderRadius: '12px',
                                                            backgroundColor:
                                                                'custom.buttonColor.light.red',
                                                            color: 'custom.buttonColor.dark.red',
                                                            '&:hover': {
                                                                backgroundColor:
                                                                    'custom.buttonColor.light.red',
                                                            },
                                                        }}>
                                                        <DeleteOutlineOutlinedIcon fontSize='small' />
                                                    </IconButton>
                                                </>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                        ) : (
                            <>
                                <TableCell component='th' scope='row' align='center'></TableCell>
                                <TableCell component='th' scope='row' align='center'>
                                    <Loading />
                                </TableCell>
                                <TableCell component='th' scope='row' align='center'></TableCell>
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component='div'
                count={holidays ? holidays?.length : '0'}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default HolidayList;
