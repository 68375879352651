import React, { useCallback, useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Divider,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Tooltip,
    Typography,
    ListItemAvatar,
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import axios from 'axios';
import Loading from '../../components/Loading';
import moment from 'moment';
import PersonalDetails from '../ViewEmployees/PersonalDetails';
import CompanyDetails from '../ViewEmployees/CompanyDetails';
import BankDetails from '../ViewEmployees/BankDetails';
import UploadDocument from '../ViewEmployees/UploadDocument';
import { Images } from '../../components/Image';
import { SelectWithSearch } from '../../components/Select';

const Index = () => {
    const [value, setValue] = React.useState(0);
    const [employeeDetail, setEmployeeDetail] = useState();
    const [employees, setEmployees] = useState({});
    const [query, setQuery] = useState({
        employeeId: '',
    });

    const handleChangeQuery = e => {
        const name = e.target.name;
        const value = e.target.value;
        setQuery({ ...query, [name]: value });
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchEmployees = useCallback(
        async (employeeSearch = '') => {
            try {
                const response = await axios.get(
                    `/hr/employee?pageSize=10${
                        employeeSearch ? `&searchBy=firstName&search=${employeeSearch}` : ''
                    }`
                );

                setEmployees([]);
                const employees = response.data.employees;

                const formattedEmployees = {};

                employees.forEach(
                    employee =>
                        (formattedEmployees[
                            employee._id
                        ] = `${employee.firstName} ${employee.lastName}`)
                );

                setEmployees(formattedEmployees);
            } catch (e) {
                console.log(e);
            }
        },
        [setEmployees]
    );

    const fetchEmployeeDetails = useCallback(
        async function () {
            try {
                const response = await axios.get(`/hr/employee/${query.employeeId}`);
                setEmployeeDetail(response.data.employee);
            } catch (e) {
                console.log(e);
            }
        },
        [setEmployeeDetail, query.employeeId]
    );

    useEffect(() => {
        fetchEmployees();
    }, [fetchEmployees]);

    useEffect(() => {
        if (query.employeeId) {
            fetchEmployeeDetails();
        }
    }, [fetchEmployeeDetails, query.employeeId]);

    return (
        <>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Profile</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box sx={{ ml: 2 }}>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0 }}>
                                    <InfoOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box my={2} width='300px' sx={{ float: 'right' }}>
                <Typography sx={{ mb: 1.5 }}>Employee Name:</Typography>
                <FormControl fullWidth size='small'>
                    <SelectWithSearch
                        name='employeeId'
                        fullWidth
                        displayEmpty
                        value={query.employeeId}
                        filter={query.employeeId && employees[query.employeeId]}
                        onChange={handleChangeQuery}
                        clear={() => setQuery({ ...query, employeeId: '' })}
                        renderValue={v => {
                            if (!query.employeeId) return 'Employee';
                            return employees[v];
                        }}
                        SearchProps={{
                            onChange: e => fetchEmployees(e.target.value),
                        }}>
                        {Object.keys(employees).map((employee, i) => (
                            <MenuItem value={employee} key={i} selected={false} sx={{ px: 1.2 }}>
                                <ListItemAvatar sx={{ minWidth: '45px' }}>
                                    <Avatar
                                        // src={`https://api.files.catch.com/open/file/preview/${employeesInfo[employee]?.photo}`}
                                        sx={{ width: 30, height: 30 }}
                                    />
                                </ListItemAvatar>{' '}
                                {employees[employee]}
                            </MenuItem>
                        ))}
                    </SelectWithSearch>
                </FormControl>
            </Box>
            <Box my={5}>
                {employeeDetail ? (
                    <Grid container spacing={2}>
                        <Grid item lg={4} xs={12}>
                            <Card elevation={0}>
                                {employeeDetail ? (
                                    <CardContent
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}>
                                        <Box>
                                            <Avatar
                                                // src={`https://api.files.catch.com/open/file/preview/${
                                                //   employeesInfo[employeeDetail._id]?.photo
                                                // }`}
                                                sx={{
                                                    width: 90,
                                                    height: 90,

                                                    my: 2,
                                                }}
                                            />
                                        </Box>

                                        <Typography variant='h6'>
                                            {employeeDetail?.firstName} {employeeDetail?.lastName}
                                        </Typography>
                                        <Typography
                                            sx={{ my: 0.5 }}
                                            color='text.secondary'
                                            variant='body2'>
                                            {employeeDetail?.designation}
                                        </Typography>
                                        <Typography color='text.secondary' variant='caption'>
                                            D.O.B :{' '}
                                            {moment(employeeDetail?.dob).utc().format('DD-MM-YYYY')}
                                        </Typography>

                                        <Divider
                                            sx={{
                                                my: 1,
                                            }}
                                        />
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{ textAlign: 'center' }}
                                            display='flex'
                                            alignItems='center'>
                                            <Grid item xs={4}>
                                                <Typography variant='caption'>January</Typography>
                                                <Typography>22 / 31</Typography>
                                                <Typography variant='caption'>
                                                    Attendance
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant='caption'>Year-2021</Typography>
                                                <Typography>22 / 31</Typography>
                                                <Typography variant='caption'>Leaves</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant='caption'>Year-2021</Typography>
                                                <Typography>0</Typography>
                                                <Typography variant='caption'>Awards</Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                ) : (
                                    <Loading />
                                )}
                            </Card>
                        </Grid>
                        <Grid item lg={8}>
                            <Box sx={{ width: '100%' }}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                    }}>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label='basic tabs example'>
                                        <Tab
                                            label='Personal Details'
                                            sx={{ textTransform: 'capitalize' }}
                                            {...a11yProps(0)}
                                        />
                                        <Tab
                                            label='Company Details'
                                            sx={{ textTransform: 'capitalize' }}
                                            {...a11yProps(1)}
                                        />
                                        <Tab
                                            label='Bank Details'
                                            sx={{ textTransform: 'capitalize' }}
                                            {...a11yProps(2)}
                                        />
                                        <Tab
                                            label='Upload Documents'
                                            sx={{ textTransform: 'capitalize' }}
                                            {...a11yProps(3)}
                                        />
                                    </Tabs>
                                </Box>
                                <Card elevation={0}>
                                    {employeeDetail ? (
                                        <>
                                            <CustomTabPanel value={value} index={0}>
                                                <PersonalDetails
                                                    firstName={employeeDetail.firstName}
                                                    lastName={employeeDetail.lastName}
                                                    dob={employeeDetail.dob}
                                                    countryCode={employeeDetail.phone.countryCode}
                                                    phone={employeeDetail.phone.phone}
                                                    gender={employeeDetail.gender}
                                                    maritalStatus={employeeDetail.maritalStatus}
                                                    bloodGroup={employeeDetail.bloodGroup}
                                                    presentAddress={employeeDetail.presentAddress}
                                                    designation={employeeDetail.designation}
                                                    email={employeeDetail.email}
                                                />
                                            </CustomTabPanel>
                                            <CustomTabPanel value={value} index={1}>
                                                <CompanyDetails
                                                    id={employeeDetail._id}
                                                    department={employeeDetail.department}
                                                    designation={employeeDetail.designation}
                                                    dateOfJoining={employeeDetail.dateOfJoining}
                                                    jobType={employeeDetail.jobType}
                                                    amount={employeeDetail.salary.amount}
                                                />
                                            </CustomTabPanel>
                                            {employeeDetail.bank ? (
                                                <CustomTabPanel value={value} index={2}>
                                                    <BankDetails
                                                        accountHolder={
                                                            employeeDetail.bank.accountHolder
                                                        }
                                                        accountNumber={
                                                            employeeDetail.bank.accountNumber
                                                        }
                                                        branch={employeeDetail.bank.branch}
                                                        bankName={employeeDetail.bank.bankName}
                                                        ifsc={employeeDetail.bank.ifsc}
                                                        pan={employeeDetail.bank.pan}
                                                        city={employeeDetail.bank.city}
                                                        state={employeeDetail.bank.state}
                                                        country={employeeDetail.bank.country}
                                                    />
                                                </CustomTabPanel>
                                            ) : (
                                                <CustomTabPanel value={value} index={2}>
                                                    <BankDetails />
                                                </CustomTabPanel>
                                            )}
                                            <CustomTabPanel value={value} index={3}>
                                                <UploadDocument />
                                            </CustomTabPanel>
                                        </>
                                    ) : (
                                        <Box>
                                            <Loading />
                                        </Box>
                                    )}
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            height: '50vh',
                            width: '100%',
                        }}>
                        <Images src='choose.svg' width='200' />
                        <Typography
                            variant='h5'
                            color='text.secondary'
                            fontWeight='bold'
                            sx={{ mt: 3 }}>
                            Please Select Employee
                        </Typography>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default Index;

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
