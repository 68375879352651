import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Typography } from '@mui/material';

const columns = [
    { id: 'S_No.', label: 'S. No.', minWidth: 80 },
    { id: 'Emp_Name', label: 'Emp Name', minWidth: 150 },
    { id: 'Status', label: 'Status', minWidth: 100, align: 'center' },
    {
        id: 'CheckIn',
        label: 'CheckIn',
        minWidth: 100,
        align: 'center',
    },
    { id: 'CheckOut', label: 'CheckOut', minWidth: 113, align: 'center' },
];

const RecentAttendance = ({ attendance }) => {
    return (
        <>
            <TableContainer>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                    }}>
                                    <Typography variant='caption'>{column.label}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {attendance
                            ? attendance.map((att, i) => (
                                  <TableRow
                                      sx={{
                                          '&:last-child td, &:last-child th': {
                                              border: 0,
                                          },
                                      }}>
                                      <TableCell component='th' scope='row'>
                                          <Typography variant='caption'>{i + 1}</Typography>
                                      </TableCell>
                                      <TableCell component='th' scope='row'>
                                          <Box>
                                              <Typography variant='body2'>
                                                  {att.employeeData?.firstName +
                                                      ' ' +
                                                      att.employeeData?.lastName}
                                              </Typography>
                                          </Box>
                                      </TableCell>
                                      <TableCell component='th' scope='row' align='center'>
                                          <Typography variant='caption'>{att.status}</Typography>
                                      </TableCell>
                                      <TableCell component='th' scope='row' align='center'>
                                          <Typography variant='caption'>
                                              {new Date(att.clockInTime).toLocaleTimeString()}
                                          </Typography>
                                      </TableCell>
                                      <TableCell component='th' scope='row' align='center'>
                                          <Typography variant='caption'>
                                              {new Date(att.clockOutTime).toLocaleTimeString()}
                                          </Typography>
                                      </TableCell>
                                  </TableRow>
                              ))
                            : null}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default RecentAttendance;
