import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Avatar, Box, Typography } from '@mui/material';

const columns = [
    { id: 'Emp_Name', label: 'Emp Name', minWidth: 185 },
    {
        id: 'Experience',
        label: 'Experience',
        minWidth: 100,
        align: 'center',
    },
    { id: 'Location', label: 'Designation', minWidth: 113, align: 'center' },
];

const RecentApplication = ({ applications }) => {
    return (
        <>
            <TableContainer>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                    }}>
                                    <Typography variant='caption'>{column.label}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {applications?.map(app => (
                            <TableRow
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}>
                                <TableCell component='th' scope='row'>
                                    <Box display='flex' alignItems='center'>
                                        <Box mr={1}>
                                            <Avatar
                                                alt='Remy Sharp'
                                                src='https://shorturl.at/fjqz9'
                                            />
                                        </Box>
                                        <Box>
                                            <Typography variant='body2'>{app.fullName}</Typography>
                                            <Typography color='text.secondary' variant='caption'>
                                                {app.email}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell component='th' scope='row' align='center'>
                                    <Typography variant='caption'>{app.experience}</Typography>
                                </TableCell>
                                <TableCell component='th' scope='row' align='center'>
                                    <Typography variant='caption'>{app.jobTitle}</Typography>
                                </TableCell>

                                {/* <TableCell component='th' scope='row' align='center'>
                                    <IconButton
                                        sx={{
                                            my: { xl: 0, xs: 1 },
                                            mx: 1,
                                            borderRadius: '4px',
                                            backgroundColor: 'custom.buttonColor.light.red',
                                            color: 'custom.buttonColor.dark.red',
                                            '&:hover': {
                                                backgroundColor: 'custom.buttonColor.light.red',
                                            },
                                        }}>
                                        <DeleteOutlineOutlinedIcon fontSize='small' />
                                    </IconButton>
                                </TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default RecentApplication;
