import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {
    Box,
    Button,
    Card,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Modal,
    Pagination,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
// import Loading from '../../components/Loading';
import axios from 'axios';
import { Input } from '../../hooks/useForm/inputs';
import { Form, Submit, useForm } from '../../hooks/useForm/useForm';
import moment from 'moment';
import { useMessage } from '../../layouts/Header';
import useModal from '../../hooks/useModal';
import { isEmpty } from '../../utils/function';
import useLoader from '../../hooks/useLoader';
import useErrorHandler from '../../hooks/useErrorHandler';
import Close from '@mui/icons-material/Close';
import { useReactToPrint } from 'react-to-print';

const columns = [
    { id: 'S. No.', label: 'S. No.', minWidth: 80 },
    { id: 'Employee', label: 'Employee', minWidth: 205, align: 'center' },
    { id: 'From', label: 'From', minWidth: 150, align: 'center' },
    { id: 'To', label: 'To', minWidth: 153, align: 'center' },
    { id: 'Salary', label: '($) Salary', minWidth: 100, align: 'center' },

    {
        id: 'Status',
        label: 'Status',
        minWidth: 45,
        align: 'center',
    },
    {
        id: 'Action',
        label: 'Action',
        minWidth: 300,
        align: 'center',
    },
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const EmploySalaryTable = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [pageNo, setPageNo] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(0);
    const [payrollUpdateData, setPayrollUpdateData] = React.useState({});
    const [payroll, setPayroll] = React.useState(null);
    const [selectedPayroll, setSelectedPayroll] = React.useState({});
    const { modalState, openModal, closeModal } = useModal();
    const { modalState: deleteState, openModal: openDelete, closeModal: closeDelete } = useModal();
    const { loaderState, start, end } = useLoader();
    const errorHandler = useErrorHandler();
    const { showSuccess, showError } = useMessage();
    const componentRef = React.useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [open, setOpen] = React.useState(false);
    const handleOpen = data => {
        setOpen(true);
        setPayrollUpdateData(data);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const fetchEmployeeSalary = React.useCallback(
        async function () {
            try {
                const response = await axios.get(
                    `/hr/payslip/?sortBy=createdAt&direction=-1&page=${pageNo}`
                );
                const body = response.data;
                const { payslips } = body;
                setPayroll(payslips);
                setPageLimit(response.data.pageData.totalPages);
            } catch (e) {
                console.log(e);
            }
        },
        [setPayroll, pageNo]
    );

    const totalAllowance = React.useMemo(
        () =>
            isEmpty(selectedPayroll)
                ? 0
                : [
                      selectedPayroll.hraAllowance,
                      selectedPayroll.conveyance,
                      selectedPayroll.medicalAllowance,
                      selectedPayroll.bonusAllowance,
                  ].reduce((total, el) => {
                      return total + parseInt(el);
                  }, 0),
        [selectedPayroll]
    );

    const totalDeduction = React.useMemo(
        () =>
            isEmpty(selectedPayroll)
                ? 0
                : [
                      selectedPayroll.tds,
                      selectedPayroll.pf,
                      selectedPayroll.professionalTax,
                      selectedPayroll.loanAndOthers,
                  ].reduce((total, el) => {
                      return total + parseInt(el);
                  }, 0),
        [selectedPayroll]
    );

    async function deletePayroll(id) {
        start();
        try {
            const res = await axios.delete(`/hr/payslip/${id}`);
            const { success, message } = res.data;
            if (success) {
                showSuccess('Payroll deleted');
            } else {
                showError(message);
            }
        } catch (e) {
            errorHandler(e);
        } finally {
            closeDelete();
            end();
            fetchEmployeeSalary();
        }
    }

    React.useEffect(() => {
        fetchEmployeeSalary();
    }, [fetchEmployeeSalary]);

    return (
        <>
            <Typography variant='h6' my={2} mb={4}>
                Employee Salary List
            </Typography>
            <Pagination
                page={pageNo}
                onChange={(_, newPage) => setPageNo(newPage)}
                color='primary'
                count={pageLimit}
                sx={{ float: 'right' }}
            />
            <TableContainer>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                    }}>
                                    <Typography variant='caption'>{column.label}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {payroll ? (
                        payroll.map((payroll, i) => (
                            <TableBody>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}>
                                    <TableCell component='th' scope='row'>
                                        {i + 1}
                                    </TableCell>
                                    {/* <TableCell component='th' scope='row'>
                                        <Box display='flex' alignItems='center'>
                                            <Box mx={2}>
                                                <Avatar
                                                    alt='Remy Sharp'
                                                    src='https://shorturl.at/fjqz9'
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant='body2'>
                                                    Gaurav Gupta
                                                </Typography>
                                                <Typography
                                                    color='text.secondary'
                                                    variant='caption'
                                                >
                                                    gaurav@gmail.com
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </TableCell> */}
                                    <TableCell component='th' scope='row' align='center'>
                                        <Typography variant='caption'>
                                            {payroll.employee.firstName +
                                                ' ' +
                                                payroll.employee.lastName}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component='th' scope='row' align='center'>
                                        <Typography variant='caption'>
                                            {new Date(payroll.from).toDateString()}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component='th' scope='row' align='center'>
                                        <Typography variant='caption'>
                                            {new Date(payroll.to).toDateString()}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component='th' scope='row' align='center'>
                                        <Typography variant='caption'>{payroll.salary}</Typography>
                                    </TableCell>

                                    <TableCell component='th' scope='row' align='center'>
                                        <Typography variant='caption'>{payroll.status}</Typography>
                                    </TableCell>
                                    <TableCell component='th' scope='row' align='center'>
                                        <IconButton
                                            onClick={() => handleOpen(payroll)}
                                            sx={{
                                                borderRadius: '12px',
                                                backgroundColor: 'custom.buttonColor.light.blue',
                                                color: 'custom.buttonColor.dark.blue',
                                                '&:hover': {
                                                    backgroundColor:
                                                        'custom.buttonColor.light.blue',
                                                },
                                            }}>
                                            <EditIcon fontSize='small' />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                setSelectedPayroll(payroll);
                                                openDelete();
                                            }}
                                            sx={{
                                                my: { xl: 0, xs: 1 },
                                                mx: 1,
                                                borderRadius: '12px',
                                                backgroundColor: 'custom.buttonColor.light.red',
                                                color: 'custom.buttonColor.dark.red',
                                                '&:hover': {
                                                    backgroundColor: 'custom.buttonColor.light.red',
                                                },
                                            }}>
                                            <DeleteOutlineOutlinedIcon fontSize='small' />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                setSelectedPayroll(payroll);
                                                openModal();
                                            }}
                                            sx={{
                                                borderRadius: '12px',
                                                backgroundColor:
                                                    'custom.buttonColor.light.lightGreen',
                                                color: 'custom.buttonColor.dark.lightGreen',
                                                '&:hover': {
                                                    backgroundColor:
                                                        'custom.buttonColor.light.lightGreen',
                                                },
                                            }}>
                                            <VisibilityIcon fontSize='small' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ))
                    ) : (
                        <Typography>No Data</Typography>
                    )}
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component='div'
                count={payroll ? payroll?.length : '0'}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Dialog open={deleteState} onClose={closeDelete}>
                <DialogTitle id='alert-dialog-title'>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Are you sure you want to delete the Payroll of{' '}
                        {selectedPayroll.employee?.firstName +
                            ' ' +
                            selectedPayroll.employee?.lastName || 'this Employee'}
                        ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDelete} color='primary' style={{ color: 'white' }}>
                        Cancel
                    </Button>

                    <Button
                        variant='contained'
                        onClick={() => deletePayroll(selectedPayroll._id)}
                        disabled={loaderState}
                        endIcon={
                            loaderState && (
                                <CircularProgress size='20px' sx={{ color: 'inherit' }} />
                            )
                        }
                        style={{ backgroundColor: '#ff2121' }}
                        autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'>
                <UpdatePaySlip
                    payrollUpdateData={payrollUpdateData}
                    handleClose={handleClose}
                    fetchEmployeeSalary={fetchEmployeeSalary}
                />
            </Modal>

            <Modal
                open={modalState}
                onClose={closeModal}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card
                    sx={{ p: 2.5, width: '100%', maxWidth: '840px', boxShadow: 'none' }}
                    elevation={0}
                    ref={componentRef}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography variant='h6'>Payroll</Typography>
                        <div>
                            <IconButton
                                onClick={() => {
                                    handlePrint();
                                }}
                                sx={{
                                    mx: 1,
                                    borderRadius: '12px',
                                    backgroundColor: 'custom.buttonColor.light.yellow',
                                    color: 'custom.buttonColor.dark.yellow',
                                    '&:hover': {
                                        backgroundColor: 'custom.buttonColor.light.yellow',
                                    },
                                }}>
                                <PrintIcon fontSize='small' />
                            </IconButton>
                            <IconButton onClick={closeModal}>
                                <Close />
                            </IconButton>
                        </div>
                    </Stack>
                    <Divider sx={{ my: 1 }} />
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            Employee
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {selectedPayroll.employee?.firstName +
                                ' ' +
                                selectedPayroll.employee?.lastName}
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            From
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {new Date(selectedPayroll.from).toLocaleDateString()}
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            To
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {new Date(selectedPayroll.to).toLocaleDateString()}
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            Status
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {selectedPayroll.status}
                        </Typography>
                    </Stack>
                    <Typography variant='h6' fontWeight={500} gutterBottom mt={2}>
                        Allowances
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            HRA allowances
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {selectedPayroll.hraAllowance}
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            Conveyance
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {selectedPayroll.conveyance}
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            Medical allowances
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {selectedPayroll.medicalAllowance}
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            Bonus allowances
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {selectedPayroll.bonusAllowance}
                        </Typography>
                    </Stack>
                    <Typography variant='h6' fontWeight={500} gutterBottom mt={2}>
                        Deductions
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            PF
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {selectedPayroll.pf}
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            Professional Tax
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {selectedPayroll.professionalTax}
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            TDS
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {selectedPayroll.loanAndOthers}
                        </Typography>
                    </Stack>
                    <Typography variant='h6' fontWeight={500} gutterBottom mt={2}>
                        Gross salary
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            Total allowances
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {totalAllowance}
                        </Typography>
                    </Stack>

                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            Total deductions
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {totalDeduction}
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            Net salary
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {parseInt(selectedPayroll.salary) + totalAllowance - totalDeduction}
                        </Typography>
                    </Stack>
                </Card>
            </Modal>
        </>
    );
};

const UpdatePaySlip = ({ payrollUpdateData, handleClose, fetchEmployeeSalary }) => {
    const from = moment(payrollUpdateData.from).utc().format('YYYY-MM-DD');
    const to = moment(payrollUpdateData.to).utc().format('YYYY-MM-DD');
    const handlers = useForm(
        React.useMemo(
            () => ({
                from: { value: from },
                to: { value: to },
                salary: { value: payrollUpdateData.salary },
            }),
            [from, to, payrollUpdateData.salary]
        ),
        { Input: TextField }
    );
    const [selectEmployee, setSelectEmployee] = React.useState({
        status: payrollUpdateData.status,
    });
    const handleChange = e => {
        const name = e.target.name;
        const value = e.target.value;
        setSelectEmployee({ ...selectEmployee, [name]: value });
    };
    const { showSuccess, showError } = useMessage();
    const submit = res => {
        const { success, message } = res.data;
        if (!success) return showError('payroll not added');
        showSuccess(message);
        fetchEmployeeSalary();
        handleClose();
    };
    return (
        <Box sx={style}>
            <Form
                handlers={handlers}
                onSubmit={submit}
                action={`/hr/payslip/${payrollUpdateData._id}`}
                method='patch'
                final={values => ({
                    ...values,
                    ...selectEmployee,
                })}
                onError={console.log}>
                {/* salary */}
                <Typography variant='h6'>Update Payslip</Typography>
                <Grid container spacing={1} mt={3} display='flex' alignItems='center'>
                    <Grid item xs={12}>
                        <Typography variant='body2'>Salary</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Input size='small' placeholder='0' name='salary' fullWidth required />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2'>From</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Input size='small' name='from' type='date' fullWidth required />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2'>To</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Input size='small' name='to' type='date' fullWidth required />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2'>Status :</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl sx={{ display: 'flex' }}>
                            <RadioGroup
                                row
                                aria-labelledby='demo-radio-buttons-group-label'
                                defaultValue='female'
                                name='status'
                                onChange={handleChange}
                                value={selectEmployee.status}>
                                <FormControlLabel value='paid' control={<Radio />} label='Paid' />
                                <FormControlLabel
                                    value='unPaid'
                                    control={<Radio />}
                                    label='UnPaid'
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Box mt={2} textAlign='right'>
                    <Button
                        onClick={handleClose}
                        variant='contained'
                        sx={{
                            background: '#F91A3F',
                            '$:hover': {
                                background: '#F91A3F',
                            },
                        }}>
                        Cancel
                    </Button>
                    <Submit>
                        {loader => (
                            <Button
                                type='submit'
                                disabled={Boolean(loader)}
                                endIcon={loader}
                                variant='contained'
                                sx={{
                                    mx: 2,

                                    background: '#4674FF',
                                    '$:hover': {
                                        background: '#4674FF',
                                    },
                                }}>
                                Save
                            </Button>
                        )}
                    </Submit>
                </Box>
            </Form>
        </Box>
    );
};

export default EmploySalaryTable;
