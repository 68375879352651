import React, { useState } from 'react';
import {
    Radio,
    RadioGroup,
    Typography,
    IconButton,
    FormControlLabel,
    TextField,
    Switch,
    Grid,
    Tabs,
    Tab,
    Paper,
    Button,Card, CardContent
} from '@mui/material'; 
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SocialSettingsIcon from '@mui/icons-material/Settings';
import PaymentSettingsIcon from '@mui/icons-material/Payment';
import OtherSettingsIcon from '@mui/icons-material/MoreVert';

const SocialSettingsForm = () => {
    return ( <div style={{ marginTop: '20px' }}>
    <div>
        <Typography variant='h5' style={{ marginbottom: '5px' }}>
        Social Settings
        </Typography>
        
        <Grid container spacing={1.5} marginTop={0.5}>
          <Grid item xs={12} sm={6} md={5.7} >
            <Card variant="outlined" style={{ borderRadius: 15 }}>
              <CardContent>
                <Grid container alignitems="center">
                  {/* Left side */}
                  <Grid item xs={11} container justify="flex-start">
                    <h3>Google Login</h3>
                  </Grid>
  
                  {/* Right side */}
                  <Grid item xs={1} alignitems="flex-end">
                    <FormControlLabel control={<Switch />} />
                  </Grid>
  
                  {/* Rest of the Google Login settings */}
                  <Grid item xs={12} marginbottom={2}>
                    <label>Callback URL</label>
                    <TextField
                      label="Callback URL"
                      placeholder="https://www.domain.com"
                      fullWidth
                      margin="normal"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label>Client ID</label>
                    <TextField
                      label="Client ID"
                      fullWidth
                      margin="normal"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label>Secret</label>
                    <TextField
                      label="Client Secret"
                      type="password"
                      fullWidth
                      margin="normal"
                      size="small"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
  
          <Grid item xs={12} sm={6} md={5.7}>
            <Card variant="outlined" style={{ borderRadius: 15 }}>
              <CardContent>
                <Grid container alignitems="center">
                  {/* Left side */}
                  <Grid item xs={11} container justify="flex-start">
                    <h3>Facebook Login</h3>
                  </Grid>
  
                  {/* Right side */}
                  <Grid item xs={1} alignitems="flex-end">
                    <FormControlLabel control={<Switch />} />
                  </Grid>
  
                  {/* Rest of the Facebook Login settings */}
                  <Grid item xs={12} marginbottom={2}>
                    <label>Callback URL</label>
                    <TextField
                      label="Callback URL"
                      placeholder="https://www.domain.com"
                      fullWidth
                      margin="normal"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label>Client ID</label>
                    <TextField
                      label="Client ID"
                      fullWidth
                      margin="normal"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label>Secret</label>
                    <TextField
                      label="Client Secret"
                      type="password"
                      fullWidth
                      margin="normal"
                      size="small"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
  
        <div style={{ marginTop: '20px' }}>
          <Button
            variant="contained"
            style={{  marginRight: '5px' }}
          >
            Save Changes
          </Button>
          <Button variant="contained" style={{ backgroundColor: 'red' }}>
            Cancel
          </Button>
        </div>
      </div>  </div>  );
};

const PaymentSettingsForm = () => {
    return (
        <div style={{ marginTop: '20px' }}>
            <div>
                <Typography variant='h5' style={{ marginbottom: '20px' }}>
                    Paypal Settings
                </Typography>
                <Grid container spacing={0.5} marginTop={1}>
        <Grid item xs={12} sm={3}marginTop="5px">
            <Typography style={{ fontSize: '14px' }}>Paypal
</Typography>
        </Grid>
        
        <Grid item xs={11} sm={8.5}>
        <FormControlLabel
                            control={<Switch color='primary' />}
                            label='Enable/Disable'
                            labelPlacement='end'
                            style={{ color: 'grey' }}
                        />
        </Grid>

                    <Grid item>
                        <Grid container spacing={0} marginTop={2} marginbottom={2}>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    style={{
                                     
                                        marginRight: '5px',
                                    }}
                                >
                                    Save Changes
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' color='error'>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

const OtherSettingsForm = () => {
    return (
<div>
      <Typography variant="h5" marginbottom={5} marginTop={1}>
        Other Settings
      </Typography>

      <Grid container spacing={0.5} margin-top={1}>
        <Grid item xs={12} sm={3}marginTop="15px" >
          <Typography style={{ fontSize: '14px' }}>API Type</Typography>
        </Grid>

        <Grid item xs={12} sm={9} marginTop={1}>
          <RadioGroup row size="small">
            <FormControlLabel
              value="Personal"
              control={<Radio color="primary" />}
              label="Personal"
              size="small"
            />
            <FormControlLabel
              value="Old Token"
              control={<Radio color="primary" />}
              label="Old Token"
            />
          </RadioGroup>
        </Grid>
      </Grid>

      <Grid container spacing={0.5} margin-top={1}>
        <Grid item xs={12} sm={3}marginTop="15px">
          <Typography style={{ fontSize: '14px' }}>API Token</Typography>
        </Grid>

        <Grid item xs={11} sm={8.5} marginTop={1}>
          <TextField
            variant="outlined"
            placeholder="Token"
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>

      <Grid container spacing={0.5} margin-top={1}>
        <Grid item xs={12} sm={3}marginTop="15px">
          <Typography style={{ fontSize: '14px' }}>Support Expiry</Typography>
        </Grid>

        <Grid item xs={12} sm={9}marginTop={1}>
          <FormControlLabel
            control={<Switch color="primary" />}
            label="Enable/Disable"
            labelPlacement="end"
            style={{ color: 'grey' }}
          />
        </Grid>

        <Grid container spacing={0} margin-bottom={2} marginTop={3}>
          <Grid item>
            <Button
              variant="contained"
              style={{
               
                marginRight: '5px',
              }}
            >
              Save Changes
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="error">
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
        
    ); 
};

const ApiSetting = () => {
    const [selectedTab, setSelectedTab] = useState(0); // State to keep track of selected tab

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const renderForm = () => {
        switch (selectedTab) {
            case 0:
                return <SocialSettingsForm />;
            case 1:
                return <PaymentSettingsForm />;
            case 2:
                return <OtherSettingsForm />;
            default:
                return null;
        }
    };

    return (


<div>
        <div style={{ marginbottom: 10 }}>
          <Grid container spacing={2} marginTop={1} alignitems="center" paddingLeft={2}>
            <Grid item xs>
              <Typography variant="h5">API Settings</Typography>
            </Grid>
            <Grid item sx={{ display: { xs: 'flex' }, mx: 'auto' }} alignitems="center">
              <div>
                <IconButton variant="navIcon" disableRipple style={{ marginRight: 0, paddingRight: 2 }}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </div>
  
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Paper style={{ width: '100%', marginbottom: '20px', height: '170px', marginTop: '15px',borderRadius:'12px' }}>
              <Tabs
                orientation="vertical"
                value={selectedTab}

                onChange={handleTabChange}
                textColor="primary"
                alignitems="right"
                
              >
                <Tab icon={<SocialSettingsIcon style={{height:'20px',marginTop:'5px'}}/>} sx={{ flexDirection: 'row', alignItems: 'center',justifyContent: 'flex-start', gap: '8px',minHeight: '55px' }} label="Social Settings" />
                <Tab icon={<PaymentSettingsIcon style={{height:'20px',marginTop:'5px'}} />}  sx={{ flexDirection: 'row',alignItems: 'center', justifyContent: 'flex-start',minHeight: '55px', gap: '8px' }} label="Payment Settings" />
                <Tab icon={<OtherSettingsIcon style={{height:'20px',marginTop:'5px'}}/>}  sx={{ flexDirection: 'row', alignItems: 'center',justifyContent: 'flex-start', minHeight: '55px',gap: '8px' }} label="Other Settings" />
               
              </Tabs>
            </Paper>
            
          </Grid>
  
          <Grid item xs={12} md={9}>
            <div style={{ marginTop:'20px', position: 'relative', width: '100%', border: '1px solid #000' }}>
              <Card sx={{ borderRadius: '12px', width: '100%', marginbottom: '20px' }}>
                <CardContent style={{ width: '100%' }}>
                  <div style={{ width: '100%', height: '70%', marginLeft: '20px' }}>
                    {renderForm()}
                  </div>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
    );
};

export default ApiSetting;
