import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';

const chartSetting = {
    // width: 700,
    height: 502,

    sx: {
        [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'rotate(-90deg) translate(0px, -100px)',
        },
    },
};
const dataset = [
    {
        Closed_Ticket: 59,
        Active_Ticket: 57,

        month: 'Jan',
    },
    {
        Closed_Ticket: 50,
        Active_Ticket: 52,

        month: 'Fev',
    },
    {
        Closed_Ticket: 47,
        Active_Ticket: 53,

        month: 'Mar',
    },
    {
        Closed_Ticket: 54,
        Active_Ticket: 56,

        month: 'Apr',
    },
    {
        Closed_Ticket: 57,
        Active_Ticket: 69,

        month: 'May',
    },
    {
        Closed_Ticket: 60,
        Active_Ticket: 63,

        month: 'June',
    },
    {
        Closed_Ticket: 59,
        Active_Ticket: 60,

        month: 'July',
    },
    {
        Closed_Ticket: 65,
        Active_Ticket: 60,

        month: 'Aug',
    },
    {
        Closed_Ticket: 51,
        Active_Ticket: 51,

        month: 'Sept',
    },
    {
        Closed_Ticket: 60,
        Active_Ticket: 65,

        month: 'Oct',
    },
    {
        Closed_Ticket: 60,
        Active_Ticket: 65,

        month: 'Nov',
    },
    {
        Closed_Ticket: 60,
        Active_Ticket: 65,

        month: 'Dec',
    },
];
const valueFormatter = (value: number) => `${value}mm`;
const DashboardBarChart = () => {
    return (
        <>
            <BarChart
                dataset={dataset}
                xAxis={[
                    {
                        scaleType: 'band',
                        dataKey: 'month',
                        categoryGapRatio: 0.5,
                    },
                ]}
                series={[
                    {
                        dataKey: 'Active_Ticket',
                        label: 'Active',
                        valueFormatter,
                    },
                    {
                        dataKey: 'Closed_Ticket',
                        label: 'Closed',
                        valueFormatter,
                    },
                ]}
                {...chartSetting}
            />
        </>
    );
};

export default DashboardBarChart;
