import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DashboardCard from '../../components/DashboardCard';
import GroupIcon from '@mui/icons-material/Group';
import Noticecard from './Noticecard';
import RecentApplication from './RecentApplication';
import RecentAttendance from './RecentAttendance';
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import useErrorHandler from '../../hooks/useErrorHandler';
import DashboardChart from './DashboardChart';

const Index = () => {
    const [time, setTime] = useState();
    const [Dates, setDate] = useState();
    const [overview, setOverview] = useState({});
    const errorHandler = useErrorHandler();

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date().toLocaleTimeString());
        }, 1000);
        const Date1 = setInterval(() => {
            setDate(new Date().toLocaleDateString());
        }, 1000);

        return () => {
            clearInterval(timer);
            clearInterval(Date1);
        };
    }, []);

    const fetchOverview = useCallback(async () => {
        try {
            const response = await axios.get(`/hr/dashboard`);
            setOverview(response.data.overview);
        } catch (e) {
            errorHandler(e);
        }
    }, [errorHandler]);

    console.log(overview);

    useEffect(() => {
        fetchOverview();
    }, [fetchOverview]);

    return (
        <>
            <Box mt={3}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>HR Dashboard</Typography>
                    </Grid>

                    <Grid item sx={{ display: { xs: 'flex' }, mx: 'auto' }} alignItems='center'>
                        <Box sx={{ mr: 2, display: { lg: 'block', xs: 'none' } }}>
                            <Button variant='outlined'>{Dates}</Button>
                        </Box>
                        <Button variant='outlined' sx={{ display: { lg: 'block', xs: 'none' } }}>
                            {time}
                        </Button>
                        <Box sx={{ mx: 2 }}>
                            <Button variant='contained'>Clock In</Button>
                        </Box>

                        <Box>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0 }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box my={3}>
                <Grid container spacing={2}>
                    <Grid item lg={9} xs={12}>
                        <Grid container spacing={2}>
                            <DashboardCard
                                Title='Total Employees'
                                Number={overview.employees?.total || 0}
                                Color='#0DCD94'
                                Icon=<GroupIcon />
                            />
                            <DashboardCard
                                Title='Department'
                                Number={overview.departments || 0}
                                Color='#F34932'
                                Icon=<BusinessIcon />
                            />
                            <DashboardCard
                                Title='Expenses'
                                Number={`$${
                                    overview.expenses
                                        ? overview.expenses.reduce(
                                              (total, el) => total + el.price,
                                              0
                                          )
                                        : 0
                                }`}
                                Color='#AA4CF2'
                                Icon=<AttachMoneyIcon />
                            />
                        </Grid>
                        <Box mt={2}>
                            <Card elevation={0} sx={{ position: 'relative', height: '100%' }}>
                                <CardContent>
                                    <Box
                                        sx={{
                                            borderLeftWidth: '5px',
                                            borderLeftColor: 'primary.main',
                                            borderLeftStyle: 'solid',
                                            height: '40px',
                                            width: '5px',
                                            position: 'absolute',

                                            left: 1,
                                        }}></Box>
                                    <Typography variant='h6' sx={{ mb: 1 }}>
                                        Overview
                                    </Typography>
                                    <DashboardChart
                                        salaries={overview.salaries}
                                        expenses={overview.expenses}
                                    />
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item lg={3} xs={12}>
                        <Card elevation={0} sx={{ position: 'relative', height: '100%' }}>
                            <CardContent>
                                <Box
                                    sx={{
                                        borderLeftWidth: '5px',
                                        borderLeftColor: 'primary.main',
                                        borderLeftStyle: 'solid',
                                        height: '40px',
                                        width: '5px',
                                        position: 'absolute',

                                        left: 1,
                                    }}></Box>
                                <Typography variant='h6' sx={{ mb: 3 }}>
                                    Notice Board
                                </Typography>
                                {overview.notices?.map((notice, i) => (
                                    <React.Fragment key={notice._id}>
                                        <Noticecard
                                            title={notice.title}
                                            caption={notice.caption}
                                            date={notice.createdAt}
                                            Background='custom.buttonColor.light.blue'
                                            Textcolor='custom.buttonColor.dark.blue'
                                        />
                                        {i === 8 ? null : <Divider sx={{ my: 1 }} />}
                                    </React.Fragment>
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {/* <Grid container spacing={2} mt={2}>
                    <Grid item lg={4} xs={12}>
                        <ProjectOverview />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <Card elevation={0} sx={{ position: 'relative' }}>
                            <CardContent>
                                <Box
                                    sx={{
                                        borderLeftWidth: '5px',
                                        borderLeftColor: 'primary.main',
                                        borderLeftStyle: 'solid',
                                        height: '40px',
                                        width: '5px',
                                        position: 'absolute',

                                        left: 1,
                                    }}></Box>
                                <Typography variant='h6' sx={{ mb: 2 }}>
                                    Recent Activity
                                </Typography>
                                <RecentActivity />
                                <RecentActivity />
                                <RecentActivity />
                                <RecentActivity />
                                <RecentActivity />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <TotalEmployees
                            female={overview.employees?.female}
                            male={overview.employees?.male}
                            total={overview.employees?.total}
                        />
                    </Grid>
                </Grid> */}
            </Box>
            <Box mb={2}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Card elevation={0} sx={{ position: 'relative', height: '100%' }}>
                            <CardContent>
                                <Box
                                    sx={{
                                        borderLeftWidth: '5px',
                                        borderLeftColor: 'primary.main',
                                        borderLeftStyle: 'solid',
                                        height: '40px',
                                        width: '5px',
                                        position: 'absolute',

                                        left: 1,
                                    }}></Box>
                                <Box
                                    display='flex'
                                    justifyContent='space-between'
                                    alignItems='center'
                                    mb={2}>
                                    <Typography variant='h6'>Recent Job Application</Typography>
                                    <Link to='/received-application'>
                                        <Button variant='contained' size='small'>
                                            View All
                                        </Button>
                                    </Link>
                                </Box>
                                {overview.applications && (
                                    <RecentApplication applications={overview.applications} />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Card elevation={0} sx={{ position: 'relative', height: '100%' }}>
                            <CardContent>
                                <Box
                                    sx={{
                                        borderLeftWidth: '5px',
                                        borderLeftColor: 'primary.main',
                                        borderLeftStyle: 'solid',
                                        height: '40px',
                                        width: '5px',
                                        position: 'absolute',

                                        left: 1,
                                    }}></Box>
                                <Box
                                    display='flex'
                                    justifyContent='space-between'
                                    alignItems='center'
                                    mb={2}>
                                    <Typography variant='h6'>Attendance</Typography>

                                    <Button
                                        variant='contained'
                                        size='small'
                                        LinkComponent={Link}
                                        to='/attendance'>
                                        View All
                                    </Button>
                                </Box>
                                <RecentAttendance attendance={overview.attendance} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Index;
