import React, { useEffect, useState } from 'react';

import { Link, NavLink, useLocation } from 'react-router-dom';

//mui component
import {
    AppBar,
    Box,
    Stack,
    Drawer as MuiDrawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
    Button,
    Collapse,
    Grid,
    Toolbar,
    Typography,
    ListItemButton,
    Menu,
    MenuItem,
    styled,
    useMediaQuery,
} from '@mui/material';

//mui icons
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import Groups3Icon from '@mui/icons-material/Groups3';
import PaymentIcon from '@mui/icons-material/Payment';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

import { useTheme } from '../style/theme';
import { useMenu } from '../hooks/useMenu';
import { env } from '../utils/function';
import { NavLinks, AttendanceCollapse, Payroll, footerLink, Employee } from '../data/navLinks';

import useMedia from '../hooks/useMedia';
import { Image } from '../components/Image';
import { useUser } from '../hooks/Authenticate';
import Cookies from 'js-cookie';

const drawerWidth = 260;
const appsWidth = 10;
const miniDrawerWidth = 72;

const openedMixin = theme => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.default,
    borderRight: 'none',
});

const closedMixin = theme => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.background.default,
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    borderRight: 'none',
});

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,

    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

export default function Navbar(props) {
    const { children } = props;
    const smLayout = useMedia('(min-width: 576px)');

    const [mobileOpen, setMobileOpen] = useState(false);

    const [collapseDrawer, setCollapseDrawer] = useState(true);
    const [drawerHover, setDrawerHover] = useState(false);
    const matches = useMediaQuery('(min-width:1024px)', { noSsr: true });
    const [open, setOpen] = React.useState(false);

    const user = useUser();
    const location = useLocation();

    const { toggleTheme, mode } = useTheme();

    // useMenu
    const {
        anchorEl: anchorElProfile,
        openMenu: openProfileMenu,
        closeMenu: closeProfileMenu,
    } = useMenu();

    const {
        anchorEl: anchorElSettings,
        openMenu: openSettingsMenu,
        closeMenu: closeSettingsMenu,
    } = useMenu();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleDrawerOpen = () => {
        setCollapseDrawer(!collapseDrawer);
    };

    const signOut = () => {
        Cookies.remove('accessToken');

        const redirectTo =
            env('AUTHENTICATION_CLIENT') + '/login?redirectto=' + encodeURIComponent(env('DOMAIN'));
        window.location.replace(redirectTo);
    };

    useEffect(() => {
        setMobileOpen(false);
    }, [location.pathname, location.hash]);

    const [openPayroll, setOpenPayroll] = React.useState(false);
    const [openDashboard, setOpenDashboard] = React.useState(false);
    const [openEmployee, setOpenEmployee] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleClickPayroll = () => {
        setOpenPayroll(!openPayroll);
    };
    const handleClickDashboard = () => {
        setOpenDashboard(!openDashboard);
    };

    const handleClickEmployee = () => {
        setOpenEmployee(!openEmployee);
    };

    useEffect(() => {
        (AttendanceCollapse.find(link => link.to === location.pathname) ||
            NavLinks.dashboard.find(link => link.to === location.pathname) ||
            Employee.find(link => link.to === location.pathname) ||
            Payroll.find(link => link.to === location.pathname) ||
            footerLink.find(link => link.to === location.pathname)) &&
            setOpenDashboard(true);
    }, [location.pathname]);

    useEffect(() => {
        Employee.find(link => link.to === location.pathname) && setOpenEmployee(true);
    }, [location.pathname]);

    useEffect(() => {
        Payroll.find(link => link.to === location.pathname) && setOpenPayroll(true);
    }, [location.pathname]);

    useEffect(() => {
        AttendanceCollapse.find(link => link.to === location.pathname) && setOpen(true);
    }, [location.pathname]);

    const drawer = (
        <Box minHeight='100dvh' color='text.secondary' display='flex' flexDirection='column'>
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                component={Link}
                to='/'
                sx={{ textDecoration: 'none', color: 'text.primary', py: 1 }}>
                <Image name='logo.jpg' sx={{ height: '30px' }} />
                <Typography variant='h5' fontWeight={500} mt={0.5} ml={1} color='primary.light'>
                    HR
                </Typography>
            </Box>

            <Box
                sx={{
                    overflowY: 'auto',
                    height: 'calc(100dvh - 90px)',
                    flexGrow: 1,
                    '::-webkit-scrollbar': { display: 'none' },
                }}>
                <Typography pl={3} pb={1} mt={1.5} fontSize='14px' fontWeight={500}>
                    Hr
                </Typography>

                {/* dashboard */}
                <List sx={{ px: 3, py: '1.5px' }}>
                    <ListItemButton
                        onClick={handleClickDashboard}
                        disableRipple
                        disableTouchRipple
                        variant='sidebarButton'
                        selected={openDashboard}>
                        <ListItemIcon
                            sx={{
                                minWidth: '35px',
                                color: `${openDashboard ? '#3B84D9' : 'text.secondary'}`,
                            }}>
                            <HomeIcon fontSize='small' />
                        </ListItemIcon>

                        <ListItemText primary='Dashboard' />
                        {openDashboard ? (
                            <ExpandMore fontSize='small' />
                        ) : (
                            <ChevronRightOutlinedIcon />
                        )}
                    </ListItemButton>
                    <Collapse in={openDashboard} timeout='auto' unmountOnExit>
                        {/* Navlinks */}
                        <List sx={{ p: '10px', my: 0, py: 0 }}>
                            {NavLinks.dashboard.map(link => (
                                <NavLink
                                    to={link.to}
                                    key={link.name}
                                    style={{
                                        textDecoration: 'none',
                                        color: 'inherit',
                                    }}>
                                    {({ isActive }) => (
                                        <ListItem disablePadding>
                                            <ListItemButton
                                                selected={isActive}
                                                disableRipple
                                                disableTouchRipple
                                                variant='sidebarDropDown'>
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: '35px',
                                                        color: 'text.secondary',
                                                    }}>
                                                    {link.icon}
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant='caption'>
                                                            {link.name}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                </NavLink>
                            ))}
                        </List>
                        {/* employee */}
                        <List sx={{ p: '10px', mt: 0, py: 0 }}>
                            <ListItemButton
                                onClick={handleClickEmployee}
                                disableRipple
                                disableTouchRipple
                                variant='sidebarDropDown'
                                selected={openEmployee}>
                                <ListItemIcon
                                    sx={{
                                        minWidth: '35px',
                                        color: 'text.secondary',
                                    }}>
                                    <Groups3Icon />
                                </ListItemIcon>
                                <ListItemText
                                    primary='Employees'
                                    primaryTypographyProps={{
                                        variant: 'caption',
                                    }}
                                />
                                {openEmployee ? (
                                    <ExpandMore fontSize='small' />
                                ) : (
                                    <ChevronRightOutlinedIcon />
                                )}
                            </ListItemButton>
                            <Collapse in={openEmployee} timeout='auto' unmountOnExit>
                                <List sx={{ p: '10px', ml: 2, py: 0 }}>
                                    {Employee.map(link => (
                                        <NavLink
                                            to={link.to}
                                            key={link.name}
                                            style={{
                                                textDecoration: 'none',
                                                color: 'inherit',
                                            }}>
                                            {({ isActive }) => (
                                                <ListItem disablePadding>
                                                    <ListItemButton
                                                        selected={isActive}
                                                        disableRipple
                                                        disableTouchRipple
                                                        variant='sidebarDropDown'>
                                                        <ListItemIcon
                                                            sx={{
                                                                minWidth: '30px',
                                                                color: 'text.secondary',
                                                            }}>
                                                            {link.icon}
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            secondary={
                                                                <Typography variant='caption'>
                                                                    {link.name}
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            )}
                                        </NavLink>
                                    ))}
                                </List>
                            </Collapse>
                        </List>
                        {/* attendance */}
                        <List sx={{ p: '10px', mt: 0, py: 0 }}>
                            <ListItemButton
                                onClick={handleClick}
                                disableRipple
                                disableTouchRipple
                                variant='sidebarDropDown'
                                selected={open}>
                                <ListItemIcon
                                    sx={{
                                        minWidth: '35px',
                                        color: 'text.secondary',
                                    }}>
                                    <InboxIcon />
                                </ListItemIcon>
                                <ListItemText
                                    secondary={
                                        <Typography variant='caption'>Attendance</Typography>
                                    }
                                />
                                {open ? (
                                    <ExpandMore fontSize='small' />
                                ) : (
                                    <ChevronRightOutlinedIcon />
                                )}
                            </ListItemButton>
                            <Collapse in={open} timeout='auto' unmountOnExit>
                                <List sx={{ p: '10px', ml: 2, py: 0 }}>
                                    {AttendanceCollapse.map(link => (
                                        <NavLink
                                            to={link.to}
                                            key={link.name}
                                            end={false}
                                            style={{
                                                textDecoration: 'none',
                                                color: 'inherit',
                                            }}>
                                            {({ isActive }) => (
                                                <ListItem disablePadding>
                                                    <ListItemButton
                                                        selected={isActive}
                                                        disableRipple
                                                        disableTouchRipple
                                                        variant='sidebarDropDown'>
                                                        <ListItemIcon
                                                            sx={{
                                                                minWidth: '30px',
                                                                color: 'text.secondary',
                                                            }}>
                                                            {link.icon}
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            secondary={
                                                                <Typography variant='caption'>
                                                                    {link.name}
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            )}
                                        </NavLink>
                                    ))}
                                </List>
                            </Collapse>
                        </List>

                        {/* footer */}
                        <List sx={{ p: '10px', my: 0, py: 0 }}>
                            {footerLink.map(link => (
                                <NavLink
                                    to={link.to}
                                    key={link.name}
                                    style={{
                                        textDecoration: 'none',
                                        color: 'inherit',
                                    }}>
                                    {({ isActive }) => (
                                        <ListItem disablePadding>
                                            <ListItemButton
                                                selected={isActive}
                                                disableRipple
                                                disableTouchRipple
                                                variant='sidebarDropDown'>
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: '35px',
                                                        color: 'text.secondary',
                                                    }}>
                                                    {link.icon}
                                                </ListItemIcon>
                                                <ListItemText
                                                    secondary={
                                                        <Typography variant='caption'>
                                                            {link.name}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                </NavLink>
                            ))}
                        </List>
                        {/* payroll */}
                        <List sx={{ p: '10px', mt: 0, py: 0 }}>
                            <ListItemButton
                                onClick={handleClickPayroll}
                                disableRipple
                                disableTouchRipple
                                variant='sidebarDropDown'
                                selected={openPayroll}>
                                <ListItemIcon
                                    sx={{
                                        minWidth: '35px',
                                        color: 'text.secondary',
                                    }}>
                                    <PaymentIcon />
                                </ListItemIcon>
                                <ListItemText
                                    secondary={<Typography variant='caption'>Payroll</Typography>}
                                />
                                {openPayroll ? (
                                    <ExpandMore fontSize='small' />
                                ) : (
                                    <ChevronRightOutlinedIcon />
                                )}
                            </ListItemButton>
                            <Collapse in={openPayroll} timeout='auto' unmountOnExit>
                                <List sx={{ p: '10px', ml: 2, py: 0 }}>
                                    {Payroll.map(link => (
                                        <NavLink
                                            to={link.to}
                                            key={link.name}
                                            style={{
                                                textDecoration: 'none',
                                                color: 'inherit',
                                            }}>
                                            {({ isActive }) => (
                                                <ListItem disablePadding>
                                                    <ListItemButton
                                                        selected={isActive}
                                                        disableRipple
                                                        disableTouchRipple
                                                        variant='sidebarDropDown'>
                                                        <ListItemIcon
                                                            sx={{
                                                                minWidth: '30px',
                                                                color: 'text.secondary',
                                                            }}>
                                                            {link.icon}
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            secondary={
                                                                <Typography variant='caption'>
                                                                    {link.name}
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            )}
                                        </NavLink>
                                    ))}
                                </List>
                            </Collapse>
                        </List>
                        {/* project link */}
                    </Collapse>
                </List>
            </Box>
        </Box>
    );

    const miniDrawer = (
        <Box minHeight='100dvh' color='text.secondary' display='flex' flexDirection='column'>
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                component={Link}
                mb={3}
                to='/'
                sx={{ textDecoration: 'none', color: 'text.primary', py: 1.5 }}>
                <Image name='logo.jpg' sx={{ height: '36px' }} />
            </Box>
            <Box
                sx={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    height: 'calc(100dvh - 90px)',
                    flexGrow: 1,
                }}>
                {/* dashboard */}
                <List sx={{ px: 1, pb: 0, my: '2px' }}>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={handleClickDashboard}
                            disableRipple
                            disableTouchRipple
                            variant='sidebarButton'
                            selected={openDashboard}
                            sx={{ height: '45px' }}>
                            <ListItemIcon
                                sx={{
                                    // minWidth: '35px',
                                    color: `${openDashboard ? '#3B84D9' : 'text.secondary'}`,
                                }}>
                                <HomeIcon fontSize='small' />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Box>
    );

    return (
        <Box
            sx={{
                bgcolor: 'background.default',
                px: { xs: 0.5, xm: 0 },
                height: '100dvh',
                position: 'relative',
            }}>
            <AppBar
                elevation={0}
                component={Box}
                position='sticky'
                sx={{
                    width: {
                        xs: '100%',
                        xm:
                            collapseDrawer && !drawerHover
                                ? `calc(100% - ${drawerWidth}px)`
                                : `calc(100% - ${miniDrawerWidth}px )`,
                    },
                    ml: {
                        xm:
                            collapseDrawer && !drawerHover
                                ? `${drawerWidth}px`
                                : `${miniDrawerWidth}px`,
                    },
                    backgroundColor: 'background.default',

                    borderBottom: '1px solid custom.border',
                    transition: '225ms, background-color 0s',
                    color: 'text.primary',
                }}>
                <Toolbar
                    sx={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        position: 'relative',
                        '&': {
                            minHeight: '64px',
                            px: 1,
                        },
                    }}>
                    <Grid container alignItems='center' columnSpacing={1}>
                        <Grid item>
                            <IconButton
                                onClick={matches ? handleDrawerOpen : handleDrawerToggle}
                                edge='start'
                                sx={{
                                    ml: 0.2,
                                    mr: 1,
                                }}>
                                <MenuIcon sx={{ fontSize: '30px' }} />
                            </IconButton>
                        </Grid>

                        <Grid item xs display={{ xs: 'none', sm: 'block' }}>
                            <Stack
                                direction='row'
                                alignItems='center'
                                justifyContent='flex-end'
                                spacing={0}>
                                <IconButton onClick={openSettingsMenu}>
                                    <SettingsIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorElSettings}
                                    open={Boolean(anchorElSettings)}
                                    onClose={closeSettingsMenu}>
                                    <MenuItem onClick={toggleTheme}>
                                        <ListItemIcon>
                                            {mode === 'dark' ? (
                                                <LightModeIcon fontSize='small' />
                                            ) : (
                                                <DarkModeIcon fontSize='small' />
                                            )}
                                        </ListItemIcon>
                                        Appearance
                                    </MenuItem>
                                    <MenuItem
                                        onClick={signOut}
                                        sx={{
                                            display: {
                                                xs: 'flex',
                                                sm: 'none',
                                            },
                                        }}>
                                        <ListItemIcon>
                                            <LogoutIcon fontSize='small' />
                                        </ListItemIcon>
                                        Sign out
                                    </MenuItem>
                                </Menu>
                            </Stack>
                        </Grid>
                        <Grid item>
                            {smLayout ? (
                                <IconButton
                                    onClick={openProfileMenu}
                                    sx={{
                                        borderWidth: '2px',
                                        borderStyle: 'solid',
                                        borderColor: 'primary.main',
                                        p: '3px',
                                    }}>
                                    <Avatar alt='UK' sx={{ width: 30, height: 30 }} />
                                </IconButton>
                            ) : (
                                <Link to='http://apps.catch.com/' target='_blank'>
                                    <IconButton
                                        onClick={openProfileMenu}
                                        sx={{
                                            borderWidth: '2px',
                                            borderStyle: 'solid',
                                            borderColor: 'primary.main',
                                            p: '3px',
                                        }}>
                                        <Avatar alt='UK' sx={{ width: 30, height: 30 }} />
                                    </IconButton>
                                </Link>
                            )}
                            <Menu
                                anchorEl={anchorElProfile}
                                open={Boolean(anchorElProfile)}
                                onClose={closeProfileMenu}
                                sx={{
                                    '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                                        width: 'min(100%, 320px)',
                                        boxShadow:
                                            'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
                                        border: '1px solid #00000017',
                                        bgcolor: 'custom.menu',
                                        px: 0.5,
                                        pt: 1.5,
                                    },
                                }}>
                                <Grid container spacing={2} alignItems='center' flexWrap='nowrap'>
                                    <Grid item>
                                        <Avatar
                                            alt='uK'
                                            sx={{
                                                width: 100,
                                                height: 100,
                                                position: 'relative',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography
                                            variant='substitle1'
                                            component='div'
                                            fontWeight={600}
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>
                                            {user?.firstName + ' ' + user?.lastName}
                                        </Typography>
                                        <Typography
                                            variant='caption'
                                            component='div'
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                            }}>
                                            {user?.email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Stack direction='row' mt={2}>
                                    <Button variant='text' onClick={signOut} fullWidth>
                                        Sign out
                                    </Button>
                                </Stack>
                            </Menu>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <Box
                component='nav'
                sx={{
                    width: { xm: drawerWidth },
                    flexShrink: { sm: 0 },
                    bgcolor: 'custom.menu',
                }}>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <MuiDrawer
                    variant='temporary'
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', xm: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            bgcolor: 'custom.menu',
                        },
                    }}>
                    {drawer}
                </MuiDrawer>
                <Drawer
                    variant='permanent'
                    open={collapseDrawer}
                    hover={drawerHover}
                    onMouseOver={() => {
                        if (!collapseDrawer) {
                            setCollapseDrawer(true);
                            setDrawerHover(true);
                        }
                    }}
                    onMouseLeave={() => {
                        if (drawerHover) {
                            setCollapseDrawer(false);
                            setDrawerHover(false);
                        }
                    }}
                    sx={{
                        display: { xs: 'none', xm: 'block' },
                        p: 0,
                        '& .MuiDrawer-paper': {
                            boxShadow: drawerHover
                                ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
                                : 'none',
                        },
                    }}>
                    {collapseDrawer ? drawer : miniDrawer}
                </Drawer>
            </Box>

            <Box
                component='main'
                sx={{
                    width: {
                        xs: '100%',
                        xm:
                            collapseDrawer && !drawerHover
                                ? `calc(100% - ${drawerWidth + appsWidth}px)`
                                : `calc(100% - ${appsWidth + miniDrawerWidth}px )`,
                    },
                    ml: {
                        xm:
                            collapseDrawer && !drawerHover
                                ? `${drawerWidth}px`
                                : `${miniDrawerWidth}px`,
                    },
                    mt: 1,
                    height: { xs: 'calc(100dvh - 90px)' },
                    overflow: 'auto',
                    backgroundColor: 'background.main',
                    borderRadius: '12px',

                    px: 2,
                    transition: '225ms, background-color 0s',
                }}>
                {children}
            </Box>
        </Box>
    );
}
