import React, { useCallback, useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Modal,
    Tooltip,
    Typography,
} from '@mui/material';
import Awardtable from './Awardtable';
import AddAward from './AddAward';
import useErrorHandler from '../../hooks/useErrorHandler';
import useModal from '../../hooks/useModal';
import axios from 'axios';

const Index = () => {
    const errorHandler = useErrorHandler();
    const { modalState, openModal, closeModal } = useModal();

    const [awards, setAwards] = useState(null);
    const [award, setAward] = useState(null);

    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const fetchAwards = useCallback(async () => {
        try {
            const params = {
                page,
            };

            const response = await axios.get('/hr/awards', { params });

            setAwards(response.data);
        } catch (e) {
            errorHandler(e);
        }
    }, [errorHandler, page]);

    const editAward = id => {
        setAward(id);
        openModal();
    };

    useEffect(() => {
        fetchAwards();
    }, [fetchAwards]);

    return (
        <>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Awards</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    setAward(null);
                                    openModal();
                                }}>
                                Add New Award
                            </Button>
                        </Box>
                        <Box sx={{ ml: 2 }}>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0 }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box my={4}>
                <Card elevation={0}>
                    <CardContent>
                        <Awardtable
                            awards={awards?.awards}
                            editAward={editAward}
                            page={page}
                            handleChangePage={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            count={awards?.pageData?.totalData}
                        />
                    </CardContent>
                </Card>
            </Box>
            <Modal open={modalState} onClose={closeModal}>
                <AddAward handleClose={closeModal} refetch={fetchAwards} award={award} />
            </Modal>
        </>
    );
};

export default Index;
