import {
    Box,
    Button,
    FormControl,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input } from '../../hooks/useForm/inputs';
import { Form, useForm } from '../../hooks/useForm/useForm';
import moment from 'moment';
import axios from 'axios';

const CompanyDetails = ({ id, department, designation, jobType, amount, dateOfJoining }) => {
    const dateJoining = moment(dateOfJoining).utc().format('YYYY-MM-DD');

    const handlers = useForm(
        useMemo(
            () => ({
                userId: { value: id },
                dateOfJoining: { value: dateJoining },
                resignationDate: '',
                terminationDate: '',
                creditLeaves: '',
                department: { value: department },
                designation: { value: designation },
                salary: { value: amount },
            }),
            [id, department, designation, amount, dateJoining]
        ),
        { Input: TextField }
    );
    const [employee, setEmployee] = useState({
        jobType: jobType,
        department: department,
    });
    const [departments, setDepartments] = useState(null);
    const handleChange = e => {
        const name = e.target.name;
        const value = e.target.value;
        setEmployee({ ...employee, [name]: value });
    };

    const fetchDepartment = useCallback(
        async function () {
            try {
                const response = await axios.get(`/hr/department?sortBy=order`);
                setDepartments(response.data.departments);
            } catch (e) {
                console.log(e);
            }
        },
        [setDepartments]
    );

    useEffect(() => {
        fetchDepartment();
    }, [fetchDepartment]);

    return (
        <>
            <Form
                handlers={handlers}
                // onSubmit={submit}
                action='/hr/employee'
                method='post'
                onError={console.log}>
                <Grid container spacing={1} display='flex' alignItems='center'>
                    <Grid item lg={3} xs={12}>
                        <Typography variant='caption'>Employee ID</Typography>
                    </Grid>
                    <Grid item lg={9} xs={12}>
                        <Box display='flex'>
                            <Input
                                name='userId'
                                placeholder='Employee ID'
                                fullWidth
                                disabled
                                required
                                size='small'
                            />
                        </Box>
                    </Grid>
                    <Grid item lg={3} xs={12}>
                        <Typography variant='caption'>Department</Typography>
                    </Grid>
                    <Grid item lg={9} xs={12} mb={2}>
                        <Box display='flex'>
                            <FormControl fullWidth size='small'>
                                <Select
                                    labelId='demo-simple-select-label'
                                    id='demo-simple-select'
                                    value={employee.department}
                                    onChange={handleChange}
                                    name='department'>
                                    {departments?.map(department => (
                                        <MenuItem value={department._id}>
                                            {department.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item lg={3} xs={12}>
                        <Typography variant='caption'>Designation</Typography>
                    </Grid>
                    <Grid item lg={9} xs={12}>
                        <Box display='flex'>
                            <Input
                                size='small'
                                name='designation'
                                required
                                placeholder='Designation'
                                fullWidth
                            />
                        </Box>
                    </Grid>
                    <Grid item lg={3} xs={12}>
                        <Typography variant='caption'>Date Of Joining</Typography>
                    </Grid>
                    <Grid item lg={9} xs={12}>
                        <Input size='small' type='date' fullWidth name='dateOfJoining' required />
                    </Grid>
                    <Grid item lg={3} xs={12}>
                        <Typography variant='caption'>Resignation Date</Typography>
                    </Grid>
                    <Grid item lg={9} xs={12}>
                        <Input size='small' type='date' fullWidth name='resignationDate' required />
                    </Grid>
                    <Grid item lg={3} xs={12}>
                        <Typography variant='caption'>Termination Date</Typography>
                    </Grid>
                    <Grid item lg={9} xs={12}>
                        <Input size='small' type='date' fullWidth name='terminationDate' required />
                    </Grid>
                    <Grid item lg={3} xs={12}>
                        <Typography variant='caption'>Credit Leaves</Typography>
                    </Grid>
                    <Grid item lg={9} xs={12}>
                        <Box display='flex'>
                            <Input
                                name='creditLeaves'
                                placeholder='0'
                                fullWidth
                                required
                                size='small'
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} my={2}>
                        <Typography variant='body1'>Salary</Typography>
                    </Grid>

                    <Grid item lg={3} xs={12}>
                        <Typography variant='caption'>Type</Typography>
                    </Grid>
                    <Grid item lg={9} xs={12} sx={{ mb: { lg: 2, xs: 1 } }}>
                        <Box>
                            <FormControl fullWidth size='small'>
                                <Select
                                    labelId='demo-simple-select-label'
                                    id='demo-simple-select'
                                    value={employee.jobType}
                                    onChange={handleChange}
                                    name='jobType'
                                    //     defaultValue='Full Time'
                                >
                                    <MenuItem value={'Full Time'}>Full-Time</MenuItem>
                                    <MenuItem value={'Part Time'}>Part-Time</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item lg={3} xs={12}>
                        <Typography variant='caption'>Salary</Typography>
                    </Grid>
                    <Grid item lg={9} xs={12}>
                        <Box display='flex'>
                            <Input
                                type='number'
                                name='salary'
                                placeholder='$Salary'
                                required
                                size='small'
                                fullWidth
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box mt={6} textAlign='right'>
                    <Button
                        variant='contained'
                        sx={{
                            background: '#F91A3F',
                            '$:hover': {
                                background: '#F91A3F',
                            },
                        }}>
                        Cancel
                    </Button>
                    <Button
                        type='submit'
                        variant='contained'
                        sx={{
                            ml: 2,

                            background: '#4674FF',
                            '$:hover': {
                                background: '#4674FF',
                            },
                        }}>
                        Update
                    </Button>
                </Box>
            </Form>
        </>
    );
};

export default CompanyDetails;
