import {
    Box,
    Card,
    CardContent,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import React from 'react';
import MovingIcon from '@mui/icons-material/Moving';

const DashboardCard = (props) => {
    const { Title, Number, Icon, Color } = props;
    return (
        <>
            <Grid item lg={4} xs={12}>
                <Card>
                    <CardContent
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box>
                            <Typography mb={1}>{Title}</Typography>
                            <Typography variant='h6' sx={{ color: Color }}>
                                {Number}
                            </Typography>
                            <Typography
                                variant='body2'
                                sx={{ maxWidth: '150px' }}
                            >
                                <IconButton>
                                    <MovingIcon />
                                </IconButton>
                                124 for last month
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                sx={{
                                    p: 1.5,
                                    background: Color,
                                    borderRadius: '8px',
                                    '&:hover': {
                                        background: Color,
                                    },
                                }}
                            >
                                {Icon}
                            </IconButton>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
};

export default DashboardCard;
