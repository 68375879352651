import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    CircularProgress,
    Modal,
    IconButton,
    Tooltip,
    Skeleton,
    Stack,
    Pagination,
} from '@mui/material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import JobCard from './JobCard';
import axios from 'axios';
import Search from './../../components/Search';
import AddJob from './AddJob';
import useModal from '../../hooks/useModal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useMessage } from '../../layouts/Header';
import useErrorHandler from '../../hooks/useErrorHandler';

const getOrders = jobs =>
    jobs.map((job, i) => ({
        id: job._id,
        index: i,
    }));
const JobListing = () => {
    const { modalState, closeModal, openModal } = useModal();
    const [selectedJob, setSelectedJob] = useState({});
    const [jobs, setJobs] = useState(null);
    const [originalOrder, setOriginalOrders] = useState(null);
    const [isOrderChanged, setIsOrderChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const { showError, showSuccess } = useMessage();
    const [offset, setOffset] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [pageLimit, setPageLimit] = useState(0);
    const errorHandler = useErrorHandler();
    const [departments, setDepartments] = useState({});

    // console.log({ offset });

    const fetchJobListing = useCallback(
        async (search = '') => {
            // setJobs(null);
            try {
                const response = await axios.get(
                    `/hr/job-listing?searchBy=title&search=${search}&sortBy=order&direction=-1&page=${pageNo}`
                );
                const body = response.data;
                const { jobs, pageData } = body;
                const { currentPage, pageSize } = pageData;
                setJobs(jobs);
                setOffset((currentPage - 1) * pageSize);
                setPageLimit(response.data.pageData.totalPages);
                setOriginalOrders(getOrders(jobs));
            } catch (e) {
                console.warn(e);
            }
        },
        [setJobs, pageNo]
    );

    useEffect(() => {
        fetchJobListing();
    }, [fetchJobListing]);

    const editJob = id => {
        openModal();
        setSelectedJob({ id, action: 'edit' });
    };

    const copyJob = id => {
        openModal();
        setSelectedJob({ id });
    };

    const saveOrder = async () => {
        setLoading(true);
        const newOrder = getOrders(jobs);
        const effOrder = newOrder
            .filter((order, i) => order.id !== originalOrder[i].id)
            .map(order => ({
                ...order,
                index: order.index + offset,
            }));

        const res = await axios.patch('/hr/job-listing/order', {
            newOrders: effOrder,
        });

        const { success } = res.data;

        if (success) {
            showSuccess('Order saved successfully');
        } else {
            showError('Cannot save order');
        }

        setIsOrderChanged(false);
        setLoading(false);
    };

    const onDragEnd = result => {
        const { source, destination } = result;

        if (!destination) return;

        if (source.droppableId === destination.droppableId && source.index === destination.index)
            return;

        setIsOrderChanged(true);

        const draggingJob = jobs[source.index];
        jobs.splice(source.index, 1);

        console.log('removed');
        console.log(jobs);

        jobs.splice(destination.index, 0, draggingJob);

        console.log('added');
        console.log(jobs);

        setJobs([...jobs]);
    };

    const getDepartments = useCallback(async () => {
        try {
            const response = await axios.get(`/hr/department`);
            const departments = response.data.departments;

            const format = {};

            departments.forEach(department => (format[department._id] = department.name));

            setDepartments(format);
        } catch (e) {
            errorHandler(e);
        }
    }, [errorHandler]);

    useEffect(() => {
        getDepartments();
    }, [getDepartments]);

    return (
        <>
            <Box mt={3}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Job Listing</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box>
                            <Button onClick={openModal} variant='contained'>
                                Add Job
                            </Button>
                        </Box>

                        <Box sx={{ ml: 2 }}>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0 }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box>
                <Modal sx={{ overflowY: 'scroll' }} open={modalState} onClose={closeModal}>
                    <AddJob
                        selectedJob={selectedJob}
                        setSelectedJob={setSelectedJob}
                        refresh={fetchJobListing}
                        handleClose={closeModal}
                    />
                </Modal>
                <Stack direction='row' justifyContent='space-between' my={4}>
                    <Search
                        placeholder='Search your listing here.'
                        onChange={e => {
                            const { value } = e.target;
                            !(value.trim() === ' ') && fetchJobListing(value);
                        }}
                    />
                    <Pagination
                        page={pageNo}
                        onChange={(_, newPage) => setPageNo(newPage)}
                        color='primary'
                        count={pageLimit}
                        sx={{ float: 'right' }}
                    />
                    {isOrderChanged && (
                        <Button
                            variant='contained'
                            onClick={saveOrder}
                            endIcon={
                                loading && (
                                    <CircularProgress size={20} color='secondary' thickness={7} />
                                )
                            }>
                            Save Order
                        </Button>
                    )}
                </Stack>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='list'>
                        {provided => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {jobs
                                    ? jobs.map((job, i) => (
                                          <Draggable key={job._id} draggableId={job._id} index={i}>
                                              {provided => (
                                                  <div
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}>
                                                      <JobCard
                                                          ref={provided.innerRef}
                                                          draggableProps={provided.draggableProps}
                                                          dragHandleProps={provided.dragHandleProps}
                                                          title={job.title}
                                                          key={i}
                                                          location={job.location}
                                                          experience={job.experience}
                                                          details={job.details}
                                                          jobType={job.jobType}
                                                          salary={job.salary}
                                                          departmentId={job.department}
                                                          refresh={fetchJobListing}
                                                          id={job._id}
                                                          editJob={editJob}
                                                          copyJob={copyJob}
                                                          departments={departments}
                                                      />
                                                  </div>
                                              )}
                                          </Draggable>
                                      ))
                                    : Array(5)
                                          .fill(0)
                                          .map((el, i) => (
                                              <Skeleton
                                                  variant='rounded'
                                                  key={i}
                                                  width='100%'
                                                  height='136px'
                                                  animation='wave'
                                                  sx={{
                                                      borderRadius: '20px',
                                                      my: 2,
                                                  }}
                                              />
                                          ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
        </>
    );
};

export default JobListing;
